import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosService } from "../../util/service";
import Carousel from "../Home/Carousel";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function Verify(props) {
  const [status, setStatus] = useState(null);

  const history = useHistory();

  const verifyEmail = async () => {
    const token = props.match.params.token;
    localStorage.setItem("token", token);

    try {
      let response = await axiosService.post("/api/user/verifyEmail");

      if (response.data.status === true) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.message);
      setStatus(false);
    } finally {
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  if (status === null) return <></>;

  return (
    <div className="verify-page">
      <div className="verify">
        <Header />

        <div className="container">
          <Carousel />

          {status === true ? (
            <div className="verify-success">
              <i className="fa-solid fa-circle-check"></i>
              <h2 className="title">Xác thực thành công</h2>
              <div className="content">
                Bạn đã xác thực email thành công <br /> Bây giờ bạn đã có thể đăng nhập <br /> Xin cảm ơn!
              </div>

              <Button style={{ width: 150 }} type="primary" size="large" onClick={() => history.replace("/login")}>
                Đăng nhập
              </Button>
            </div>
          ) : null}

          {status === false ? (
            <div className="verify-fail">
              <i className="fa-solid fa-circle-xmark"></i>
              <h2 className="title">Xác thực thất bại</h2>
              <div className="content">
                Đã xảy ra lỗi khi xác thực email của bạn <br /> Bạn có thể thử lại sau ít phút <br /> hoặc liên hệ với
                quản trị viên để được hỗ trợ <br /> Xin cảm ơn!
              </div>
            </div>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
}
