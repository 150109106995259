import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function Chart() {
  const [array, setArray] = useState([]);

  const { timeMode, time } = useSelector((root) => root.gameReducer);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);
  const onPaginationChange = (page) => {
    setCurrent(page);
    getData({
      time: timeMode,
      limit: ROWS,
      page,
    });
  };

  const getData = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/getChartLotery", payload);
      let arrayResult = response.data.data.array;

      // xét arrayResult[0]
      // nếu arrayResult[0].number == null: đó là kỳ số hiện tại, chưa có kết quả
      // do vậy nên không có data để vẽ chart, cần loại bỏ nó ra khỏi chart
      // nếu arrayResult[0].number có giá trị thì hiển thị trong chart như bình thường

      if (arrayResult[0].number == null) {
        arrayResult = arrayResult.slice(1); // tạo mảng mới từ phần tử thứ 1 về sau, loại bỏ phần tử 0
      }

      setArray(arrayResult);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const drawLine = (canvasID, canvasW, canvasH, firstRow, secondRow) => {
    const canvasElement = document.getElementById(canvasID);
    let cv = canvasElement.getContext("2d");
    // Set display size
    canvasElement.style.width = canvasW + "px";
    canvasElement.style.height = canvasH + "px";
    // Set actual size in memory (scaled to account for extra pixel density).
    const scale = window.devicePixelRatio;
    canvasElement.width = canvasW * scale;
    canvasElement.height = canvasH * scale;
    // Normalize coordinate system to use css pixels.
    cv.scale(scale, scale);
    let canvasRect = Math.floor(document.querySelector(`#${canvasID}`).getBoundingClientRect().x);
    let firstElementRect = Math.floor(document.querySelector(`.row-${firstRow} .active`).getBoundingClientRect().x);
    let secondElementRect = Math.floor(document.querySelector(`.row-${secondRow} .active`).getBoundingClientRect().x);
    cv.beginPath();
    cv.moveTo(firstElementRect - canvasRect + 9, 0);
    cv.lineTo(secondElementRect - canvasRect + 9, canvasH);
    cv.strokeStyle = "#b5b5b5";
    cv.stroke();
  };

  useEffect(() => {
    setCurrent(1);
    getData({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  useEffect(() => {
    if (array.length != 0) {
      for (let index = 1; index <= array.length - 1; index++) {
        drawLine(`canvas-${index}`, 314, 30, index, index + 1);
      }
    }
  }, [array]);

  useEffect(() => {
    // bắt đầu game mới, load kết quả game cũ
    if (
      (timeMode === 1 && time === 59) ||
      (timeMode === 3 && time === 179) ||
      (timeMode === 5 && time === 299) ||
      (timeMode === 10 && time === 599)
    ) {
      setCurrent(1);
      getData({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time]);

  return (
    <>
      <div className="game-chart">
        {array.map((item, index) => {
          if (index !== array.length - 1) {
            return (
              <div key={index} style={{ height: 49 }}>
                <div className={`row row-${index + 1}`}>
                  <div className="row-left">{item.id}</div>
                  <div className="row-right">
                    <ul>
                      <li className={item.number == 0 ? "li li-0 active" : "li li-0"}>0</li>
                      <li className={item.number == 1 ? "li li-1 active" : "li li-1"}>1</li>
                      <li className={item.number == 2 ? "li li-2 active" : "li li-2"}>2</li>
                      <li className={item.number == 3 ? "li li-3 active" : "li li-3"}>3</li>
                      <li className={item.number == 4 ? "li li-4 active" : "li li-4"}>4</li>
                      <li className={item.number == 5 ? "li li-5 active" : "li li-5"}>5</li>
                      <li className={item.number == 6 ? "li li-6 active" : "li li-6"}>6</li>
                      <li className={item.number == 7 ? "li li-7 active" : "li li-7"}>7</li>
                      <li className={item.number == 8 ? "li li-8 active" : "li li-8"}>8</li>
                      <li className={item.number == 9 ? "li li-9 active" : "li li-9"}>9</li>
                    </ul>
                  </div>
                </div>

                <canvas className="row-line-canvas" id={`canvas-${index + 1}`} width="314" height="30"></canvas>
              </div>
            );
          } else {
            return (
              <div key={index}>
                <div className={`row row-${index + 1}`}>
                  <div className="row-left">{item.id}</div>
                  <div className="row-right">
                    <ul>
                      <li className={item.number == 0 ? "li li-0 active" : "li li-0"}>0</li>
                      <li className={item.number == 1 ? "li li-1 active" : "li li-1"}>1</li>
                      <li className={item.number == 2 ? "li li-2 active" : "li li-2"}>2</li>
                      <li className={item.number == 3 ? "li li-3 active" : "li li-3"}>3</li>
                      <li className={item.number == 4 ? "li li-4 active" : "li li-4"}>4</li>
                      <li className={item.number == 5 ? "li li-5 active" : "li li-5"}>5</li>
                      <li className={item.number == 6 ? "li li-6 active" : "li li-6"}>6</li>
                      <li className={item.number == 7 ? "li li-7 active" : "li li-7"}>7</li>
                      <li className={item.number == 8 ? "li li-8 active" : "li li-8"}>8</li>
                      <li className={item.number == 9 ? "li li-9 active" : "li li-9"}>9</li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>

      <div className="pagination">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>
    </>
  );
}
