import React from "react";
import { useHistory } from "react-router-dom";

export default function Card5() {
  const history = useHistory();

  return (
    <div className="game-card5">
      <div className="content">
        <div className="top">
          <h2 className="title">AOV LOTTERY</h2>
          <p className="desc">Xổ số Liên Quân Mobile, Thắng Bại Tại Kĩ Năng</p>
        </div>
        <div className="bottom">
          <button className="play" onClick={() => history.push("/aov-gameplay")}>
            <span>Play</span>
            <i className="fa-solid fa-circle-right"></i>
          </button>
        </div>
        {/* <div className="bottom">
          <button className="play coming">
            <span>Coming soon</span>
          </button>
        </div> */}
      </div>
    </div>
  );
}
