import { Button, Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Carousel from "../Home/Carousel";

export default function VerifyForgotPassword(props) {
  // user nhấp vào link trong email để đi đến trang này

  const [status, setStatus] = useState(null); // null: hiển thị ô nhập mật khẩu mới; true: thành công; false: thất bại
  const [state, setState] = useState({
    newpw1: "",
    newpw2: "",
  });
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const verifyPassword = async (password) => {
    const token = props.match.params.token;
    localStorage.setItem("token", token);

    try {
      await axiosService.post("api/user/forgetPassword", {
        newPassword: password,
      });
      setStatus(true);
    } catch (error) {
      console.log(error);
      setStatus(false);
    } finally {
      localStorage.removeItem("token");
    }
  };

  const handleSubmit = () => {
    if (!state.newpw1 || state.newpw1 == "") {
      showAlert("error", "Hãy nhập mật khẩu mới");
      return;
    }

    if (!state.newpw2 || state.newpw2 == "") {
      showAlert("error", "Hãy xác nhận mật khẩu mới");
      return;
    }

    if (state.newpw1 != state.newpw2) {
      showAlert("error", "Mật khẩu không khớp");
      return;
    }

    verifyPassword(state.newpw2);
  };

  if (status === null)
    return (
      <div className="verify-pw-page">
        <div className="verify-pw">
          <Header />

          <div className="container">
            <form>
              <div className="field">
                <label htmlFor="newpw1">Nhập mật khẩu mới</label>
                <Input.Password
                  id="newpw1"
                  size="large"
                  value={state.newpw1}
                  onChange={(e) => setState({ ...state, newpw1: e.target.value })}
                ></Input.Password>
              </div>

              <div className="field">
                <label htmlFor="newpw2">Xác nhận mật khẩu mới</label>
                <Input.Password
                  id="newpw2"
                  size="large"
                  value={state.newpw2}
                  onChange={(e) => setState({ ...state, newpw2: e.target.value })}
                ></Input.Password>
              </div>

              <div className="btntbtn">
                <Button
                  size="large"
                  type="primary"
                  style={{ width: 150 }}
                  onClick={() => handleSubmit()}
                  loading={loading}
                >
                  Xác nhận
                </Button>
              </div>
            </form>
          </div>

          <Footer />
        </div>
      </div>
    );

  return (
    <div className="verify-pw-page">
      <div className="verify-pw">
        <Header />

        <div className="container">
          <Carousel />

          {status === true ? (
            <div className="verify-success">
              <i className="fa-solid fa-circle-check"></i>
              <h2 className="title">Đổi mật khẩu thành công</h2>
              <div className="content">Bây giờ bạn có thể đăng nhập bằng mật khẩu mới</div>

              <Button style={{ width: 150 }} type="primary" size="large" onClick={() => history.replace("/login")}>
                Đăng nhập
              </Button>
            </div>
          ) : null}

          {status === false ? (
            <div className="verify-fail">
              <i className="fa-solid fa-circle-xmark"></i>
              <h2 className="title">Đã xảy ra lỗi</h2>
              <div className="content">
                Đã xảy ra lỗi khi đổi mật khẩu của bạn <br /> Bạn có thể thử lại sau ít phút <br /> hoặc liên hệ với
                quản trị viên để được hỗ trợ <br /> Xin cảm ơn!
              </div>

              <Button style={{ width: 150 }} type="primary" size="large" onClick={() => history.replace("/login")}>
                Quay về
              </Button>
            </div>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
}
