import React, { useEffect, useState } from "react";
import avatar from "../../assets/image/avatar.svg";
import { shuffle } from "../../function/shuffleArray.js";

export default function Rank() {
  const names = [
    "LêVănTuấn",
    "Member11829",
    "NguyenThiTuyet",
    "Đặng Thành Long",
    "Jack Sully",
    "Member0756",
    "Member21001",
    "DiệuAnh",
    "Alibaba",
    "AChính",
    "Lê Văn Lộc",
    "Hương Ngọc Bích",
    "Nghiem09",
    "Trítươi",
    "Văn Hùng",
    "CaptainIron",
    "Ronaldo112",
    "Mỹ Tiên Trần",
    "Lala145",
    "Minh Châu Nguyễn",
    "Oliva C2",
    "MessiPlayer",
    "Ly Thi Chung",
    "Member536212",
    "ThiênKim2",
    "ThiênKim1",
    "SuzresLopez",
    "Member1012",
    "Linh",
    "TheBeatlesFan",
    "Whistles",
    "HuynhHuuThien",
    "Thien2610",
    "HaiVu",
    "Tống Văn Quỳnh",
    "Nguyễn Vĩ Khang",
    "Trung Rùa",
    "Lucky Star",
    "Smiley Duo",
    "Member199653",
    "LuisLongHoang",
    "Lý Tú An",
    "Ngọc Hà Nội",
    "BaoBinh112",
    "Chinva00583162",
    "Binhantrongtam",
    "Tuyết Nguyễn",
    "Tân Quang",
    "Trần Duyên",
    "MemberDISOALE",
    "Vânn",
    "Lý Khả Nguyên",
    "Hoà Bình",
    "KittyCatMeow",
    "Catriona Gray",
    "HenHen",
    "Dương Lâm Tú",
    "ThuyTrang",
  ];

  const moneys = [
    80002, 500000, 65331, 15000000, 245632, 120000, 140000, 30650, 50000, 100000, 121000, 65000, 1000000, 245100,
    306000, 12000, 100000, 110000, 200000, 300000, 400000, 500000, 600000, 700000, 15000, 78650, 90005, 1630000, 125600,
    1000000, 2000000, 3000000, 100000, 200000, 300000,
  ];

  const [users, setUsers] = useState([]);
  const [stuffClass, setStuffClass] = useState("");

  const getRndInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const randomDataToTable = () => {
    const shuffledArr1 = shuffle(names);
    const shuffledArr2 = shuffle(moneys);

    let nowHour = new Date(Date.now()).getHours() - 1;
    if (nowHour < 0) nowHour = 0;
    let minutes = [
      getRndInteger(10, 20),
      getRndInteger(20, 30),
      getRndInteger(30, 40),
      getRndInteger(40, 50),
      getRndInteger(50, 59),
    ];

    let temp = [];
    for (let i of [0, 1, 2, 3, 4]) {
      temp.push({
        name: shuffledArr1[i],
        amount: shuffledArr2[i],
        time: nowHour + ":" + minutes[i],
      });
    }
    setStuffClass("anim-out");
    setTimeout(() => {
      setUsers(temp);
      setStuffClass("anim-in");
    }, 500);
  };

  useEffect(() => {
    randomDataToTable();
    const intervalID = setInterval(randomDataToTable, 7000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className="rank-wrapper">
      <div className="rank-title">
        <span>BẢNG XẾP HẠNG RÚT TIỀN</span>
      </div>

      <div className="rank-list">
        {users.map((u, i) => {
          return (
            <div className={`item ${stuffClass}`} key={i}>
              <div className="img">
                <img src={avatar} />
              </div>
              <div className="name">{u.name}</div>
              <div className="amount">{Number(u.amount).toLocaleString()}đ</div>
              <div className="time">{u.time}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
