import { Table } from "antd";
import React from "react";

export default function Table1({ isMarginTop }) {
  const columns = [
    {
      title: "Cấp đại lý",
      key: "Cấp đại lý",
      dataIndex: "capDaiLy",
      width: 180,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: "Số người",
      key: "Số người",
      dataIndex: "soNguoi",
      width: 120,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num}</span>;
      },
    },
    {
      title: "Doanh thu đội",
      key: "Doanh thu đội",
      dataIndex: "doanhThuDoi",
      width: 200,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num.toLocaleString()}</span>;
      },
    },
    {
      title: "Tiền nạp đội",
      key: "Tiền nạp đội",
      dataIndex: "tienNapDoi",
      width: 200,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num.toLocaleString()}</span>;
      },
    },
  ];

  const bangDoanhThuDaiLy = [
    {
      id: 0,
      capDaiLy: "Thành viên cấp 0",
      soNguoi: 0,
      doanhThuDoi: 0,
      tienNapDoi: 0,
    },
    {
      id: 1,
      capDaiLy: "Thành viên cấp 1",
      soNguoi: 10,
      doanhThuDoi: 500000000,
      tienNapDoi: 50000000,
    },
    {
      id: 2,
      capDaiLy: "Thành viên cấp 2",
      soNguoi: 15,
      doanhThuDoi: 750000000,
      tienNapDoi: 75000000,
    },
    {
      id: 3,
      capDaiLy: "Thành viên cấp 3",
      soNguoi: 30,
      doanhThuDoi: 1000000000,
      tienNapDoi: 100000000,
    },
    {
      id: 4,
      capDaiLy: "Thành viên cấp 4",
      soNguoi: 45,
      doanhThuDoi: 1250000000,
      tienNapDoi: 125000000,
    },
    {
      id: 5,
      capDaiLy: "Thành viên cấp 5",
      soNguoi: 50,
      doanhThuDoi: 1500000000,
      tienNapDoi: 150000000,
    },
    {
      id: 6,
      capDaiLy: "Thành viên cấp 6",
      soNguoi: 60,
      doanhThuDoi: 2500000000,
      tienNapDoi: 250000000,
    },
  ];

  return (
    <div className={`${isMarginTop ? "table1-component" : "table1-component no-space"}`}>
      <div className="title">BẢNG DOANH THU ĐẠI LÝ</div>

      <Table
        size="small"
        columns={columns}
        dataSource={bangDoanhThuDaiLy}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </div>
  );
}
