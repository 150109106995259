import { Button, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";

export default function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [info, setInfo] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    document.getElementById("loginForm")?.addEventListener("keypress", handleEnter);
  }, []);

  if (localStorage.getItem("token") && localStorage.getItem("user")) {
    if (JSON.parse(localStorage.getItem("user")).id == 1) {
      return <Redirect to={"/dashboard"} />;
    } else {
      return <Redirect to={"/account"} />;
    }
  }

  const login = async (payload) => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/user/login", payload);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      dispatch({
        type: "USER_LOGIN",
        payload: response.data.data,
      });
      if (response.data.data.id == 1) {
        history.replace("/dashboard-wingo");
      } else {
        history.replace("/account");
      }
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      login({
        email: emailRef.current.input.value,
        password: passwordRef.current.input.value,
      });
    }
  };

  return (
    <div className="login-form">
      <h2 className="title">ĐĂNG NHẬP</h2>
      <form id="loginForm">
        <div className="field">
          <Input
            ref={emailRef}
            size="large"
            type="text"
            placeholder="Địa chỉ email"
            addonBefore={<i className="fa-solid fa-envelope"></i>}
            value={info.email}
            onChange={(e) => setInfo({ ...info, email: e.target.value })}
          />
        </div>
        <div className="field">
          <Input.Password
            ref={passwordRef}
            size="large"
            placeholder="Mật khẩu"
            addonBefore={<i className="fa-solid fa-lock"></i>}
            value={info.password}
            onChange={(e) => setInfo({ ...info, password: e.target.value })}
          />
        </div>
      </form>
      <div style={{ textAlign: "center" }}>
        <Button
          size="large"
          type="primary"
          style={{ width: 200 }}
          onClick={() => {
            login(info);
          }}
          loading={loading}
        >
          Đăng nhập
        </Button>
      </div>
      <div className="helper">
        <span onClick={() => history.push("/forgot-password")} style={{ marginBottom: 20 }}>
          Quên mật khẩu?
        </span>
        <br />
        <span onClick={() => history.replace("/signup")}>Đăng ký tài khoản</span>
      </div>
    </div>
  );
}
