import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";
import HistoryGame from "./HistoryGame";
import ChartAOV from "./ChartAOV";
import HistoryOrder from "./HistoryOrder";

export default function History() {
  const [historyMode, setHistoryMode] = useState(1);
  const { timeMode, time } = useSelector((root) => root.gameReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getData({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  useEffect(() => {
    // khi time = 58 178 298 598, lúc đó gọi API để cập nhật id game mới
    if (
      (timeMode === 1 && time === 58) ||
      (timeMode === 3 && time === 178) ||
      (timeMode === 5 && time === 298) ||
      (timeMode === 10 && time === 598)
    ) {
      getData({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time]);

  // getChartLotery
  // thật ra thì không cần gọi API ở đây vì đã gọi ở 3 component con bên trong
  // tuy nhiên giờ cần phải gọi vì cần lấy được thông tin game mới -> redux -> component Info
  const getData = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/getAovLotery", payload);
      let arrayResult = response.data.data.array;

      // ở đây chỉ xét arrayResult[0]
      // nếu arrayResult[0].number == null: lưu id của kỳ đó lên redux để hiển thị kỳ sổ hiện tại ở component Info
      // nếu arrayResult[0].number có giá trị thì không quan tâm vì mấy component lịch sử đã handle rồi

      if (arrayResult[0].number == null) {
        dispatch({
          type: "SET_CURRENT_GAME",
          payload: arrayResult[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="aov-history-result">
      <div className="button-group">
        <button onClick={() => setHistoryMode(1)} className={historyMode == 1 ? "btn btnActive" : "btn"}>
          Lịch sử game
        </button>
        <button onClick={() => setHistoryMode(3)} className={historyMode == 3 ? "btn btnActive" : "btn"}>
          Biểu đồ
        </button>
        <button onClick={() => setHistoryMode(2)} className={historyMode == 2 ? "btn btnActive" : "btn"}>
          Lịch sử cược
        </button>
      </div>

      {historyMode == 1 && <HistoryGame />}
      {historyMode == 2 && <HistoryOrder />}
      {historyMode == 3 && <ChartAOV />}
    </div>
  );
}
