const defaultState = {
  orderNow: undefined,
};

export const adminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LOAD_PENDING_ORDER_NOW": {
      return {
        ...state,
        orderNow: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
