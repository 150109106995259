import { Button, Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function ForgotPassword() {
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = () => {
    if (!mail || mail == "") {
      showAlert("error", "Vui lòng nhập email");
      return;
    }

    sendEmailToResetPassword(mail);
  };

  const sendEmailToResetPassword = async (email) => {
    setLoading(true);

    try {
      let response = await axiosService.post("api/user/sendmailforgetpassword", { email });
      showAlert("success", response.data.message);
      history.push("/login");
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-pw-page">
      <div className="forgot-pw">
        <Header />

        <div className="container">
          <div className="title-area">
            <h2 className="title">Lấy lại mật khẩu</h2>
          </div>

          <p className="description">
            Hãy nhập email bạn đã đăng ký tài khoản. Hệ thống sẽ gửi link lấy lại mật khẩu qua địa chỉ email đó.
          </p>

          <form>
            <div className="field">
              <label htmlFor="registeredEmail">Email</label>
              <Input
                type="email"
                id="registeredEmail"
                size="large"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              ></Input>
            </div>

            <div className="btntbtn">
              <Button
                size="large"
                type="primary"
                style={{ width: 200 }}
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Lấy lại mật khẩu
              </Button>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </div>
  );
}
