import { QuestionCircleFilled } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function ManageDepositBank() {
  const [list, setList] = useState([]);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    // mỗi lần mở reset state
    setInfo({
      bank: "",
      number: "",
      name: "",
    });
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (!info.bank || !info.number || !info.name) {
      showAlert("error", "Vui lòng nhập đủ thông tin");
      return;
    }

    if (
      info.bank != "Vietcombank" &&
      info.bank != "Sacombank" &&
      info.bank != "Techcombank" &&
      info.bank != "MB Bank" &&
      info.bank != "TP Bank" &&
      info.bank != "Momo"
    ) {
      showAlert("error", "Ngân hàng " + info.bank + " không được hỗ trợ");
      return;
    }

    setIsModalOpen(false);

    await addBank({
      name_banking: info.bank,
      number_banking: info.number,
      owner_banking: info.name,
    });

    await getAllBanks();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // end modal

  // modal 2 - update
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const showModal2 = (bank) => {
    // set global record
    setCurrentRecord(bank);
    // mỗi lần mở -> set data của bank vào state
    setInfo({
      bank: bank.name_banking,
      number: bank.number_banking,
      name: bank.owner_banking,
    });
    setIsModalOpen2(true);
  };
  const handleOk2 = async () => {
    if (!info.bank || !info.number || !info.name) {
      showAlert("error", "Vui lòng nhập đủ thông tin");
      return;
    }

    if (
      info.bank != "Vietcombank" &&
      info.bank != "Sacombank" &&
      info.bank != "Techcombank" &&
      info.bank != "MB Bank" &&
      info.bank != "TP Bank" &&
      info.bank != "Momo"
    ) {
      showAlert("error", "Ngân hàng " + info.bank + " không được hỗ trợ");
      return;
    }

    setIsModalOpen2(false);

    await updateBank(currentRecord.id, info.bank, info.number, info.name);
    await getAllBanks();
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  // end modal 2

  const [info, setInfo] = useState({
    bank: "",
    number: "",
    name: "",
  });

  const getAllBanks = async () => {
    try {
      let response = await axiosService.post("api/depositVND/getAllBanking", {
        limit: 100,
        page: 1,
      });
      console.log(response.data.data);
      setList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addBank = async (data) => {
    try {
      let response = await axiosService.post("api/depositVND/addBankingAdmin", data);
      showToast("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const updateBank = async (id, name_banking, number_banking, owner_banking) => {
    try {
      let response = await axiosService.post("api/depositVND/editBankingAdmin", {
        id,
        name_banking,
        number_banking,
        owner_banking,
      });
      showToast("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const deleteBank = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/deleteBankingAdmin", {
        id,
      });
      showToast("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const confirmDeleteBank = (bank) => {
    Modal.confirm({
      title: "Confirm delete bank",
      icon: <QuestionCircleFilled />,
      content: "Are you sure to delete this bank account?",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        await deleteBank(bank.id);
        await getAllBanks();
      },
    });
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  return (
    <div className="mn-bank">
      <div className="title-area">
        <h2 className="title">Ngân hàng nạp tiền</h2>

        <Button type="primary" onClick={showModal}>
          <i className="fa-solid fa-plus" style={{ marginRight: 5 }}></i>
          Thêm
        </Button>
      </div>

      <div className="bank-list">
        {list.map((bank, idx) => {
          return (
            <div className="bank-card" key={idx}>
              <div className="first-row">
                {renderBankImg(bank.name_banking, 1)}
                <span>{bank.name_banking}</span>
              </div>

              <div className="second-row">{bank.number_banking}</div>
              <div className="third-row">{bank.owner_banking}</div>

              <div className="fourth-row">
                <Button size="small" onClick={() => showModal2(bank)}>
                  Chỉnh sửa
                </Button>
                <Button size="small" danger onClick={() => confirmDeleteBank(bank)}>
                  Xoá
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        title="Thêm ngân hàng"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Thêm ngân hàng"
      >
        <form className="add-bank-modal">
          <div className="field bank-field">
            <label htmlFor="bank">Tên ngân hàng</label>
            <Input
              type="text"
              size="large"
              id="bank"
              value={info.bank}
              onChange={(e) =>
                setInfo({
                  ...info,
                  bank: e.target.value,
                })
              }
            />
          </div>

          <div className="field number-field">
            <label htmlFor="number">Số tài khoản</label>
            <Input
              type="text"
              size="large"
              id="number"
              value={info.number}
              onChange={(e) =>
                setInfo({
                  ...info,
                  number: e.target.value,
                })
              }
            />
          </div>

          <div className="field name-field">
            <label htmlFor="name">Tên chủ tài khoản</label>
            <Input
              type="text"
              size="large"
              id="name"
              value={info.name}
              onChange={(e) =>
                setInfo({
                  ...info,
                  name: e.target.value,
                })
              }
            />
          </div>
        </form>
      </Modal>

      <Modal
        title="Chỉnh sửa ngân hàng"
        visible={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        okText="Cập nhật"
      >
        <form className="add-bank-modal">
          <div className="field bank-field">
            <label htmlFor="bank">Tên ngân hàng</label>
            <Input
              type="text"
              size="large"
              id="bank"
              value={info.bank}
              onChange={(e) =>
                setInfo({
                  ...info,
                  bank: e.target.value,
                })
              }
            />
          </div>

          <div className="field number-field">
            <label htmlFor="number">Số tài khoản</label>
            <Input
              type="text"
              size="large"
              id="number"
              value={info.number}
              onChange={(e) =>
                setInfo({
                  ...info,
                  number: e.target.value,
                })
              }
            />
          </div>

          <div className="field name-field">
            <label htmlFor="name">Tên chủ tài khoản</label>
            <Input
              type="text"
              size="large"
              id="name"
              value={info.name}
              onChange={(e) =>
                setInfo({
                  ...info,
                  name: e.target.value,
                })
              }
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
