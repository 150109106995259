import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function Menu5ListF1() {
  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  // data
  const [array, setArray] = useState([]);

  const getListUserF1 = async (limit, page) => {
    try {
      let response = await axiosService.post("api/lotery/getListUserF1", {
        limit,
        page,
      });
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getListUserF1(ROWS, page);
  };

  useEffect(() => {
    setCurrent(1);
    getListUserF1(ROWS, 1);
  }, []);

  const columns = [
    {
      title: "STT",
      key: "STT",
      width: 60,
      render: (t, r, i) => {
        return <span style={{ fontSize: 12 }}>{(current - 1) * 10 + i + 1}</span>;
      },
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      width: 180,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: (
        <span>
          Mã
          <br />
          giới thiệu
        </span>
      ),
      dataIndex: "referral",
      key: "referral",
      width: 140,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: "Level",
      key: "level",
      width: 100,
      render: (_, record) => {
        return <span style={{ fontSize: 12 }}>Level {record.level}</span>;
      },
    },
    {
      title: (
        <span>
          Commission
          <br />
          balance
        </span>
      ),
      key: "Commission balance",
      dataIndex: "commissionBalance",
      width: 120,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
  ];

  return (
    <div className="acc-list-user-f1">
      <div className="title-area">
        <div className="title">Users F1</div>

        <Pagination
          size="small"
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          showLessItems={true}
          className="pagination-box"
        />
      </div>

      <div className="table">
        <Table
          size="small"
          columns={columns}
          dataSource={array}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{
            x: 950,
          }}
        />
      </div>
    </div>
  );
}
