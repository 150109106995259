import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import socket from "../../util/socket";
import { calcTime } from "../../function/calcTime.js";

export default function Info() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { timeMode, time, currentGame } = useSelector((root) => root.gameReducer);

  const [secA, setSecA] = useState(0); // first digit of second
  const [secB, setSecB] = useState(0); // second digit of second
  const [secC, setSecC] = useState(0); // first digit of minute
  const [secD, setSecD] = useState(0); // second digit of minute

  useEffect(() => {
    socket.removeAllListeners();

    if (timeMode === 1) {
      socket.on("TIME", (res) => {
        // res: [1-60]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 60 - res, // [59-0]
        });
      });
    } else if (timeMode === 3) {
      socket.on("TIME3", (res) => {
        // res: [1-180]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 180 - res,
        });
      });
    } else if (timeMode === 5) {
      socket.on("TIME5", (res) => {
        // res: [1-300]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 300 - res,
        });
      });
    } else if (timeMode === 10) {
      socket.on("TIME10", (res) => {
        // res: [1-600]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 600 - res,
        });
      });
    }
  }, [timeMode]);

  useEffect(() => {
    const x = calcTime(time); // [c,d,a,b]
    setSecC(x[0]);
    setSecD(x[1]);
    setSecA(x[2]);
    setSecB(x[3]);
  }, [time]);

  return (
    <div className="aov-info">
      <div className="left">
        <span className="timeMode">{timeMode} phút</span>
        <p>{currentGame.id}</p>
        {/* <span className="link-to-guide" onClick={() => history.push("/guide")}>
          Xem hướng dẫn
        </span> */}
      </div>

      {time !== null ? (
        <div className="right">
          {time <= 4 ? <div className="text">Chờ kết quả</div> : <div className="text">Đặt cược</div>}
          <div className="time">
            <span className="second-digit">{secC}</span>
            <span className="second-digit">{secD}</span>
            <span className="divider">:</span>
            <span className="second-digit">{secA}</span>
            <span className="second-digit">{secB}</span>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="circle1"></div>
      <div className="circle2"></div>
      <div className="line"></div>
    </div>
  );
}
