import React from "react";
import { Redirect } from "react-router-dom";

export default function RedirectHome() {
  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return <Redirect to="/home" />;
}
