import { Button, message } from "antd";
import React, { useCallback, useEffect, useRef } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import { axiosService } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import History from "./History";
import Menu5 from "./Menu5";
import MyTeam from "./MyTeam";
import Table1 from "./Table1";
import Table2 from "./Table2";

export default function PromoHome() {
  const dispatch = useDispatch();
  const { user } = useSelector((root) => root.userReducer);
  const { currentMenu } = useSelector((root) => root.promoReducer);

  const REFERRAL = user.referral;
  const LINK = "https://aovmoney.com/signup?ref=" + REFERRAL;

  const svgRef = useRef();
  const [value, copy] = useCopyToClipboard();

  const handleChangeMenu = (num) => {
    dispatch({
      type: "CHANGE_PROMO_MENU",
      payload: num,
    });
  };

  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_PROFILE",
        payload: response.data.data,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      const new_userInLocalStorage = {
        ...userInLocalStorage,
        ...response.data.data,
      };
      localStorage.setItem("user", JSON.stringify(new_userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentMenu == 1) getProfile();
  }, [currentMenu]);

  // to download qr code in svg format
  const downloadBlob = (blob, filename) => {
    try {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadQRCode = useCallback(() => {
    const svg = svgRef.current.innerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml" });
    downloadBlob(blob, "qrCode.svg");
  }, []);

  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return (
    <div className="promohome-page">
      <div className="promohome">
        <Header />

        <div className="promo-menu">
          <div className={`item ${currentMenu == 1 ? "active" : ""}`} onClick={() => handleChangeMenu(1)}>
            Tổng
            <br />
            quan
          </div>
          <div className={`item ${currentMenu == 3 ? "active" : ""}`} onClick={() => handleChangeMenu(3)}>
            Lịch sử
            <br />
            nhận
          </div>
          <div className={`item ${currentMenu == 2 ? "active" : ""}`} onClick={() => handleChangeMenu(2)}>
            Thống kê
            <br />
            hoa hồng
          </div>
          <div className={`item ${currentMenu == 5 ? "active" : ""}`} onClick={() => handleChangeMenu(5)}>
            Đội
            <br />
            của tôi
          </div>
          <div className={`item ${currentMenu == 4 ? "active" : ""}`} onClick={() => handleChangeMenu(4)}>
            Hướng
            <br />
            dẫn
          </div>
        </div>

        {currentMenu == 1 && (
          <div className="container">
            <div className="promo-level">
              <span>Thành viên cấp {user.level}</span>
            </div>

            <div className="promo-statistics">
              <div className="row1">
                <div className="text">Hoa hồng hôm nay</div>
                <div className="num">
                  {Math.round(Number(user.commissionBalance) + Number(user.commissionTeam)).toLocaleString()} VND
                </div>
              </div>

              <div className="row2">
                <div className="left">
                  <div className="text">Hoa hồng trực tiếp</div>
                  <div className="num">{Math.round(Number(user.commissionBalance)).toLocaleString()} VND</div>
                </div>

                <div className="right">
                  <div className="text">Hoa hồng đội</div>
                  <div className="num">{Math.round(Number(user.commissionTeam)).toLocaleString()} VND</div>
                </div>
              </div>

              <div className="row3">
                <div className="left">
                  <div className="text">Doanh thu đội</div>
                  <div className="num">{Number(user.revenueTeam).toLocaleString()} VND</div>
                </div>

                <div className="right">
                  <div className="text">Tiền nạp đội</div>
                  <div className="num">{Number(user.rechargeTeam).toLocaleString()} VND</div>
                </div>
              </div>
            </div>

            <div className="promo-referral">
              <div className="qr-code" ref={svgRef}>
                <QRCode value={LINK} size={128} type="" />
              </div>

              <div className="action-copy">
                <Button type="primary" style={{ width: "100%" }} onClick={() => handleDownloadQRCode()}>
                  Lưu QR
                </Button>

                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    copy(REFERRAL);
                    message.success("Đã sao chép");
                  }}
                >
                  Copy mã giới thiệu
                </Button>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    copy(LINK);
                    message.success("Đã sao chép");
                  }}
                >
                  Copy đường dẫn
                </Button>
              </div>
            </div>

            <Table1 isMarginTop={true} />
            <Table2 />
          </div>
        )}

        {currentMenu == 2 && (
          <div className="container">
            <MyTeam />
          </div>
        )}

        {currentMenu == 3 && (
          <div className="container">
            <History />
          </div>
        )}

        {currentMenu == 4 && (
          <div className="container">
            <Table1 isMarginTop={false} />
            <Table2 />
          </div>
        )}

        {currentMenu == 5 && (
          <div className="container">
            <Menu5 />
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}
