import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function Profit() {
  const { user } = useSelector((root) => root.userReducer);

  // for table
  const [array, setArray] = useState([]);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const getHistory = async (limit, page) => {
    let url;
    if (user.id == 1) {
      // admin
      url = "api/admin/getProfitsAdmin";
    } else if (user.type == 1) {
      url = "api/admin/getListUserProfitParentAdmin";
    } else {
      url = "";
    }

    try {
      let response = await axiosService.post(url, {
        limit,
        page,
      });
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getHistory(ROWS, page);
  };

  useEffect(() => {
    setCurrent(1);
    getHistory(ROWS, 1);
  }, []);

  const columns = [
    {
      title: "UserID",
      key: "UserID",
      dataIndex: "userid",
      width: 80,
    },
    {
      title: "Username",
      key: "Username",
      dataIndex: "username",
      width: 120,
    },
    {
      title: "Email",
      key: "Email",
      dataIndex: "email",
      width: 180,
    },
    {
      title: "Message",
      key: "Message",
      dataIndex: "message",
      width: 100,
    },
    {
      title: "Số tiền",
      key: "Số tiền",
      width: 120,
      render: (_, { type, amount }) => {
        if (type == 1) {
          return <b style={{ color: "green" }}>+{Math.round(Number(amount)).toLocaleString()}</b>;
        } else if (type == 0) {
          return <b style={{ color: "red" }}>-{Math.round(Number(amount)).toLocaleString()}</b>;
        }
      },
    },
    {
      title: "Lợi nhuận trước",
      key: "Lợi nhuận trước",
      dataIndex: "profitBefore",
      width: 120,
      render: (num) => <span>{Math.round(Number(num)).toLocaleString()}</span>,
    },
    {
      title: "Lợi nhuận sau",
      key: "Lợi nhuận sau",
      dataIndex: "profitAfter",
      width: 120,
      render: (num) => <span>{Math.round(Number(num)).toLocaleString()}</span>,
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 160,
    },
  ];

  return (
    <div className="admin-history-profit">
      <div className="title-area">
        <h2 className="title">Lịch sử lợi nhuận</h2>

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
        />
      </div>

      <Table
        columns={columns}
        dataSource={array}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{
          x: 1100,
        }}
      />
    </div>
  );
}
