import { Button, DatePicker, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function MyTeam() {
  const [info, setInfo] = useState({
    date: "",
    // uid: "",
    // level: 1,
  });

  const [data, setData] = useState([]);

  const [statical, setStatical] = useState({
    totalAmount: undefined,
    totalAmountReceive: undefined,
  }); // tổng số tiền đặt cược và tổng số tiền hoa hồng

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const onPaginationChange = (page) => {
    if (!payloadForTab2ToLoad) {
      // payloadForTab2ToLoad này là người dùng chọn từ DatePicker hoặc chuyển từ tab 2 qua
      // nếu không có thì không gọi API
    } else {
      setCurrent(page);
      getData({
        limit: ROWS,
        page: page,
        timeStart: payloadForTab2ToLoad.timeStart,
        timeEnd: payloadForTab2ToLoad.timeEnd,
      });
    }
  };

  const dispatch = useDispatch();
  const { payloadForTab2ToLoad } = useSelector((root) => root.promoReducer);

  const getData = async (data) => {
    try {
      let response = await axiosService.post("api/lotery/historyCommission", data);
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);

      setStatical({
        totalAmount: response.data.data.totalAmount,
        totalAmountReceive: response.data.data.totalAmountReceive,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!payloadForTab2ToLoad) {
      // payloadForTab2ToLoad này là người dùng chọn từ DatePicker hoặc chuyển từ tab 2 qua
      // nếu không có thì không gọi API
    } else {
      setCurrent(1);
      getData({
        limit: ROWS,
        page: 1,
        timeStart: payloadForTab2ToLoad.timeStart,
        timeEnd: payloadForTab2ToLoad.timeEnd,
      });
    }
  }, [payloadForTab2ToLoad]);

  const columns = [
    {
      title: "STT",
      key: "STT",
      render: (t, r, i) => {
        return <span style={{ fontSize: 12 }}>{(current - 1) * 10 + i + 1}</span>;
      },
      width: 50,
    },
    {
      title: "Email",
      key: "Email",
      dataIndex: "email",
      width: 200,
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "Doanh thu",
      key: "Doanh thu",
      dataIndex: "amountReceive",
      width: 150,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{Math.floor(Number(text)).toLocaleString()}</span>;
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
  ];

  const onDateChange = (date, dateString) => {
    const timeStart = Date.parse(date) - 57084000; // lấy từ 00:00:00 của ngày được chọn
    const timeEnd = Date.parse(date) + 29315000; // đến 23:59:59 của ngày được chọn

    dispatch({
      type: "USER_CHOOSE_TIME",
      payload: {
        timeStart,
        timeEnd,
      },
    });

    setInfo({
      ...info,
      date: dateString,
    });
  };

  const handleSubmitForm = () => {
    console.log(info);
  };

  return (
    <div className="promo-team-component">
      <div className="count">Đội trực tiếp (0 người)</div>

      <div className="date">
        <DatePicker onChange={onDateChange} style={{ width: "100%" }} />
      </div>

      <div className="form">
        {/* <Input
          type="text"
          placeholder="UID"
          value={info.uid}
          onChange={(e) => setInfo({ ...info, uid: e.target.value })}
        />

        <Select
          defaultValue={1}
          options={[1, 2, 3, 4, 5, 6].map((level) => ({
            label: `Bậc ${level}`,
            value: level,
          }))}
          value={info.level}
          onChange={(e) => setInfo({ ...info, level: e })}
        /> */}

        <span></span>

        <Button type="primary" onClick={handleSubmitForm}>
          Tìm kiếm
        </Button>

        <span></span>
      </div>

      {statical.totalAmount != undefined && statical.totalAmountReceive != undefined && (
        <div className="statistics">
          <div className="item">
            Tổng số tiền đặt cược: <span>{Math.round(Number(statical.totalAmount)).toLocaleString()}</span> VND
          </div>
          <div className="item">
            Tổng số tiền hoa hồng: <span>{Math.round(Number(statical.totalAmountReceive)).toLocaleString()}</span> VND
          </div>
        </div>
      )}

      <div className="pagination-sk">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          hideOnSinglePage
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>

      <div className="table">
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={false}
          // scroll={{ x: 500 }}
        />
      </div>
    </div>
  );
}
