import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function HowToPlay() {
  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return (
    <div className="howtoplay-page">
      <div className="howtoplay">
        <Header />

        <div className="banner"></div>

        <div className="container">
          <h2 className="title">QUY ĐỊNH ĐẶT CƯỢC</h2>

          <p>(1) Đối với hình thức cược Lớn/Nhỏ: Trong một kỳ xổ số không được đặt cược vừa Lớn vừa Nhỏ.</p>

          <p>(2) Đối với hình thức cược Xanh/Tím/Đỏ: Trong một kỳ xổ số không được đặt cược nhiều hơn một màu.</p>

          <p>(3) Đối với hình thức cược số 0-9: Trong một kỳ xổ số không được đặt cược nhiều hơn 7 số.</p>

          <p>
            (4) Mỗi kỳ xổ số, bạn được phép đặt cược tối thiểu 0 lần và tối đa 9 lần (bao gồm 1 lần cược Lớn/Nhỏ, 1 lần
            cược Xanh/Tím/Đỏ, 7 lần cược số)
          </p>

          <p>(5) Nếu bị phát hiện có hành vi đặt cược trái với quy định nêu trên sẽ bị hủy bỏ lệnh rút tiền.</p>

          <h2 className="title" style={{ marginTop: 50 }}>
            HƯỚNG DẪN TÍNH THƯỞNG
          </h2>

          <p>
            (1) Mỗi kỳ xổ số kéo dài 1 phút, trong đó 55 giây đầu là thời gian đặt cược, 5 giây cuối là thời gian tạm
            ngưng đặt cược và chờ mở kết quả. Giải thưởng sẽ được mở cả ngày. Tổng số kỳ xổ số mỗi ngày là 1440 kỳ.
          </p>

          <p>
            (2) Phí giao dịch mỗi lần đặt cược là 2% tính trên số tiền đặt cược ban đầu. VD: Nếu bạn đặt cược 100đ, phí
            sẽ là 2đ, vậy nên tiền đặt cược thực tế của bạn sẽ là 98đ.
          </p>

          <p>
            (3) <b>Trường hợp đặt cược màu Xanh:</b>
          </p>

          <p>* Nếu kết quả là 1, 3, 7, 9: bạn thắng 2 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là 5: bạn thắng 1.5 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>

          <p>
            (4) <b>Trường hợp đặt cược màu Đỏ:</b>
          </p>

          <p>* Nếu kết quả là 2, 4, 6, 8: bạn thắng 2 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là 0: bạn thắng 1.5 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>

          <p>
            (5) <b>Trường hợp đặt cược màu Tím:</b>
          </p>

          <p>* Nếu kết quả là 0, 5: bạn thắng 4.5 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>

          <p>
            (6) <b>Trường hợp đặt cược số từ 0 đến 9:</b>
          </p>

          <p>* Nếu kết quả là đúng số bạn đặt cược: bạn thắng 9 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>

          <p>
            (7) <b>Trường hợp đặt cược Lớn:</b>
          </p>

          <p>* Nếu kết quả là 5, 6, 7, 8, 9: bạn thắng 2 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>

          <p>
            (8) <b>Trường hợp đặt cược Nhỏ:</b>
          </p>

          <p>* Nếu kết quả là 0, 1, 2, 3, 4: bạn thắng 2 lần số tiền cược thực tế.</p>
          <p>* Nếu kết quả là số khác: bạn không thắng.</p>
        </div>

        <Footer />
      </div>
    </div>
  );
}
