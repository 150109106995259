import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/image/aov/logo-rectangle.png";

export default function Header() {
  const location = useLocation();
  const history = useHistory();

  const isVisible =
    location.pathname == "/wingo-gameplay" ||
    location.pathname == "/guide" ||
    location.pathname == "/aov-gameplay" ||
    location.pathname == "/cay-thue-lien-quan" ||
    location.pathname == "/lich-su-cay-thue";

  const isVisible_SupportIcon = location.pathname == "/cay-thue-lien-quan" || location.pathname == "/lich-su-cay-thue";

  return (
    <div className="header">
      <i
        className="fa-regular fa-circle-left"
        style={{ visibility: isVisible ? "visible" : "hidden" }}
        onClick={() => {
          if (location.pathname == "/lich-su-cay-thue") {
            history.push("/cay-thue-lien-quan");
          } else {
            history.push("/home");
          }
        }}
      ></i>

      <img
        src={logo}
        alt="Logo"
        onClick={() => {
          try {
            const token = localStorage.getItem("token");
            if (token) {
              navigator.clipboard.writeText(token);
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />

      <i
        className="fa-solid fa-headphones-simple"
        style={{ visibility: isVisible_SupportIcon ? "visible" : "hidden" }}
        onClick={() => history.push("/help-center")}
      ></i>
    </div>
  );
}
