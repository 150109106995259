import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";

import music86 from "../../assets/music/music86.mp3";

export default function MarqueeComponent() {
  const [isPlayingMusic, setIsPlayingMusic] = useState(true);
  const audioRef86 = useRef(null);

  useEffect(() => {
    // click chuột lần đầu tiên autoplay music78 1 lần, xong sẽ play music86 loop
    const aovScreen = document.querySelector(".aov-gameplay");
    if (aovScreen) {
      aovScreen.addEventListener("click", handleUserClickFirstTime);
    }
  }, []);

  const handleUserClickFirstTime = (event) => {
    event.preventDefault();
    if (audioRef86) {
      audioRef86.current.play();
    }
    document.querySelector(".aov-gameplay").removeEventListener("click", handleUserClickFirstTime);
  };

  const toggleMusic = () => {
    setIsPlayingMusic((isPlayingMusic) => !isPlayingMusic);
    if (!audioRef86.current.paused) {
      audioRef86.current.pause();
    } else {
      audioRef86.current.play();
    }
  };

  return (
    <div className="aov-horizontal-zone">
      <div className="aov-marquee-component">
        <div className="main">
          <Marquee gradient={false} speed={60} delay={1}>
            <span className="marquee-text">Welcome to AOV Money! Wish you good luck!</span>
          </Marquee>
        </div>
      </div>

      <div className="aov-music-control" onClick={() => toggleMusic()}>
        {isPlayingMusic ? <i className="fa-solid fa-volume-high"></i> : <i className="fa-solid fa-volume-xmark"></i>}
        <audio src={music86} ref={audioRef86} loop></audio>
      </div>
    </div>
  );
}
