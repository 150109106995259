import { Button, Pagination, Spin, Table, Tabs, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ROWS } from "../../constant/constant";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { axiosService, DOMAIN } from "../../util/service";

import imgADD from "../../assets/image/aov/ad2.png";
import nhanvat0 from "../../assets/image/aov/nhanvat0.png";
import nhanvat1 from "../../assets/image/aov/nhanvat1.png";
import nhanvat2 from "../../assets/image/aov/nhanvat2.png";
import nhanvat3 from "../../assets/image/aov/nhanvat3.png";
import nhanvat4 from "../../assets/image/aov/nhanvat4.png";
import nhanvat5 from "../../assets/image/aov/nhanvat5.png";
import nhanvat6 from "../../assets/image/aov/nhanvat6.png";
import nhanvat7 from "../../assets/image/aov/nhanvat7.png";
import nhanvat8 from "../../assets/image/aov/nhanvat8.png";
import nhanvat9 from "../../assets/image/aov/nhanvat9.png";
import imgSPP from "../../assets/image/aov/sp2.png";

export default function DetailUser() {
  const { uid } = useParams();
  const [currentTab, setCurrentTab] = useState("tab1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllData({
      userid: uid,
      limit: ROWS,
      page,
    });
  };

  const renderOrder = (order) => {
    if (order == "big") {
      return <span className="type type-big">Lớn</span>;
    } else if (order == "small") {
      return <span className="type type-small">Nhỏ</span>;
    } else if (order == "0") {
      return <span className="type type-0">0</span>;
    } else if (order == "5") {
      return <span className="type type-5">5</span>;
    } else if (order == "1") {
      return <span className="type type-1379">1</span>;
    } else if (order == "3") {
      return <span className="type type-1379">3</span>;
    } else if (order == "7") {
      return <span className="type type-1379">7</span>;
    } else if (order == "9") {
      return <span className="type type-1379">9</span>;
    } else if (order == "2") {
      return <span className="type type-2468">2</span>;
    } else if (order == "4") {
      return <span className="type type-2468">4</span>;
    } else if (order == "6") {
      return <span className="type type-2468">6</span>;
    } else if (order == "8") {
      return <span className="type type-2468">8</span>;
    } else if (order == "green") {
      return <span className="type type-green">Xanh</span>;
    } else if (order == "violet") {
      return <span className="type type-purple">Tím</span>;
    } else if (order == "red") {
      return <span className="type type-red">Đỏ</span>;
    }
  };

  const renderOrderAOV = (order) => {
    let img = undefined;

    /* SP lớn AD nhỏ */

    if (order == "big") {
      img = imgSPP;
    } else if (order == "small") {
      img = imgADD;
    } else if (order == "0") {
      img = nhanvat0;
    } else if (order == "5") {
      img = nhanvat5;
    } else if (order == "1") {
      img = nhanvat1;
    } else if (order == "3") {
      img = nhanvat3;
    } else if (order == "7") {
      img = nhanvat7;
    } else if (order == "9") {
      img = nhanvat9;
    } else if (order == "2") {
      img = nhanvat2;
    } else if (order == "4") {
      img = nhanvat4;
    } else if (order == "6") {
      img = nhanvat6;
    } else if (order == "8") {
      img = nhanvat8;
    }

    return (
      <img src={img} className={order == "big" || order == "small" ? "adsp-in-result-game" : "tuong-in-result-game"} />
    );
  };

  const columns = [
    {
      title: "Kỳ xổ",
      key: "Kỳ xổ",
      width: 120,
      render: (_, { time, idChartLottery }) => {
        return (
          <div>
            <span>{time} phút</span> <br />
            <span>#{idChartLottery}</span>
          </div>
        );
      },
    },
    {
      title: "Đặt cược",
      key: "Đặt cược",
      width: 150,
      render: (_, { order, amount, balance }) => {
        return (
          <div>
            <div>{renderOrder(order)}</div>
            <span>
              {amount} x {Math.round(Number(balance)).toLocaleString()}
            </span>
          </div>
        );
      },
    },
    {
      title: "Phí",
      key: "Phí",
      dataIndex: "fee",
      width: 100,
    },
    {
      title: "Kết quả",
      key: "Kết quả",
      width: 150,
      render: (_, record) => {
        if (record.status == "PENDING") {
          return <span>PENDING</span>;
        } else if (record.status == "SUCCESS") {
          if (record.profit != 0) {
            return (
              <>
                <b className="result-win" style={{ color: "green" }}>
                  THẮNG
                </b>
                <br />
                <span className="result-win-money">+{Math.round(Number(record.profit)).toLocaleString()}</span>
              </>
            );
          } else if (record.profit == 0) {
            return (
              <>
                <b className="result-lose" style={{ color: "red" }}>
                  THUA
                </b>
                <br />
                <span className="result-lose-money">
                  -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
  ];

  const columns2 = [
    {
      title: "Kỳ xổ",
      key: "Kỳ xổ",
      width: 120,
      render: (_, { time, idChartLottery }) => {
        return (
          <div>
            <span>{time} phút</span> <br />
            <span>#{idChartLottery}</span>
          </div>
        );
      },
    },
    {
      title: "Đặt cược",
      key: "Đặt cược",
      width: 150,
      render: (_, { order, amount, balance }) => {
        return (
          <div>
            <div>{renderOrderAOV(order)}</div>
            <span>
              {amount} x {Math.round(Number(balance)).toLocaleString()}
            </span>
          </div>
        );
      },
    },
    {
      title: "Phí",
      key: "Phí",
      dataIndex: "fee",
      width: 100,
    },
    {
      title: "Kết quả",
      key: "Kết quả",
      width: 150,
      render: (_, record) => {
        if (record.status == "PENDING") {
          return <span>PENDING</span>;
        } else if (record.status == "SUCCESS") {
          if (record.profit != 0) {
            return (
              <>
                <b className="result-win" style={{ color: "green" }}>
                  THẮNG
                </b>
                <br />
                <span className="result-win-money">+{Math.round(Number(record.profit)).toLocaleString()}</span>
              </>
            );
          } else if (record.profit == 0) {
            return (
              <>
                <b className="result-lose" style={{ color: "red" }}>
                  THUA
                </b>
                <br />
                <span className="result-lose-money">
                  -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
  ];

  const columns3 = [
    {
      title: "Ngân hàng",
      key: "Ngân hàng",
      width: 180,
      render: (_, { bank_name }) => {
        return (
          <div className="bank-column">
            {renderBankImg(bank_name, 1)}
            {bank_name}
          </div>
        );
      },
    },
    {
      title: "Số tiền",
      key: "Số tiền",
      dataIndex: "amount",
      width: 180,
      render: (a) => {
        return <span>{Number(a).toLocaleString()}</span>;
      },
    },
    {
      title: "Nội dung chuyển khoản",
      key: "Nội dung chuyển khoản",
      dataIndex: "code_unique",
      width: 250,
    },
    {
      title: "Hình ảnh",
      key: "Hình ảnh",
      width: 100,
      render: (_, record) => {
        if (!record.images) {
          return <span>-</span>;
        } else {
          return (
            <Button
              size="small"
              onClick={() => {
                window.open(DOMAIN + record.images);
              }}
            >
              Xem
            </Button>
          );
        }
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: "Trạng thái",
      key: "Trạng thái",
      width: 180,
      render: (_, { type_admin, type_user, note }) => {
        if (type_admin == 0 && type_user == 0) {
          return (
            <Tag color="orange">
              <b>ĐANG CHỜ</b>
            </Tag>
          );
        } else if (type_admin == 0 && type_user == 2) {
          return (
            <Tag color="grey">
              <b>USER HUỶ GIAO DỊCH</b>
            </Tag>
          );
        } else if (type_admin == 2 && type_user == 0) {
          return (
            <Tag color="blue">
              <b>USER ĐÃ CHUYỂN TIỀN</b>
            </Tag>
          );
        } else if (type_admin == 3) {
          return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Tag color="red">
                <b>TỪ CHỐI</b>
              </Tag>
              <Tooltip title={note}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{ fontSize: 18, color: "grey", cursor: "pointer", marginLeft: 6 }}
                ></i>
              </Tooltip>
            </div>
          );
        } else if (type_admin == 1) {
          return (
            <Tag color="green">
              <b>THÀNH CÔNG</b>
            </Tag>
          );
        }
      },
    },
  ];

  const columns4 = [
    {
      title: "Ngân hàng",
      key: "Bank",
      width: 150,
      render: (_, record) => {
        return (
          <div className="bank-item-w-vnd">
            {renderBankImg(record.nameBanking, 1)}
            <span>{record.nameBanking}</span>
          </div>
        );
      },
    },
    {
      title: "Tài khoản",
      key: "Account",
      width: 200,
      render: (_, record) => {
        return (
          <div>
            <span>{record.numberBanking}</span> <br />
            <span>{record.ownerBanking}</span>
          </div>
        );
      },
    },
    {
      title: "Số tiền",
      key: "Amount",
      dataIndex: "amount",
      width: 150,
      render: (text) => {
        return Number(text).toLocaleString();
      },
    },
    {
      title: "Số tiền thực tế",
      key: "Số tiền thực tế",
      width: 150,
      render: (_, { amount }) => {
        const soTienRut = Number(amount);
        const soTienThucTe = soTienRut * 0.97;
        return Math.round(soTienThucTe).toLocaleString();
      },
    },
    {
      title: "Thời gian",
      key: "Time",
      dataIndex: "created_at",
      width: 180,
    },
    {
      title: "Trạng thái",
      key: "Status",
      width: 120,
      render: (_, { type }) => {
        if (type == 1) {
          // rút tiền thành công
          return (
            <Tag color="green">
              <strong>THÀNH CÔNG</strong>
            </Tag>
          );
        } else if (type == 2) {
          // chờ admin duyệt
          return (
            <Tag color="blue">
              <strong>ĐANG CHỜ DUYỆT</strong>
            </Tag>
          );
        } else if (type == 0) {
          // admin từ chối
          return (
            <Tag color="red">
              <strong>TỪ CHỐI</strong>
            </Tag>
          );
        }
      },
    },
  ];

  const getCol = () => {
    if (currentTab === "tab1") return columns;
    else if (currentTab === "tab2") return columns2;
    else if (currentTab === "tab3") return columns3;
    else if (currentTab === "tab4") return columns4;
  };

  const getScrollValue = () => {
    if (currentTab === "tab1") return { x: 720 };
    else if (currentTab === "tab2") return { x: 720 };
    else if (currentTab === "tab3") return { x: 1090 };
    else if (currentTab === "tab4") return { x: 950 };
  };

  const getAllData = async (payload) => {
    let url;
    if (currentTab === "tab1") {
      url = "api/admin/getListOrderToId";
    } else if (currentTab === "tab2") {
      url = "api/admin/getListOrderAovToId";
    } else if (currentTab === "tab3") {
      url = "api/admin/getListDepositToId";
    } else if (currentTab === "tab4") {
      url = "api/admin/getListWithdrawToId";
    } else {
      url = "";
    }

    try {
      setLoading(true);
      let response = await axiosService.post(url, payload);
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
      // username
      const x = response.data.data.array[0];
      setUsername(x?.email);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getAllData({
      userid: uid,
      limit: ROWS,
      page: 1,
    });
  }, [currentTab]);

  return (
    <div className="users-detail-component">
      <div className="title-area">
        <h2 className="title">{username || "User"}</h2>
      </div>

      <div className="control-tabs">
        <Tabs
          activeKey={currentTab}
          onChange={(key) => setCurrentTab(key)}
          items={[
            {
              label: `Win Go Lottery`,
              key: "tab1",
              children: <></>,
            },
            {
              label: `AOV Lottery`,
              key: "tab2",
              children: <></>,
            },
            {
              label: `Nạp tiền`,
              key: "tab3",
              children: <></>,
            },
            {
              label: `Rút tiền`,
              key: "tab4",
              children: <></>,
            },
          ]}
        />
      </div>

      <Pagination
        total={totalRecord}
        current={current}
        onChange={onPaginationChange}
        showSizeChanger={false}
        showQuickJumper={false}
        className="pagination-box"
        hideOnSinglePage
      />

      {loading ? (
        <Spin />
      ) : (
        <Table
          size="small"
          columns={getCol()}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={getScrollValue()}
        />
      )}
    </div>
  );
}
