import { Descriptions, Modal, Pagination, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

import nhanvat0 from "../../assets/image/aov/nhanvat0.png";
import nhanvat1 from "../../assets/image/aov/nhanvat1.png";
import nhanvat2 from "../../assets/image/aov/nhanvat2.png";
import nhanvat3 from "../../assets/image/aov/nhanvat3.png";
import nhanvat4 from "../../assets/image/aov/nhanvat4.png";
import nhanvat5 from "../../assets/image/aov/nhanvat5.png";
import nhanvat6 from "../../assets/image/aov/nhanvat6.png";
import nhanvat7 from "../../assets/image/aov/nhanvat7.png";
import nhanvat8 from "../../assets/image/aov/nhanvat8.png";
import nhanvat9 from "../../assets/image/aov/nhanvat9.png";
import imgADD from "../../assets/image/aov/ad2.png";
import imgSPP from "../../assets/image/aov/sp2.png";
import quanhuy from "../../assets/image/aov/quanhuy.png";

export default function HistoryOrder() {
  const [array, setArray] = useState([]);
  const { timeMode, userOrderTemp, time } = useSelector((root) => root.gameReducer);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page,
    });
  };
  // end pagination

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [record, setRecord] = useState({});

  const showModal = (record) => {
    setRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // end modal

  const getOrderHistory = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/historyOrderAov", payload);
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  useEffect(() => {
    // khi user order, userOrderTemp sẽ tăng lên 1, dựa vào signal đó load lại dữ liệu
    setCurrent(1);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [userOrderTemp]);

  useEffect(() => {
    // bắt đầu game mới, load lại API để show kết quả game cũ nếu có
    if (
      (timeMode === 1 && time === 59) ||
      (timeMode === 3 && time === 179) ||
      (timeMode === 5 && time === 299) ||
      (timeMode === 10 && time === 599)
    ) {
      setCurrent(1);
      getOrderHistory({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time]);

  const renderOrder = (order) => {
    let img = undefined;

    /* SP lớn AD nhỏ */

    if (order == "big") {
      img = imgSPP;
    } else if (order == "small") {
      img = imgADD;
    } else if (order == "0") {
      img = nhanvat0;
    } else if (order == "5") {
      img = nhanvat5;
    } else if (order == "1") {
      img = nhanvat1;
    } else if (order == "3") {
      img = nhanvat3;
    } else if (order == "7") {
      img = nhanvat7;
    } else if (order == "9") {
      img = nhanvat9;
    } else if (order == "2") {
      img = nhanvat2;
    } else if (order == "4") {
      img = nhanvat4;
    } else if (order == "6") {
      img = nhanvat6;
    } else if (order == "8") {
      img = nhanvat8;
    }

    return (
      <img src={img} className={order == "big" || order == "small" ? "adsp-in-result-game" : "tuong-in-result-game"} />
    );
  };

  const renderResult = (num) => {
    switch (num) {
      case "0":
        return (
          <>
            <span className="type type-0">0</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
            <span className="type type-purple">Tím</span>
          </>
        );
      case "1":
        return (
          <>
            <span className="type type-1379">1</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "2":
        return (
          <>
            <span className="type type-2468">2</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "3":
        return (
          <>
            <span className="type type-1379">3</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "4":
        return (
          <>
            <span className="type type-2468">4</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "5":
        return (
          <>
            <span className="type type-5">5</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
            <span className="type type-purple">Tím</span>
          </>
        );
      case "6":
        return (
          <>
            <span className="type type-2468">6</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "7":
        return (
          <>
            <span className="type type-1379">7</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "8":
        return (
          <>
            <span className="type type-2468">8</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "9":
        return (
          <>
            <span className="type type-1379">9</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      default:
        break;
    }
  };

  const columns = [
    {
      title: "Kỳ xổ",
      key: "kyxo",
      width: "35%",
      render: (_, record) => {
        return <span style={{ fontSize: 12 }}>{record.idChartLottery}</span>;
      },
    },
    {
      title: "Cược",
      key: "cuoc",
      width: "20%",
      render: (_, { order }) => {
        return renderOrder(order);
      },
    },
    {
      title: "Kết quả",
      key: "ketqua",
      width: "35%",
      render: (_, record) => {
        if (record.status == "PENDING") {
          return <span>PENDING</span>;
        } else if (record.status == "SUCCESS") {
          if (record.profit != 0) {
            return (
              <>
                <span className="result-win">THẮNG</span> <br />
                <span className="result-win-money">+{Math.round(Number(record.profit)).toLocaleString()}</span>
                <img src={quanhuy} className="quan-huy-lq" />
              </>
            );
          } else if (record.profit == 0) {
            return (
              <>
                <span className="result-lose">THUA</span> <br />
                <span className="result-lose-money">
                  -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}
                </span>
                <img src={quanhuy} className="quan-huy-lq" />
              </>
            );
          }
        }
      },
    },
    {
      title: "",
      key: "chitiet",
      width: "10%",
      render: (_, record) => (
        <Tooltip title={"Chi tiết"}>
          <i
            className="fa-solid fa-circle-right"
            style={{ color: "grey", fontSize: 18, cursor: "pointer" }}
            onClick={() => showModal(record)}
          ></i>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="aov-history-order">
      <Table
        columns={columns}
        dataSource={array}
        rowKey={(record) => record.id}
        pagination={false}
        size="small"
        showHeader={false}
      />

      <div className="pagination-history-order-aov">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>

      <Modal
        closable={false}
        footer={null}
        centered
        width={window.innerWidth <= 576 ? "100%" : 360}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="result-modal"
      >
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label="ID">{record?.id}</Descriptions.Item>
          <Descriptions.Item label="Kỳ xổ">{record?.idChartLottery}</Descriptions.Item>
          <Descriptions.Item label="Số lượng mua">{record?.amount}</Descriptions.Item>
          <Descriptions.Item label="Số tiền mua">
            {Number(record?.balance).toLocaleString()} <img src={quanhuy} className="quan-huy-lq" />
          </Descriptions.Item>
          <Descriptions.Item label="Thuế">
            {Math.round(Number(Number(record?.amount) * Number(record?.balance) * (1 - record?.fee))).toLocaleString()}{" "}
            <img src={quanhuy} className="quan-huy-lq" />
          </Descriptions.Item>
          <Descriptions.Item label="Số tiền sau thuế">
            {Math.round(Number(Number(record?.amount) * Number(record?.balance) * record?.fee)).toLocaleString()}{" "}
            <img src={quanhuy} className="quan-huy-lq" />
          </Descriptions.Item>
          <Descriptions.Item label="Đặt cược">{renderOrder(record?.order)}</Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            {record.profit == null ? <span>PENDING</span> : null}
            {record.profit != null && record.profit != 0 ? (
              <span style={{ color: "green", fontWeight: "bold" }}>THẮNG</span>
            ) : null}
            {record.profit != null && record.profit == 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>THUA</span>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Số tiền">
            {record.profit == null ? <span>PENDING</span> : null}
            {record.profit != null && record.profit != 0 ? (
              <span style={{ color: "green", fontWeight: "bold" }}>
                +{Math.round(Number(record.profit)).toLocaleString()} <span>đ</span>
              </span>
            ) : null}
            {record.profit != null && record.profit == 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}{" "}
                <img src={quanhuy} className="quan-huy-lq" />
              </span>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Thời gian">{record.created_at}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
}
