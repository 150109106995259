const defaultState = {
  currentMenu: 1, // 12345
  payloadForTab2ToLoad: undefined,
};

export const promoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "CHANGE_PROMO_MENU": {
      return {
        ...state,
        currentMenu: action.payload,
      };
    }
    case "CHANGE_PROMO_MENU_FROM_TAB3_TO_TAB2": {
      return {
        ...state,
        currentMenu: 2,
        payloadForTab2ToLoad: action.payload,
      };
    }
    case "USER_CHOOSE_TIME": {
      return {
        ...state,
        payloadForTab2ToLoad: action.payload,
      };
    }
    case "CLEAR_PAYLOAD": {
      return {
        ...state,
        payloadForTab2ToLoad: undefined,
      };
    }
    default:
      return state;
  }
};
