import React from "react";
import Slider from "react-slick";

import banner1 from "../../assets/image/aov_banner_1.jpg";
import banner2 from "../../assets/image/aov_banner_2.jpg";
import banner3 from "../../assets/image/aov_banner_3.jpg";

export default function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        <div>
          <img src={banner1} />
        </div>
        <div>
          <img src={banner2} />
        </div>
        <div>
          <img src={banner3} />
        </div>
      </Slider>
    </div>
  );
}
