import { Alert } from "antd";
import React from "react";

export default function AdminNoAccess() {
  return (
    <div>
      <Alert message="Bạn không có quyền truy cập trang này" type="error" showIcon banner />
    </div>
  );
}
