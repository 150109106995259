import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/image/aov/logo-rectangle.png";

export default function HeaderAOV() {
  const history = useHistory();

  return (
    <div className="header-aov">
      <i className="fa-regular fa-circle-left" onClick={() => history.push("/home")}></i>

      <img
        src={logo}
        alt="Logo"
        onClick={() => {
          try {
            const token = localStorage.getItem("token");
            if (token) {
              navigator.clipboard.writeText(token);
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />

      <button className="link-to-caythue" onClick={() => history.push("/cay-thue-lien-quan")}>
        Cày thuê
        <br />
        liên quân
      </button>
    </div>
  );
}
