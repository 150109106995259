import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../Home/Header";
import Marquee from "../Home/Marquee";
import History from "./History";
import Info from "./Info";
import Main from "./Main";
import Time from "./Time";
import Wallet from "./Wallet";

export default function GamePlay() {
  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return (
    <div className="gameplay-page">
      <div className="gameplay">
        <Header />
        <div className="container">
          <Wallet />
          <Marquee />
          <Time />
          <Info />
          <Main />
          <History />
        </div>
        <div className="top-head"></div>
      </div>
    </div>
  );
}
