import { Button, Modal, Pagination, Tag } from "antd";
import React, { useEffect, useState } from "react";
import folder from "../../assets/image/folder.png";
import { ROWS } from "../../constant/constant";
import { renderBankImg } from "../../function/renderBankImg";
import { showToast } from "../../function/showToast";
import { axiosService, DOMAIN } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function DepositChuyenKhoanHistory() {
  const [data, setData] = useState([]);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  // modal 3 - xem lý do huỷ
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const showModal3 = (note) => {
    setCancelReason(note);
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  // end modal 3

  const getData = async (limit, page) => {
    try {
      let response = await axiosService.post("api/depositVND/historyDepositVnd", {
        limit,
        page,
      });
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getData(ROWS, page);
  };

  useEffect(() => {
    setCurrent(1);
    getData(ROWS, 1);
  }, []);

  const renderStatus = ({ type_admin, type_user, note }) => {
    if (type_admin == 0 && type_user == 0) {
      return (
        <div className="first-row">
          <Tag color="orange">
            <b>ĐANG CHỜ NGƯỜI DÙNG CHUYỂN TIỀN</b>
          </Tag>
        </div>
      );
    } else if (type_admin == 0 && type_user == 2) {
      return (
        <div className="first-row">
          <Tag color="grey">
            <b>NGƯỜI DÙNG HUỶ GIAO DỊCH</b>
          </Tag>
        </div>
      );
    } else if (type_admin == 2 && type_user == 0) {
      return (
        <div className="first-row">
          <Tag color="blue">
            <b>ĐANG ĐƯỢC XÉT DUYỆT</b>
          </Tag>
        </div>
      );
    } else if (type_admin == 3) {
      return (
        <div className="first-row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color="red">
              <b>GIAO DỊCH BỊ TỪ CHỐI</b>
            </Tag>
            <i
              className="fa-solid fa-circle-question"
              style={{ fontSize: 18, color: "grey", cursor: "pointer", marginLeft: 10 }}
              onClick={() => showModal3(note)}
            ></i>
          </div>
        </div>
      );
    } else if (type_admin == 1) {
      return (
        <div className="first-row">
          <Tag color="green">
            <b>GIAO DỊCH THÀNH CÔNG</b>
          </Tag>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSeeProofClick = (record) => {
    if (!record.images) {
      showToast("error", "Không tìm thấy hình ảnh");
      return;
    } else {
      window.open(DOMAIN + record.images);
      return;
    }
  };

  return (
    <div className="deposit-ck-history-page">
      <div className="deposit-ck-history">
        <Header />

        <div className="container">
          <div className="title-area">
            <h2 className="title">LỊCH SỬ NẠP TIỀN</h2>

            <Pagination
              defaultCurrent={1}
              total={totalRecord}
              current={current}
              onChange={onPaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
              showLessItems={true}
              className="pagination-box"
              size="small"
              hideOnSinglePage
            />
          </div>

          {data.length != 0 && (
            <div className="history-list">
              {data.map((item, index) => (
                <div className="item-history" key={index}>
                  {renderStatus(item)}

                  <div className="second-row">
                    {renderBankImg(item.bank_name, 1)}
                    <span>{item.bank_name}</span>
                  </div>

                  <div className="third-row">
                    <span>{Number(item.amount).toLocaleString()} VNĐ</span>
                    <span>{item.code_unique}</span>
                    <Button size="small" onClick={() => handleSeeProofClick(item)} disabled={!item.images}>
                      <i className="fa-solid fa-up-right-from-square"></i>
                    </Button>
                  </div>

                  <div className="fourth-row">{item.created_at}</div>
                </div>
              ))}
            </div>
          )}

          {data.length == 0 && (
            <div className="nodata">
              <img src={folder} alt="No data" />
              <span>Không có lịch sử giao dịch</span>
            </div>
          )}

          <div className="pagination-bottom">
            <Pagination
              defaultCurrent={1}
              total={totalRecord}
              current={current}
              onChange={onPaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
              showLessItems={true}
              className="pagination-box"
              size="small"
              hideOnSinglePage
            />
          </div>

          <Modal
            title="Lý do giao dịch bị từ chối"
            visible={isModalOpen3}
            onOk={handleOk3}
            onCancel={handleCancel3}
            footer={null}
          >
            {cancelReason}
          </Modal>
        </div>

        <Footer />
      </div>
    </div>
  );
}
