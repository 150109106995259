import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";

export default function SignupForm() {
  const history = useHistory();

  const [info, setInfo] = useState({
    email: "",
    password: "",
    password2: "",
    referral: "",
  });

  const [loading, setLoading] = useState(false);

  const signup = async (payload) => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/user/signup", payload);
      showAlert("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  // const getReferral = async () => {
  //   try {
  //     let response = await axiosService.get("api/user/getReferral");
  //     setInfo({
  //       ...info,
  //       referral: response.data.data,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSubmit = () => {
    // validation
    if (!info.email || info.email == "") {
      showAlert("error", "Vui lòng nhập email");
      return;
    }
    if (!info.password || info.password == "") {
      showAlert("error", "Vui lòng nhập mật khẩu");
      return;
    }
    if (!info.password2 || info.password2 == "") {
      showAlert("error", "Vui lòng xác nhận mật khẩu");
      return;
    }
    if (info.password2 != info.password) {
      showAlert("error", "Mật khẩu không khớp");
      return;
    }

    signup({
      email: info.email,
      password: info.password2,
      referral: info.referral ? info.referral : "f5a980f0",
      // referral: info.referral,
    });
  };

  useEffect(() => {
    if (!history.location.search || history.location.search == "") {
      // user vào bằng link signup thông thường
      // getReferral();
    } else if (!history.location.search.startsWith("?ref=")) {
      // user vào bằng link signup có ref nhưng sai định dạng -> vẫn dùng ref mặc định
      // getReferral();
    } else {
      // user vào bằng link signup có refferal, dùng referral đó luôn không gọi API
      const customReferral = history.location.search.substring(5);
      setInfo({
        ...info,
        referral: customReferral,
      });
    }
  }, []);

  if (localStorage.getItem("token") && localStorage.getItem("user")) {
    return <Redirect to={"/account"} />;
  }

  return (
    <div className="signup-form">
      <h2 className="title">ĐĂNG KÝ TÀI KHOẢN</h2>
      <form>
        <div className="field">
          <Input
            size="large"
            type="text"
            placeholder="Địa chỉ email"
            addonBefore={<i className="fa-solid fa-envelope"></i>}
            value={info.email}
            onChange={(e) => setInfo({ ...info, email: e.target.value })}
          />
        </div>

        <div className="field">
          <Input.Password
            size="large"
            placeholder="Mật khẩu"
            addonBefore={<i className="fa-solid fa-lock"></i>}
            value={info.password}
            onChange={(e) => setInfo({ ...info, password: e.target.value })}
          />
        </div>

        <div className="field">
          <Input.Password
            size="large"
            placeholder="Xác nhận mật khẩu"
            addonBefore={<i className="fa-solid fa-lock"></i>}
            value={info.password2}
            onChange={(e) => setInfo({ ...info, password2: e.target.value })}
          />
        </div>

        <div className="field">
          <Input
            size="large"
            type="text"
            placeholder="Mã giới thiệu"
            addonBefore={<i className="fa-solid fa-keyboard"></i>}
            value={info.referral}
            onChange={(e) => setInfo({ ...info, referral: e.target.value })}
          />
        </div>
      </form>
      <div style={{ textAlign: "center" }}>
        <Button size="large" type="primary" style={{ width: 200 }} onClick={() => handleSubmit()} loading={loading}>
          Đăng ký
        </Button>
      </div>
      <div className="helper">
        <span onClick={() => history.replace("/login")}>Đăng nhập</span>
      </div>
    </div>
  );
}
