import { QuestionCircleFilled } from "@ant-design/icons";
import { Alert, Button, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import up from "../../assets/image/photo.png";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function DepositChuyenKhoan() {
  const [amount, setAmount] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [currentBank, setCurrentBank] = useState(0); // first bank in list

  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(false);

  const [value, copy] = useCopyToClipboard();

  const [img, setImg] = useState(undefined); // for uploading
  const [path, setPath] = useState(undefined); // for showing preview

  const [currentTransaction, setCurrentTransaction] = useState(null);

  const history = useHistory();

  const getBanks = async () => {
    try {
      let response = await axiosService.post("api/depositVND/getBanking");
      setBankList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const checkDeposit = async () => {
    // API này dùng sau khi tạo giao dịch, để lấy thông tin hiển thị bước 2
    try {
      let response = await axiosService.post("api/depositVND/checkTransactionDepositVnd");
      // nếu thành công là có giao dịch, hiển thị dữ liệu ra bước 2
      setCurrentTransaction(response.data.data);
    } catch (error) {
      console.log(error);
      // nếu có lỗi là user chưa có giao dịch
      setCurrentTransaction(null);
    }
  };

  const checkDepositFirstTime = async () => {
    // API này dùng để kiểm tra nếu user đang có giao dịch pending thì chuyển đến các bước sau mà không cần nhập thông tin ở bước 1
    try {
      let response = await axiosService.post("api/depositVND/checkTransactionDepositVnd");

      // check có giao dịch
      const giaoDich = response.data.data;
      setCurrentTransaction(giaoDich);

      // type_admin==0 && type_user==0 -> mới tạo giao dịch chứ chưa xác nhận -> chuyển đến bước 2
      // type_admin==2 && type_user==0 -> user đã nhấn xác nhận chuyển tiền rồi
      //    + images = null -> chưa có hình ảnh minh chứng -> chuyển đến bước 3
      //    + có hình -> chuyển đến bước 4

      if (giaoDich.type_admin == 0 && giaoDich.type_user == 0) {
        setStep(2);
      } else if (giaoDich.type_admin == 2 && giaoDich.type_user == 0) {
        if (giaoDich.images == null) {
          setStep(3);
        } else {
          setStep(4);
        }
      }
    } catch (error) {
      console.log(error);
      // nếu không có thì bắt đầu từ bước 1
      setStep(1);
      setCurrentTransaction(null);
      getBanks();
    }
  };

  const createDeposit = async (data) => {
    setLoading(true);
    try {
      await axiosService.post("api/depositVND/createDepositVND", data);
      await checkDeposit(); // to get current transaction
      setStep(2);
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStep1Done = () => {
    if (!amount || amount == 0) {
      showAlert("error", "Hãy nhập số tiền");
      return;
    }

    if (Number(amount) < 50000) {
      showAlert("error", "Số tiền nạp tối thiểu là 50,000 VNĐ");
      return;
    }

    // mặc định chọn ngân hàng đầu tiên, catch trường hợp không load được danh sách ngân hàng
    if (bankList.length == 0) {
      showAlert("error", "Đã xảy ra lỗi. Vui lòng thử lại sau");
      return;
    }

    createDeposit({
      idBanking: bankList[currentBank].id,
      amount: Number(amount),
      message: randomStringNumber(),
    });
  };

  const verifyTransaction = async (idTransaction) => {
    try {
      await axiosService.post("api/depositVND/verifyTransactionDepositVnd", {
        idTransaction,
      });
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  const confirm1 = () => {
    Modal.confirm({
      title: "Xác nhận đã chuyển tiền",
      icon: <QuestionCircleFilled />,
      content: "Bạn có chắc chắn xác nhận đã chuyển tiền?",
      okText: "Tôi đã chuyển tiền",
      cancelText: "Huỷ bỏ",
      onOk: async () => {
        await verifyTransaction(currentTransaction.id);
        setStep(3);
      },
    });
  };

  const cancelDeposit = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/cancelTransactionDepositVnd", {
        idTransaction: id,
      });
      showToast("success", response.data.message);

      // reset to default
      setStep(1);
      setAmount(0);
      setCurrentBank(0);
      setCurrentTransaction(null);

      getBanks();
    } catch (error) {
      console.log(error);
    }
  };

  const confirm2 = () => {
    Modal.confirm({
      title: "Xác nhận huỷ lệnh chuyển tiền",
      icon: <QuestionCircleFilled />,
      content: (
        <div>
          <span>Bạn có chắc chắn xác nhận huỷ lệnh chuyển tiền?</span>
          <Alert type="warning" banner message="Không huỷ nếu đã chuyển tiền thành công" style={{ marginTop: 10 }} />
        </div>
      ),
      okText: "Huỷ lệnh chuyển tiền",
      cancelText: "Quay lại",
      onOk: () => {
        cancelDeposit(currentTransaction.id);
      },
    });
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImg(file);
  };

  const submitProof = async (userid, idTransaction, image) => {
    try {
      let fd = new FormData();
      fd.append("userid", userid);
      fd.append("idTransaction", idTransaction);
      fd.append("image", image);

      await axiosService.post("api/depositVND/uploadImageDeposiVND", fd);
      showToast("success", "Đã gửi minh chứng chuyển tiền thành công");
      setStep(4);
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  const handleSendImage = () => {
    if (!currentTransaction) {
      showToast("error", "Đã xảy ra lỗi");
      return;
    }

    if (!img) {
      showToast("error", "Vui lòng chọn hình ảnh");
      return;
    }

    submitProof(currentTransaction.userid, currentTransaction.id, img);
  };

  const randomStringNumber = () => {
    // random 3 số từ 0-99 ghép lại -> string
    const x = Math.floor(Math.random() * 100);
    const y = Math.floor(Math.random() * 100);
    const z = Math.floor(Math.random() * 100);
    return x + "" + y + "" + z;
  };

  useEffect(() => {
    if (img) {
      const path = URL.createObjectURL(img);
      setPath(path);
    }
  }, [img]);

  useEffect(() => {
    checkDepositFirstTime();
  }, []);

  // const calcAmount = () => {
  //   let promoAmount = 0;
  //   if (amount == null || amount == undefined || amount == 0) {
  //     promoAmount = 0;
  //   } else {
  //     if (amount < 2000000) {
  //       promoAmount = amount;
  //     } else if (amount < 5000000) {
  //       promoAmount = amount * 1.05;
  //     } else {
  //       promoAmount = amount * 1.1;
  //     }
  //   }

  //   return Math.round(Number(promoAmount)).toLocaleString();
  // };

  return (
    <>
      {step == 1 && (
        <div className="deposit-step-1">
          <div className="deposit-info">
            <h3 className="title">
              <i className="fa-solid fa-wallet"></i>
              <span>Thông tin chuyển khoản</span>
            </h3>

            {/* Promotion area */}
            {/* <div className="promotion-5">
              <h3>Happy New Year!</h3>
              <p className="promo-content">
                Khuyến mãi <span>5%</span> khi nạp tiền từ 2,000,000 đ
              </p>
              <p className="promo-content">
                <span>10%</span> khi nạp tiền từ 5,000,000 đ
              </p>
              <p className="promo-valid">Áp dụng từ 29 Tết đến hết mùng 9 Tết</p>
              <img src={fireworks} alt="Happy New Year" />
            </div> */}
            {/* Promotion area */}

            <div className="amount-input">
              <InputNumber
                size="large"
                placeholder="Nhập số tiền chuyển khoản"
                addonAfter="VNĐ"
                value={amount}
                onChange={(num) => setAmount(num)}
                controls={false}
                style={{ width: "100%" }}
                min={0}
              />
            </div>

            <div className="amount-select">
              <div
                onClick={() => {
                  setAmount(100000);
                }}
                className={`item ${amount == 100000 ? "active" : ""}`}
              >
                100K
              </div>
              <div
                onClick={() => {
                  setAmount(500000);
                }}
                className={`item ${amount == 500000 ? "active" : ""}`}
              >
                500K
              </div>
              <div
                onClick={() => {
                  setAmount(10000000);
                }}
                className={`item ${amount == 10000000 ? "active" : ""}`}
              >
                10M
              </div>
              <div
                onClick={() => {
                  setAmount(20000000);
                }}
                className={`item ${amount == 20000000 ? "active" : ""}`}
              >
                20M
              </div>
              <div
                onClick={() => {
                  setAmount(100000000);
                }}
                className={`item ${amount == 100000000 ? "active" : ""}`}
              >
                100M
              </div>
              <div
                onClick={() => {
                  setAmount(1000000000);
                }}
                className={`item ${amount == 1000000000 ? "active" : ""}`}
              >
                1,000M
              </div>
            </div>

            {/* Promotion area */}
            {/* {amount != null && amount != undefined && amount != 0 && (
              <div className="promotion-5-amount">
                <Alert
                  className="promotion-alert"
                  type="warning"
                  banner
                  showIcon={false}
                  message={`Số tiền sẽ nhận được: ${calcAmount()} đ`}
                />
              </div>
            )} */}
            {/* Promotion area */}

            <div className="bank-choice">
              <span className="bank-choice-title">Chọn ngân hàng bạn muốn chuyển khoản đến</span>

              <div className="list">
                {bankList.map((bank, index) => {
                  return (
                    <div
                      className={`item-banking ${currentBank == index ? "active" : ""}`}
                      key={index}
                      onClick={() => setCurrentBank(index)}
                    >
                      {renderBankImg(bank.name_banking, 1)}
                      <span>{bank.name_banking}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="deposit-warning">
            <Alert type="warning" banner message="Quý khách phải chuyển khoản đúng số tiền đã tạo lệnh." />
            <Alert type="warning" banner message="Hệ thống sẽ tự động cập nhật số dư." />
            <Alert
              type="warning"
              banner
              message="Nếu quý khách chuyển sai số tiền đã tạo lệnh, khoản tiền bị thất thoát chúng tôi sẽ không chịu trách nhiệm."
            />
          </div>

          <div className="deposit-action">
            <Button
              type="primary"
              size="large"
              style={{ width: 150 }}
              loading={loading}
              onClick={() => handleStep1Done()}
            >
              Tiếp tục
            </Button>
          </div>
        </div>
      )}

      {step == 2 && (
        <div className="deposit-step-2">
          <div className="deposit-info">
            <h3 className="title">
              <i className="fa-solid fa-wallet"></i>
              <span>Thông tin chuyển khoản</span>
            </h3>

            <div className="deposit-warning">
              <Alert
                type="warning"
                banner
                message="Quý khách phải chuyển khoản đúng lệnh đã tạo như thông tin dưới đây. Nếu chuyển khoản sai, khoản tiền bị thất thoát chúng tôi sẽ không chịu trách nhiệm."
              />
            </div>

            <div className="deposit-blabla">
              <div className="item">
                <div className="item-left">Ngân hàng</div>
                <div className="item-right">
                  <span>{currentTransaction?.name_banking_admin}</span>
                </div>
              </div>

              <div className="item">
                <div className="item-left">Số tài khoản</div>
                <div className="item-right">
                  <span>{currentTransaction?.number_banking_admin}</span>
                  <i className="fa-regular fa-clone" onClick={() => copy(currentTransaction?.number_banking_admin)}></i>
                </div>
              </div>

              <div className="item">
                <div className="item-left">Tên tài khoản</div>
                <div className="item-right">
                  <span>{currentTransaction?.owner_banking_admin}</span>
                </div>
              </div>

              <div className="item">
                <div className="item-left">Số tiền</div>
                <div className="item-right">
                  <span>{currentTransaction?.amount.toLocaleString()}</span>
                  <i className="fa-regular fa-clone" onClick={() => copy(currentTransaction?.amount)}></i>
                </div>
              </div>

              <div className="item">
                <div className="item-left">Nội dung</div>
                <div className="item-right">
                  <span>{currentTransaction?.code_unique}</span>
                  <i className="fa-regular fa-clone" onClick={() => copy(currentTransaction?.code_unique)}></i>
                </div>
              </div>
            </div>
          </div>

          <div className="deposit-action">
            <Button type="primary" size="large" style={{ width: 220 }} onClick={() => confirm1()}>
              Tôi đã chuyển tiền
            </Button>

            <Button style={{ width: 120, marginTop: 15 }} onClick={() => confirm2()}>
              Huỷ
            </Button>
          </div>
        </div>
      )}

      {step == 3 && (
        <div className="deposit-step-3">
          <div className="deposit-info">
            <h3 className="title">
              <i className="fa-solid fa-wallet"></i>
              <span>Gửi minh chứng đã chuyển khoản</span>
            </h3>

            <div className="deposit-warning">
              <Alert
                type="warning"
                banner
                message="Hãy sử dụng ảnh chụp màn hình giao dịch. Hình ảnh càng rõ nét càng tốt."
              />
            </div>

            <div className="deposit-upload">
              <input type="file" id="hidden-input-file" accept="image/*" onChange={handleChangeImage} />

              {!path && (
                <div className="evidence-upload" onClick={() => document.getElementById("hidden-input-file").click()}>
                  <img src={up} alt="Upload image" />
                  <span>Nhấn vào đây để tải ảnh lên</span>
                </div>
              )}

              {path && (
                <div
                  className="evidence-upload-preview"
                  onClick={() => document.getElementById("hidden-input-file").click()}
                >
                  <img src={path} />
                </div>
              )}
            </div>
          </div>

          <div className="deposit-action">
            <Button type="primary" size="large" style={{ width: 200 }} onClick={() => handleSendImage()}>
              Gửi hình ảnh
            </Button>
          </div>
        </div>
      )}

      {step == 4 && (
        <div className="deposit-step-4">
          <Alert
            type="success"
            banner
            message="Đã xong - Đang chờ duyệt"
            description="Hệ thống sẽ kiểm duyệt giao dịch của bạn và sẽ cập nhật số dư tài khoản trong thời gian sớm nhất. Cản ơn bạn đã
              giao dịch."
          />

          <div className="deposit-see-history">
            <Button type="primary" size="large" style={{ width: 220 }} onClick={() => history.push("/deposit-history")}>
              Xem lịch sử giao dịch
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
