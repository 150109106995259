import React from "react";
import customerservice from "../../assets/image/customerservice.png";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function HelpCenter() {
  return (
    <div className="help-center-page">
      <div className="help-center">
        <Header />

        <div className="container">
          <div className="customerservice-img">
            <img src={customerservice} alt="Customer service" />
          </div>

          <div className="customerservice-list">
            <div className="item" onClick={() => window.open("https://t.me/spAOVmoney")}>
              <div className="left">
                <i className="fa-brands fa-telegram"></i>
                <span>Telegram</span>
              </div>

              <div className="right">
                <i className="fa-solid fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
