import { Button, Drawer, Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../assets/image/avatar.svg";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function AccInfo() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const { user } = useSelector((root) => root.userReducer);
  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpen(true);
    setName(user?.userName);
  };
  const closeDrawer = () => {
    setOpen(false);
    setName("");
  };

  const handleChangeName = () => {
    if (!name || name == "") {
      showToast("error", "Hãy nhập tên người dùng");
      return;
    }

    changeUserName(name.trim());
  };

  const changeUserName = async (newName) => {
    try {
      await axiosService.post("api/user/changeUserName", {
        userNameNew: newName,
      });
      showToast("success", "Đã đổi tên thành công");
      await getProfile();
    } catch (error) {
      console.log(error);
      showToast("error", "Đã xảy ra lỗi");
    } finally {
      closeDrawer();
    }
  };

  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_PROFILE",
        payload: response.data.data,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      const new_userInLocalStorage = {
        ...userInLocalStorage,
        ...response.data.data,
      };
      localStorage.setItem("user", JSON.stringify(new_userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="acc-info">
      <div className="left">
        <img src={avatar} />
      </div>

      <div className="right">
        <div className="u-username">{user?.userName ?? "Username"}</div>
        <div className="u-email">{user?.email ?? "Email"}</div>
      </div>

      <Button size="small" shape="circle" onClick={() => showDrawer()}>
        <i className="fa-solid fa-pen-to-square"></i>
      </Button>

      <Drawer placement="bottom" onClose={closeDrawer} open={open} closable={false} className="ajkenkople-drawer">
        <form className="form-change-username">
          <div className="field">
            <label htmlFor="field_username">Chỉnh sửa tên người dùng</label>
            <Input
              size="large"
              type="text"
              id="field_username"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="field action-field">
            <Button type="primary" onClick={() => handleChangeName()}>
              Cập nhật thông tin
            </Button>
          </div>
        </form>
      </Drawer>
    </div>
  );
}
