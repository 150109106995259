import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Account from "./components/Account/Account";
import Auth from "./components/Account/Auth";
import ChangePassword from "./components/Account/ChangePassword";
import ForgotPassword from "./components/Account/ForgotPassword";
import HelpCenter from "./components/Account/HelpCenter";
import Admin from "./components/Admin/Admin.jsx";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminNoAccess from "./components/Admin/AdminNoAccess";
import AdminTemplate from "./components/Admin/AdminTemplate.jsx";
import DetailUser from "./components/Admin/DetailUser";
import ManageDeposit from "./components/Admin/ManageDeposit";
import ManageDepositBank from "./components/Admin/ManageDepositBank";
import ManageWithdraw from "./components/Admin/ManageWithdraw";
import ManageWithdrawBank from "./components/Admin/ManageWithdrawBank";
import Orders from "./components/Admin/Orders";
import OrdersAOV from "./components/Admin/OrdersAOV";
import Profit from "./components/Admin/Profit";
import SetResult from "./components/Admin/SetResult";
import SetResultAOV from "./components/Admin/SetResultAOV";
import AOVGameplay from "./components/AOVGamePlay/AOVGamePlay";
import CayThueLienQuan from "./components/CayThueLienQuan/CayThueLienQuan";
import LichSuCayThue from "./components/CayThueLienQuan/LichSuCayThue";
import Deposit from "./components/Deposit/Deposit";
import DepositChuyenKhoanHistory from "./components/Deposit/DepositChuyenKhoanHistory";
import GamePlay from "./components/GamePlay/GamePlay";
import Home from "./components/Home/Home";
import HowToPlay from "./components/HowToPlay/HowToPlay";
import Page404 from "./components/Page404/Page404";
import PromoHome from "./components/Promo/PromoHome";
import RedirectHome from "./components/RedirectHome/RedirectHome";
import ScrollToTop from "./components/ScrollToTop";
import Verify from "./components/Verify/Verify";
import VerifyForgotPassword from "./components/Verify/VerifyForgotPassword";
import Withdraw from "./components/Withdraw/Withdraw";
import WithdrawHistory from "./components/Withdraw/WithdrawHistory";

export default function App() {
  const { user } = useSelector((root) => root.userReducer);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/wingo-gameplay" component={GamePlay} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/login" component={Auth} />
          <Route exact path="/signup" component={Auth} />
          <Route exact path="/guide" component={HowToPlay} />
          <Route exact path="/promotion" component={PromoHome} />
          <Route exact path="/deposit" component={Deposit} />
          <Route exact path="/deposit-history" component={DepositChuyenKhoanHistory} />
          <Route exact path="/withdraw" component={Withdraw} />
          <Route exact path="/withdraw-history" component={WithdrawHistory} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/help-center" component={HelpCenter} />
          <Route exact path="/aov-gameplay" component={AOVGameplay} />
          <Route exact path="/cay-thue-lien-quan" component={CayThueLienQuan} />
          <Route exact path="/lich-su-cay-thue" component={LichSuCayThue} />

          <AdminTemplate path="/dashboard" component={AdminDashboard} />
          <AdminTemplate path="/users-management" component={Admin} />
          <AdminTemplate path="/deposit-management" component={ManageDeposit} />
          <AdminTemplate path="/withdraw-management" component={ManageWithdraw} />
          <AdminTemplate path="/deposit-bank" component={user?.id === 1 ? ManageDepositBank : AdminNoAccess} />
          <AdminTemplate path="/withdraw-bank" component={user?.id === 1 ? ManageWithdrawBank : AdminNoAccess} />
          <AdminTemplate path="/dashboard-wingo" component={user?.id === 1 ? SetResult : Orders} />
          <AdminTemplate path="/dashboard-aov" component={user?.id === 1 ? SetResultAOV : OrdersAOV} />
          <AdminTemplate path="/history-profit" component={Profit} />
          <AdminTemplate path="/history-order-wingo" component={Orders} />
          <AdminTemplate path="/history-order-aov" component={OrdersAOV} />
          <AdminTemplate path="/users-detail/:uid" component={DetailUser} />

          <Route exact path="/verify/:token" component={Verify} />
          <Route exact path="/verifyForgotPassword/:token" component={VerifyForgotPassword} />
          <Route exact path="/" component={RedirectHome} />
          <Route exact path="*" component={Page404} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}
