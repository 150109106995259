// import "antd/dist/antd.css";
import "./assets/theme/antd-customized.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import "./assets/scss/style.scss";
import "./index.scss";
import store from "./redux/configStore";
import "animate.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
