import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import AccInfo from "../Account/AccInfo";
import AccMenu from "../Account/AccMenu";
import Wallet from "../GamePlay/Wallet";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function Account() {
  const history = useHistory();
  const dispatch = useDispatch();

  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({
      type: "USER_LOGOUT",
    });
    history.replace("/login");
  };

  return (
    <div className="account-page">
      <div className="account">
        <Header />

        <div className="banner"></div>

        <div className="container">
          <AccInfo />
          <Wallet />
          <AccMenu />

          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Button size="large" type="primary" style={{ width: 200 }} onClick={() => logout()}>
              Đăng xuất
            </Button>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
