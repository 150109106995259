import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "../../assets/image/icon1.png";
import icon2 from "../../assets/image/icon2.png";
import icon3 from "../../assets/image/icon3.png";
import icon4 from "../../assets/image/icon4.png";
import icon5 from "../../assets/image/icon5.png";
import { axiosService } from "../../util/service";

export default function WithdrawNote() {
  const { user } = useSelector((root) => root.userReducer);
  const dispatch = useDispatch();

  // để cập nhật phần tổng cược còn lại, số lần rút tiền còn lại
  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_PROFILE",
        payload: response.data.data,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      const new_userInLocalStorage = {
        ...userInLocalStorage,
        ...response.data.data,
      };
      localStorage.setItem("user", JSON.stringify(new_userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="withdraw-extra-note">
      <div className="item item1">
        <img src={icon1} />
        <span>
          Lệ phí: <b>3%</b>
        </span>
      </div>

      <div className="item item2">
        <img src={icon2} />
        <span>
          Tổng cược còn lại: <b>{Math.round(Number(user?.totalOrder)).toLocaleString()} đ</b>
        </span>
      </div>

      <div className="item item4">
        <img src={icon4} />
        <span>
          Số lần rút tiền còn lại: <b>{user?.numberWithdraw}</b>
        </span>
      </div>

      <div className="item item3">
        <img src={icon3} />
        <span>
          Thời gian rút tiền: <b>00:00 - 23:55</b>
        </span>
      </div>

      <div className="item item5">
        <img src={icon5} />
        <span>
          Phạm vi số tiền rút: <b>50,000 đ - 1,000,000,000 đ</b>
        </span>
      </div>
    </div>
  );
}
