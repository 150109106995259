import { QuestionCircleFilled } from "@ant-design/icons";
import { Alert, Button, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBankList } from "../../function/getBankList";
import { renderBankImg } from "../../function/renderBankImg";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";
import Wallet from "../GamePlay/Wallet";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import WithdrawNote from "./WithdrawNote";

export default function Withdraw() {
  const [isBankingAccount, setIsBankingAccount] = useState(null); // null: chưa biết - true/false: có / không có
  const [bankInfo, setBankInfo] = useState(null); // thông tin ngân hàng khi isBankingAccount = true

  const [step, setStep] = useState(1);

  const [info, setInfo] = useState({
    bank: "",
    number: "",
    name: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(undefined); // vnd
  const [realAmount, setRealAmount] = useState(undefined); // vnd - số tiền thực nhận

  const getBankingUser = async () => {
    try {
      let response = await axiosService.post("api/depositVND/getBankingUser");
      // user đã có tài khoản ngân hàng
      setIsBankingAccount(true);
      setBankInfo(response.data.data);
    } catch (error) {
      console.log(error);
      // user chưa có tài khoản ngân hàng
      setIsBankingAccount(false);
    }
  };

  const addBankUser = async (data) => {
    try {
      let response = await axiosService.post("api/depositVND/addListBanking", data);
      showToast("success", response.data.message);

      await getBankingUser();
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  // getProfile just to update balance
  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_BALANCE",
        payload: response.data.data.balance,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      userInLocalStorage.balance = response.data.data.balance;
      localStorage.setItem("user", JSON.stringify(userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAmountWithdraw = () => {
    if (!amount || amount <= 0 || isNaN(Number(amount))) {
      showToast("error", "Số tiền không hợp lệ");
      return;
    }

    if (!Number.isInteger(Number(amount))) {
      showToast("error", "Số tiền không hợp lệ");
      return;
    }

    withdraw(Number(amount));
  };

  const withdraw = async (amount) => {
    try {
      let response = await axiosService.post("api/depositVND/widthdrawVND", { amount });
      setAmount(undefined);
      getProfile();
      setStep(2);
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  const handleChangeSelectBank = (value) => {
    setInfo({
      ...info,
      bank: value,
    });
  };

  const handleSubmitBankAccount = () => {
    if (!info.bank) {
      showToast("error", "Please choose a bank");
      return;
    }
    if (!info.number) {
      showToast("error", "Please enter your account number");
      return;
    }
    if (!info.name) {
      showToast("error", "Please enter your account name");
      return;
    }

    Modal.confirm({
      title: "Xác nhận",
      icon: <QuestionCircleFilled />,
      content: (
        <div className="double-check-bank-account">
          <span>Vui lòng kiểm tra lại thông tin ngân hàng của bạn. Thông tin không thể thay đổi sau này.</span>
          <div className="infoo infoo1">
            <p>Tên ngân hàng: </p>
            <span>{info.bank}</span>
          </div>
          <div className="infoo">
            <p>Số tài khoản: </p>
            <span>{info.number}</span>
          </div>
          <div className="infoo">
            <p>Tên chủ tài khoản: </p>
            <span>{info.name}</span>
          </div>
        </div>
      ),
      okText: "Thêm ngân hàng",
      cancelText: "Huỷ bỏ",
      onOk: async () => {
        await addBankUser({
          owner_banking: info.name,
          name_banking: info.bank,
          number_banking: info.number,
        });
      },
    });
  };

  useEffect(() => {
    getBankingUser();
  }, []);

  useEffect(() => {
    if (amount != undefined) {
      const soTienRut = Number(amount);
      const soTienThucTe = soTienRut * 0.97;
      const x = Math.round(soTienThucTe);
      setRealAmount(x);
    }
  }, [amount]);

  if (isBankingAccount == null) {
    // tránh màn hình bị chớp khi load data
    return <></>;
  }

  return (
    <div className="withdraw-page">
      <div className="withdraw">
        <Header />

        <div className="container">
          <Wallet noButton={true} />

          {isBankingAccount == false && (
            <div className="user-not-have-account">
              <Alert
                message="Vui lòng nhập thông tin tài khoản ngân hàng trước khi rút tiền."
                type="warning"
                showIcon
                banner
              />

              <h3 className="title">
                <i className="fa-solid fa-wallet"></i>
                <span>Thêm tài khoản ngân hàng</span>
              </h3>

              <form className="user-add-bank-form">
                <div className="field bank-field">
                  <label htmlFor="bank">Tên ngân hàng</label>
                  <Select
                    size="large"
                    id="bank"
                    style={{ width: "100%" }}
                    onChange={handleChangeSelectBank}
                    value={info.bank}
                    options={getBankList().map((bank) => {
                      return {
                        value: bank,
                        label: bank,
                      };
                    })}
                  />
                </div>

                <div className="field number-field">
                  <label htmlFor="number">Số tài khoản</label>
                  <Input
                    type="text"
                    size="large"
                    id="number"
                    value={info.number}
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        number: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="field name-field">
                  <label htmlFor="name">Tên chủ tài khoản</label>
                  <Input
                    type="text"
                    size="large"
                    id="name"
                    value={info.name}
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="add-bank-action">
                  <Button size="large" type="primary" onClick={handleSubmitBankAccount}>
                    Thêm ngân hàng
                  </Button>
                </div>
              </form>
            </div>
          )}

          {isBankingAccount == true && step == 1 && (
            <div className="user-withdraw-step">
              <h3 className="title">
                <i className="fa-solid fa-wallet"></i>
                <span>Thông tin rút tiền</span>
              </h3>

              <div className="bank-info">
                <div className="first-row">{renderBankImg(bankInfo.name_banking, 1)}</div>

                <div className="second-row">
                  <span className="s1">{bankInfo.name_banking}</span>
                  <span className="s2">{bankInfo.number_banking}</span>
                  <span className="s3">{bankInfo.owner_banking}</span>
                </div>
              </div>

              <div className="form">
                <div className="amount-field field">
                  <label htmlFor="amount">Số tiền cần rút</label>
                  <Input
                    type="text"
                    style={{ width: "100%" }}
                    size="large"
                    id="amount"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    addonAfter="VNĐ"
                    placeholder="Nhập số tiền cần rút"
                  />
                </div>

                <div className="real-field field">
                  <label htmlFor="real">Số tiền thực tế nhận được (lệ phí 3%)</label>
                  <Input
                    type="text"
                    style={{ width: "100%" }}
                    size="large"
                    id="real"
                    name="real"
                    value={realAmount}
                    disabled
                    addonAfter="VNĐ"
                  />
                </div>

                <div className="confirm-button">
                  <Button
                    size="large"
                    type="primary"
                    style={{ width: 140 }}
                    onClick={() => handleSubmitAmountWithdraw()}
                  >
                    Rút tiền
                  </Button>
                </div>
              </div>
            </div>
          )}

          {isBankingAccount == true && step == 2 && (
            <div className="withdraw-success">
              <Alert
                showIcon
                banner
                type="success"
                message="Đang chờ duyệt"
                description="Quý khách đã gửi yêu cầu rút tiền thành công. Yêu cầu của quý khách sẽ được duyệt trong thời gian sớm nhất. Cảm ơn quý khách đã giao dịch!"
              />

              <div className="withdraw-success-action">
                <Button
                  type="primary"
                  size="large"
                  style={{ width: 180 }}
                  onClick={() => {
                    history.push("/account");
                  }}
                >
                  Tiếp tục
                </Button>
                <Button
                  size="large"
                  style={{ width: 180 }}
                  onClick={() => {
                    history.push("/withdraw-history");
                  }}
                >
                  Xem lịch sử
                </Button>
              </div>
            </div>
          )}

          <WithdrawNote />
        </div>

        <Footer />
      </div>
    </div>
  );
}
