import { Drawer, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import logo from "../../assets/image/aov/logo-rectangle.png";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";

export default function NavbarAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((root) => root.userReducer);

  const [messageConfig, setMessageConfig] = useState(0); // 0 tắt hoặc 1 bật

  const [open, setOpen] = useState(false);
  const showDrawer = async () => {
    await getMessageConfigOrder();
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch({
      type: "USER_LOGOUT",
    });
    history.replace("/login");
  };

  const onSwitchChange = (checked) => {
    updateMessageConfigOrder();
  };

  const getMessageConfigOrder = async () => {
    try {
      let response = await axiosService.post("api/admin/getMessageConfig");
      setMessageConfig(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMessageConfigOrder = async () => {
    try {
      await axiosService.post("api/admin/updateMessageConfig");
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      await getMessageConfigOrder();
    }
  };

  if (!localStorage.getItem("user") || !localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  } else {
    const x = JSON.parse(localStorage.getItem("user"));
    if (x.id == 1) {
      // là admin -> ở lại trang này
    } else {
      // không phải admin
      if (x.type == 1) {
      } else {
        return <Redirect to="/home" />;
      }
    }
  }

  return (
    <div className="navbar">
      <div className="logo">
        <i
          className="fa-solid fa-bars"
          onClick={() => {
            dispatch({
              type: "TOGGLE_SIDEBAR",
            });
          }}
        ></i>

        {window.innerWidth >= 375 && <img src={logo} alt="AOV Money" />}
      </div>

      <div className="action">
        {user?.id === 1 && (
          <div className="action-setting" onClick={showDrawer}>
            Setting
          </div>
        )}
        <div className="action-logout" onClick={signOut}>
          Log out
        </div>
      </div>

      <Drawer title="Cài đặt" onClose={onClose} open={open} width={300}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ fontSize: 16 }}>Message</div>
          <Switch defaultChecked={messageConfig == 1} onChange={onSwitchChange} />
        </div>
      </Drawer>
    </div>
  );
}
