import { Button, Input, Pagination, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function ManageWithdraw() {
  const { user } = useSelector((root) => root.userReducer);

  const [data, setData] = useState([]);

  // for searching
  const [searchWord, setSearchWord] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const getAllData = async (limit, page) => {
    let url;
    if (user.id == 1) {
      // admin
      url = "api/depositVND/getHistoryWidthdrawAdmin";
    } else if (user.type == 1) {
      url = "api/admin/getListUserWithdrawParentAdmin";
    } else {
      url = "";
    }

    try {
      let response = await axiosService.post(url, { limit, page });
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmWithdraw = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/comfirmWidthdrawVnd", { id });
      showToast("success", response.data.message);
      await getAllData(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const cancelWithdraw = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/cancelWidthdrawVnd", { id });
      showToast("success", response.data.message);
      await getAllData(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const columns = [
    {
      title: "UserID",
      key: "UserID",
      dataIndex: "userid",
      width: 70,
    },
    {
      title: "User",
      key: "User",
      width: 200,
      render: (_, record) => {
        return (
          <div>
            <span>{record.userName ?? "-"}</span> <br />
            <span>{record.email ?? "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Ngân hàng",
      key: "Bank",
      width: 150,
      render: (_, record) => {
        return (
          <div className="bank-item-w-vnd">
            {renderBankImg(record.nameBanking, 1)}
            <span>{record.nameBanking}</span>
          </div>
        );
      },
    },
    {
      title: "Tài khoản",
      key: "Account",
      width: 200,
      render: (_, record) => {
        return (
          <div>
            <span>{record.numberBanking}</span> <br />
            <span>{record.ownerBanking}</span>
          </div>
        );
      },
    },
    {
      title: "Số tiền",
      key: "Amount",
      dataIndex: "amount",
      width: 150,
      render: (text) => {
        return Number(text).toLocaleString();
      },
    },
    {
      title: "Số tiền thực tế",
      key: "Số tiền thực tế",
      width: 150,
      render: (_, { amount }) => {
        const soTienRut = Number(amount);
        const soTienThucTe = soTienRut * 0.97;
        return Math.round(soTienThucTe).toLocaleString();
      },
    },
    {
      title: "Thời gian",
      key: "Time",
      dataIndex: "created_at",
      width: 180,
    },
    {
      title: "Trạng thái",
      key: "Status",
      width: 120,
      render: (_, { type }) => {
        if (type == 1) {
          // rút tiền thành công
          return (
            <Tag color="green">
              <strong>THÀNH CÔNG</strong>
            </Tag>
          );
        } else if (type == 2) {
          // chờ admin duyệt
          return (
            <Tag color="blue">
              <strong>ĐANG CHỜ DUYỆT</strong>
            </Tag>
          );
        } else if (type == 0) {
          // admin từ chối
          return (
            <Tag color="red">
              <strong>TỪ CHỐI</strong>
            </Tag>
          );
        }
      },
    },
    {
      title: "Action",
      key: "Action",
      width: 180,
      render: (_, record) => {
        if (record.type == 2) {
          // đang chờ admin duyệt -> 2 nút đồng ý hoặc từ chối
          return (
            <>
              <Button size="small" onClick={() => confirmWithdraw(record.id)}>
                Chấp nhận
              </Button>
              <Button size="small" danger style={{ marginLeft: 6 }} onClick={() => cancelWithdraw(record.id)}>
                Từ chối
              </Button>
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  useEffect(() => {
    setCurrent(1);
    getAllData(ROWS, 1);
  }, []);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllData(ROWS, page);
  };

  const resetTable = () => {
    setCurrent(1);
    getAllData(ROWS, 1);
    setIsSearching(false);
  };

  const onSearch = (keyword) => {
    setSearchWord(keyword);

    if (keyword !== "") {
      searchData(keyword);
      setIsSearching(true);
    } else {
      resetTable();
    }
  };

  const onChange = (e) => {
    const keyword = e.target.value;
    setSearchWord(keyword);

    if (keyword === "") {
      resetTable();
    }
  };

  const searchData = async (keyword) => {
    try {
      const response = await axiosService.post("api/admin/sreachListToEmail", {
        limit: 1000,
        page: 1,
        keyWord: keyword,
        table: "tb_widthdraw_vnd",
      });
      setData(response.data.data);

      setCurrent(1);
      setTotalRecord(10);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mn-w-vnd">
      <div className="title-area">
        <h2 className="title">Giao dịch rút tiền</h2>

        {user?.id === 1 && (
          <Input.Search
            placeholder="Tìm kiếm email"
            className="search-box"
            allowClear
            onSearch={onSearch}
            onChange={onChange}
          />
        )}

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          hideOnSinglePage
        />
      </div>

      <Table
        columns={user?.id === 1 ? columns : columns.filter((col) => col.key != "Action")}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={
          isSearching
            ? { position: ["topRight"], defaultCurrent: 1, showSizeChanger: false, showQuickJumper: false }
            : false
        }
        scroll={{
          x: 1500,
        }}
      />
    </div>
  );
}
