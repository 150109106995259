import { Button, Drawer, InputNumber, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

import imgADD from "../../assets/image/aov/imgADD.png";
import imgSPP from "../../assets/image/aov/imgSPP.png";
import nhanvat0 from "../../assets/image/aov/nhanvat0.png";
import nhanvat1 from "../../assets/image/aov/nhanvat1.png";
import nhanvat2 from "../../assets/image/aov/nhanvat2.png";
import nhanvat3 from "../../assets/image/aov/nhanvat3.png";
import nhanvat4 from "../../assets/image/aov/nhanvat4.png";
import nhanvat5 from "../../assets/image/aov/nhanvat5.png";
import nhanvat6 from "../../assets/image/aov/nhanvat6.png";
import nhanvat7 from "../../assets/image/aov/nhanvat7.png";
import nhanvat8 from "../../assets/image/aov/nhanvat8.png";
import nhanvat9 from "../../assets/image/aov/nhanvat9.png";
import quanhuy from "../../assets/image/aov/quanhuy.png";

export default function Main() {
  const { timeMode, time } = useSelector((root) => root.gameReducer);

  // loading when order
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState({
    color: "", // for CSS
    content: "", // for displaying to user
    data: "", // for calling API
  });
  const [money, setMoney] = useState(1000);
  const [amount, setAmount] = useState(1);
  const [total, setTotal] = useState(1000);

  const dispatch = useDispatch();

  const showDrawer = (color, content, data) => {
    setOpen(true);
    setRecord({
      color,
      content,
      data,
    });
    setMoney(1000);
    setAmount(1);
    setTotal(1000);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const renderTopDrawer = () => {
    return (
      <div className="aov-top">
        <div>{timeMode} phút</div>
        <img src={record.content} alt={record.data} />
      </div>
    );
  };

  const handleCong = () => {
    // cộng thì vô tư
    setAmount((amount) => amount + 1);
  };
  const handleTru = () => {
    // trừ thì không cho trừ xuống dưới 1
    if (amount <= 1) {
      return;
    }
    setAmount((amount) => amount - 1);
  };

  const renderCenterDrawer = () => {
    return (
      <div className="center">
        <div className="field">
          <span>Số tiền</span>
          <div>
            <button className={money == 1000 ? "money money-active" : "money"} onClick={() => setMoney(1000)}>
              1,000
            </button>
            <button className={money == 10000 ? "money money-active" : "money"} onClick={() => setMoney(10000)}>
              10,000
            </button>
            <button className={money == 100000 ? "money money-active" : "money"} onClick={() => setMoney(100000)}>
              100,000
            </button>
            <button className={money == 1000000 ? "money money-active" : "money"} onClick={() => setMoney(1000000)}>
              1,000,000
            </button>
          </div>
        </div>

        <div className="field">
          <span>Số lượng</span>

          <div className="new-amount-zone">
            <Button shape="circle" style={{ marginRight: 10 }} onClick={() => handleTru()} disabled={amount <= 1}>
              –
            </Button>
            <InputNumber
              min={1}
              style={{ width: 90 }}
              value={amount}
              onChange={(value) => setAmount(value)}
              controls={false}
              className="input-number-in-open-drawer"
            />
            <Button shape="circle" style={{ marginLeft: 10 }} onClick={() => handleCong()}>
              +
            </Button>
          </div>
        </div>

        <div className="field">
          <span></span>
          <div>
            <button className={amount == 1 ? "amount amount-active" : "amount"} onClick={() => setAmount(1)}>
              x1
            </button>
            <button className={amount == 5 ? "amount amount-active" : "amount"} onClick={() => setAmount(5)}>
              x5
            </button>
            <button className={amount == 10 ? "amount amount-active" : "amount"} onClick={() => setAmount(10)}>
              x10
            </button>
            <button className={amount == 20 ? "amount amount-active" : "amount"} onClick={() => setAmount(20)}>
              x20
            </button>
            <button className={amount == 50 ? "amount amount-active" : "amount"} onClick={() => setAmount(50)}>
              x50
            </button>
            <button className={amount == 100 ? "amount amount-active" : "amount"} onClick={() => setAmount(100)}>
              x100
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomDrawer = () => {
    return (
      <div className="bottom" style={{ height: 62 }}>
        <button className="btnCancel" onClick={() => closeDrawer()}>
          HUỶ BỎ
        </button>

        {!loading ? (
          <button className="btnSubmit" onClick={handleOrder}>
            ĐẶT CƯỢC {total.toLocaleString()} <img src={quanhuy} className="quan-huy-lq" />
          </button>
        ) : (
          <button className="btnSubmit" style={{ padding: 0, height: 62 }}>
            <Spin />
          </button>
        )}
      </div>
    );
  };

  const order = async (payload) => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/lotery/orderAov", payload);
      showToast("success", response.data.message);
      dispatch({
        type: "USER_ORDER",
      });
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    } finally {
      setOpen(false);
      getProfile();

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_BALANCE",
        payload: response.data.data.balance,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      userInLocalStorage.balance = response.data.data.balance;
      localStorage.setItem("user", JSON.stringify(userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrder = () => {
    let API_type;

    if (record.data == "green" || record.data == "red" || record.data == "violet") {
      API_type = 1;
    } else if (record.data == "big" || record.data == "small") {
      API_type = 3;
    } else {
      API_type = 2;
    }
    // API_type theo yêu cầu của backend

    order({
      time: timeMode,
      amount: amount,
      balance: money,
      order: record.data,
      type: API_type,
      api: "orderAOV",
    });
  };

  useEffect(() => {
    setTotal(money * amount);
  }, [money, amount]);

  useEffect(() => {
    if (time !== null && time <= 4) {
      setOpen(false);
    }
  }, [time]);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div className="aov-main-game">
        <div className="type-number">
          <button className="num0" onClick={() => showDrawer("", nhanvat0, "0")}>
            <img src={nhanvat0} />
          </button>
          <button className="num1" onClick={() => showDrawer("", nhanvat1, "1")}>
            <img src={nhanvat1} />
          </button>
          <button className="num2" onClick={() => showDrawer("", nhanvat2, "2")}>
            <img src={nhanvat2} />
          </button>
          <button className="num3" onClick={() => showDrawer("", nhanvat3, "3")}>
            <img src={nhanvat3} />
          </button>
          <button className="num4" onClick={() => showDrawer("", nhanvat4, "4")}>
            <img src={nhanvat4} />
          </button>
          <button className="num5" onClick={() => showDrawer("", nhanvat5, "5")}>
            <img src={nhanvat5} />
          </button>
          <button className="num6" onClick={() => showDrawer("", nhanvat6, "6")}>
            <img src={nhanvat6} />
          </button>
          <button className="num7" onClick={() => showDrawer("", nhanvat7, "7")}>
            <img src={nhanvat7} />
          </button>
          <button className="num8" onClick={() => showDrawer("", nhanvat8, "8")}>
            <img src={nhanvat8} />
          </button>
          <button className="num9" onClick={() => showDrawer("", nhanvat9, "9")}>
            <img src={nhanvat9} />
          </button>
        </div>

        <div className="type-boolean">
          {/* Cái này đổi lại sp bên trái ad đổi lại bên phải - Sp là lớn ad là nhỏ */}
          <button onClick={() => showDrawer("", imgSPP, "big")}>SP</button>
          <button onClick={() => showDrawer("", imgADD, "small")}>AD</button>
        </div>

        {time !== null && time <= 4 ? (
          <div className="overlay">
            <span className="second-digit">0</span>
            <span className="second-digit">{time}</span>
          </div>
        ) : null}
      </div>

      <Drawer placement="bottom" onClose={closeDrawer} open={open} closable={false} className="aov-mainteskol-drawer">
        <div className="my-drawer-content">
          {renderTopDrawer()}
          {renderCenterDrawer()}
          {renderBottomDrawer()}
        </div>
      </Drawer>
    </>
  );
}
