import { Descriptions, Modal, Pagination, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function HistoryOrder() {
  const [array, setArray] = useState([]);
  const { timeMode, userOrderTemp, time } = useSelector((root) => root.gameReducer);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page,
    });
  };
  // end pagination

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [record, setRecord] = useState({});

  const showModal = (record) => {
    setRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // end modal

  const getOrderHistory = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/historyOrder", payload);
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  useEffect(() => {
    // khi user order, userOrderTemp sẽ tăng lên 1, dựa vào signal đó load lại dữ liệu
    setCurrent(1);
    getOrderHistory({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [userOrderTemp]);

  useEffect(() => {
    // bắt đầu game mới, load lại API để show kết quả game cũ nếu có
    if (
      (timeMode === 1 && time === 59) ||
      (timeMode === 3 && time === 179) ||
      (timeMode === 5 && time === 299) ||
      (timeMode === 10 && time === 599)
    ) {
      setCurrent(1);
      getOrderHistory({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time]);

  const renderOrder = (order) => {
    if (order == "big") {
      return <span className="type type-big">Lớn</span>;
    } else if (order == "small") {
      return <span className="type type-small">Nhỏ</span>;
    } else if (order == "0") {
      return <span className="type type-0">0</span>;
    } else if (order == "5") {
      return <span className="type type-5">5</span>;
    } else if (order == "1") {
      return <span className="type type-1379">1</span>;
    } else if (order == "3") {
      return <span className="type type-1379">3</span>;
    } else if (order == "7") {
      return <span className="type type-1379">7</span>;
    } else if (order == "9") {
      return <span className="type type-1379">9</span>;
    } else if (order == "2") {
      return <span className="type type-2468">2</span>;
    } else if (order == "4") {
      return <span className="type type-2468">4</span>;
    } else if (order == "6") {
      return <span className="type type-2468">6</span>;
    } else if (order == "8") {
      return <span className="type type-2468">8</span>;
    } else if (order == "green") {
      return <span className="type type-green">Xanh</span>;
    } else if (order == "violet") {
      return <span className="type type-purple">Tím</span>;
    } else if (order == "red") {
      return <span className="type type-red">Đỏ</span>;
    }
  };

  const renderResult = (num) => {
    switch (num) {
      case "0":
        return (
          <>
            <span className="type type-0">0</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
            <span className="type type-purple">Tím</span>
          </>
        );
      case "1":
        return (
          <>
            <span className="type type-1379">1</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "2":
        return (
          <>
            <span className="type type-2468">2</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "3":
        return (
          <>
            <span className="type type-1379">3</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "4":
        return (
          <>
            <span className="type type-2468">4</span>
            <span className="type type-small">Nhỏ</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "5":
        return (
          <>
            <span className="type type-5">5</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
            <span className="type type-purple">Tím</span>
          </>
        );
      case "6":
        return (
          <>
            <span className="type type-2468">6</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "7":
        return (
          <>
            <span className="type type-1379">7</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      case "8":
        return (
          <>
            <span className="type type-2468">8</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-red">Đỏ</span>
          </>
        );
      case "9":
        return (
          <>
            <span className="type type-1379">9</span>
            <span className="type type-big">Lớn</span>
            <span className="type type-green">Xanh</span>
          </>
        );
      default:
        break;
    }
  };

  const columns = [
    {
      title: "Kỳ xổ",
      key: "kyxo",
      width: "35%",
      render: (_, record) => {
        return (
          <>
            <span style={{ fontSize: 12 }}>
              <strong>{record.idChartLottery}</strong>
            </span>
            <br />
            <span style={{ fontSize: 12 }}>{record.created_at}</span>
          </>
        );
      },
    },
    {
      title: "Cược",
      key: "cuoc",
      width: "20%",
      render: (_, { order }) => {
        return renderOrder(order);
      },
    },
    {
      title: "Kết quả",
      key: "ketqua",
      width: "35%",
      render: (_, record) => {
        if (record.status == "PENDING") {
          return <span>PENDING</span>;
        } else if (record.status == "SUCCESS") {
          if (record.profit != 0) {
            return (
              <>
                <span className="result-win">THẮNG</span> <br />
                <span className="result-win-money">+{Math.round(Number(record.profit)).toLocaleString()}</span>
              </>
            );
          } else if (record.profit == 0) {
            return (
              <>
                <span className="result-lose">THUA</span> <br />
                <span className="result-lose-money">
                  -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "",
      key: "chitiet",
      width: "10%",
      render: (_, record) => (
        <Tooltip title={"Chi tiết"}>
          <i
            className="fa-solid fa-circle-right"
            style={{ color: "grey", fontSize: 18, cursor: "pointer" }}
            onClick={() => showModal(record)}
          ></i>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="history-order">
      <Table columns={columns} dataSource={array} rowKey={(record) => record.id} pagination={false} size="small" />

      <div className="pagination">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>

      <Modal
        closable={false}
        footer={null}
        centered
        width={window.innerWidth <= 576 ? "100%" : 360}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="result-modal"
      >
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label="ID">{record?.id}</Descriptions.Item>
          <Descriptions.Item label="Kỳ xổ">{record?.idChartLottery}</Descriptions.Item>
          <Descriptions.Item label="Số lượng mua">{record?.amount}</Descriptions.Item>
          <Descriptions.Item label="Số tiền mua">
            {Number(record?.balance).toLocaleString()} <span>đ</span>
          </Descriptions.Item>
          <Descriptions.Item label="Thuế">
            {Math.round(Number(Number(record?.amount) * Number(record?.balance) * (1 - record?.fee))).toLocaleString()}{" "}
            <span>đ</span>
          </Descriptions.Item>
          <Descriptions.Item label="Số tiền sau thuế">
            {Math.round(Number(Number(record?.amount) * Number(record?.balance) * record?.fee)).toLocaleString()}{" "}
            <span>đ</span>
          </Descriptions.Item>
          <Descriptions.Item label="Đặt cược">{renderOrder(record?.order)}</Descriptions.Item>
          {/* <Descriptions.Item label="Kết quả">-</Descriptions.Item> */}
          <Descriptions.Item label="Trạng thái">
            {record.profit == null ? <span>PENDING</span> : null}
            {record.profit != null && record.profit != 0 ? (
              <span style={{ color: "green", fontWeight: "bold" }}>THẮNG</span>
            ) : null}
            {record.profit != null && record.profit == 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>THUA</span>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Số tiền">
            {record.profit == null ? <span>PENDING</span> : null}
            {record.profit != null && record.profit != 0 ? (
              <span style={{ color: "green", fontWeight: "bold" }}>
                +{Math.round(Number(record.profit)).toLocaleString()} <span>đ</span>
              </span>
            ) : null}
            {record.profit != null && record.profit == 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()} <span>đ</span>
              </span>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Thời gian">{record.created_at}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
}
