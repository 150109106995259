import { Tag, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ROWS } from "../../constant/constant.js";
import { axiosService } from "../../util/service";
import Header from "../Home/Header";

export default function LichSuCayThue() {
  const [data, setData] = useState([]);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const getHistory = async (limit, page) => {
    try {
      let response = await axiosService.post("api/plowing/getHistoryPlowing", { limit, page });
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getHistory(ROWS, 1);
  }, []);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getHistory(ROWS, page);
  };

  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  return (
    <div className="lichsucaythue-page">
      <div className="lichsucaythue">
        <Header />

        <div className="container">
          <div className="caythue-title">LỊCH SỬ CÀY THUÊ</div>

          <div className="caythue-form">
            <div className="pagination-lsct">
              <Pagination
                defaultCurrent={1}
                current={current}
                total={totalRecord}
                showSizeChanger={false}
                showQuickJumper={false}
                size="small"
                showLessItems
                className="pagination-box"
                onChange={onPaginationChange}
              />
            </div>

            <div className="list">
              {data.map((item, index) => {
                return (
                  <div className="list-item" key={index}>
                    <div className="rank">
                      {item.rankBegin} <i className="fa-solid fa-circle-arrow-right"></i>
                      {item.rankFinish}
                    </div>

                    <div className="status">
                      {item.type == 2 && (
                        <Tag color="grey">
                          <b>CHƯA HOÀN THÀNH</b>
                        </Tag>
                      )}
                      {item.type == 1 && (
                        <Tag color="green">
                          <b>HOÀN THÀNH</b>
                        </Tag>
                      )}
                    </div>

                    <div className="option">
                      {item.typeChampion == 1 && (
                        <span className="option-item">
                          <i className="fa-solid fa-clipboard-check"></i>
                          Chọn tướng
                        </span>
                      )}
                      {item.typeSpeed == 1 && (
                        <span className="option-item">
                          <i className="fa-solid fa-clipboard-check"></i>
                          Cày siêu tốc
                        </span>
                      )}
                    </div>

                    <div className="detail">
                      <span className="amount">{Math.round(Number(item.balance)).toLocaleString()}</span>
                      <span className="time">{item.created_at}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="pagination-lsct2">
              <Pagination
                defaultCurrent={1}
                current={current}
                total={totalRecord}
                showSizeChanger={false}
                showQuickJumper={false}
                size="small"
                showLessItems
                className="pagination-box"
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
