import React, { useState } from "react";
import Wallet from "../GamePlay/Wallet";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import DepositChuyenKhoan from "./DepositChuyenKhoan";

export default function Deposit() {
  const [method, setMethod] = useState(1); // 1 chuyển khoản 2 thẻ cào

  return (
    <div className="deposit-page">
      <div className="deposit">
        <Header />

        <div className="container">
          <Wallet noButton={true} />

          <div className="deposit-method-choice">
            <h3 className="title">
              <i className="fa-solid fa-wallet"></i>
              <span>Phương thức thanh toán</span>
            </h3>

            <div className="list">
              <div onClick={() => setMethod(1)} className={`item ${method == 1 ? "active" : ""}`}>
                <i className="fa-solid fa-money-bill-transfer"></i>
                <span>Chuyển khoản</span>
              </div>

              <div className="item disabled">
                <i className="fa-regular fa-credit-card"></i>
                <span>Thẻ cào</span>
              </div>
            </div>
          </div>

          {method == 1 && <DepositChuyenKhoan />}

          {method == 2 && <span>...</span>}
        </div>

        <Footer />
      </div>
    </div>
  );
}
