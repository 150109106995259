import { Table } from "antd";
import React from "react";

export default function Table2() {
  const cachTinhHoaHong = [
    {
      id: 0,
      capBac: "Thành viên cấp 0",
      bac1: 1.2,
      bac2: 0.36,
      bac3: 0.108,
      bac4: 0.0324,
    },
    {
      id: 1,
      capBac: "Thành viên cấp 1",
      bac1: 1.4,
      bac2: 0.42,
      bac3: 0.126,
      bac4: 0.0378,
    },
    {
      id: 2,
      capBac: "Thành viên cấp 2",
      bac1: 1.5,
      bac2: 0.45,
      bac3: 0.135,
      bac4: 0.0405,
    },
    {
      id: 3,
      capBac: "Thành viên cấp 3",
      bac1: 1.6,
      bac2: 0.48,
      bac3: 0.144,
      bac4: 0.0432,
    },
    {
      id: 4,
      capBac: "Thành viên cấp 4",
      bac1: 1.7,
      bac2: 0.51,
      bac3: 0.153,
      bac4: 0.0459,
    },
    {
      id: 5,
      capBac: "Thành viên cấp 5",
      bac1: 1.8,
      bac2: 0.54,
      bac3: 0.162,
      bac4: 0.0486,
    },
    {
      id: 6,
      capBac: "Thành viên cấp 6",
      bac1: 2,
      bac2: 0.6,
      bac3: 0.18,
      bac4: 0.054,
    },
  ];

  const columns = [
    {
      title: (
        <div>
          Cấp bậc
          <br />
          <span style={{ fontWeight: "normal" }}>Tỷ lệ hoàn trả</span>
        </div>
      ),
      key: "cc1",
      dataIndex: "capBac",
      width: 200,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: (
        <div>
          Bậc 1
          <br />
          <span style={{ fontWeight: "normal" }}>(%)</span>
        </div>
      ),
      key: "cc2",
      dataIndex: "bac1",
      width: 90,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num}</span>;
      },
    },
    {
      title: (
        <div>
          Bậc 2
          <br />
          <span style={{ fontWeight: "normal" }}>(%)</span>
        </div>
      ),
      key: "cc3",
      dataIndex: "bac2",
      width: 90,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num}</span>;
      },
    },
    {
      title: (
        <div>
          Bậc 3
          <br />
          <span style={{ fontWeight: "normal" }}>(%)</span>
        </div>
      ),
      key: "cc4",
      dataIndex: "bac3",
      width: 90,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num}</span>;
      },
    },
    {
      title: (
        <div>
          Bậc 4
          <br />
          <span style={{ fontWeight: "normal" }}>(%)</span>
        </div>
      ),
      key: "cc5",
      dataIndex: "bac4",
      width: 90,
      render: (num) => {
        return <span style={{ fontSize: 12 }}>{num}</span>;
      },
    },
  ];

  return (
    <div className="table2-component">
      <div className="title">CÁCH TÍNH HOA HỒNG (XỔ SỐ)</div>

      <Table
        size="small"
        columns={columns}
        dataSource={cachTinhHoaHong}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </div>
  );
}
