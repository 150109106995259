import { Pagination, Tag } from "antd";
import React, { useEffect, useState } from "react";
import folder from "../../assets/image/folder.png";
import { ROWS } from "../../constant/constant";
import { renderBankImg } from "../../function/renderBankImg";
import { axiosService } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function WithdrawHistory() {
  const [data, setData] = useState([]);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const getData = async (limit, page) => {
    try {
      let response = await axiosService.post("api/depositVND/getHistoryWidthdraw", {
        limit,
        page,
      });
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getData(ROWS, page);
  };

  useEffect(() => {
    setCurrent(1);
    getData(ROWS, 1);
  }, []);

  const renderStatus = ({ type }) => {
    if (type == 1) {
      // rút tiền thành công
      return (
        <div className="first-row">
          <Tag color="green">
            <strong>RÚT TIỀN THÀNH CÔNG</strong>
          </Tag>
        </div>
      );
    } else if (type == 2) {
      // chờ admin duyệt
      return (
        <div className="first-row">
          <Tag color="blue">
            <strong>ĐANG CHỜ XÉT DUYỆT</strong>
          </Tag>
        </div>
      );
    } else if (type == 0) {
      // admin từ chối
      return (
        <div className="first-row">
          <Tag color="red">
            <strong>GIAO DỊCH BỊ TỪ CHỐI</strong>
          </Tag>
        </div>
      );
    }
  };

  return (
    <div className="withdraw-history-page">
      <div className="withdraw-history">
        <Header />

        <div className="container">
          <div className="title-area">
            <h2 className="title">LỊCH SỬ RÚT TIỀN</h2>

            <Pagination
              defaultCurrent={1}
              total={totalRecord}
              current={current}
              onChange={onPaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
              showLessItems={true}
              className="pagination-box"
              size="small"
              hideOnSinglePage
            />
          </div>

          {data.length != 0 && (
            <div className="history-list">
              {data.map((item, index) => (
                <div className="item-history" key={index}>
                  {renderStatus(item)}

                  <div className="second-row">
                    {renderBankImg(item.nameBanking, 1)}
                    <span>
                      Ngân hàng: {item.nameBanking}
                      <br />
                      STK: {item.numberBanking} ({item.ownerBanking})
                    </span>
                  </div>

                  <div className="third-row">{Number(item.amount).toLocaleString()} VNĐ</div>

                  <div className="fourth-row">{item.created_at}</div>
                </div>
              ))}
            </div>
          )}

          {data.length == 0 && (
            <div className="nodata">
              <img src={folder} alt="No data" />
              <span>Không có lịch sử giao dịch</span>
            </div>
          )}

          <div className="pagination-bottom">
            <Pagination
              defaultCurrent={1}
              total={totalRecord}
              current={current}
              onChange={onPaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
              showLessItems={true}
              className="pagination-box"
              size="small"
              hideOnSinglePage
            />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
