import React from "react";
import { useHistory } from "react-router-dom";

export default function AccMenu() {
  const history = useHistory();

  return (
    <div className="account-menu">
      <div className="item" onClick={() => history.push("/change-password")}>
        <div className="left">
          <i className="fa-solid fa-unlock-keyhole"></i>
          <span>Đổi mật khẩu</span>
        </div>
        <div className="right">
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>

      <div className="item" onClick={() => history.push("/deposit-history")}>
        <div className="left">
          <i className="fa-solid fa-wallet"></i>
          <span>Lịch sử nạp tiền</span>
        </div>
        <div className="right">
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>

      <div className="item" onClick={() => history.push("/withdraw-history")}>
        <div className="left">
          <i className="fa-solid fa-money-check-dollar"></i>
          <span>Lịch sử rút tiền</span>
        </div>
        <div className="right">
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>

      <div className="item" onClick={() => history.push("/guide")}>
        <div className="left">
          <i className="fa-solid fa-circle-question"></i>
          <span>Hướng dẫn sử dụng</span>
        </div>
        <div className="right">
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>

      <div className="item" onClick={() => history.push("/help-center")}>
        <div className="left">
          <i className="fa-solid fa-headphones-simple"></i>
          <span>CSKH trực tuyến 24/7</span>
        </div>
        <div className="right">
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>
    </div>
  );
}
