import { Button, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function History() {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getHistory({
      limit: ROWS,
      page: page,
    });
  };

  const getHistory = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/historyCommissionTransfer", payload);
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeeDetail = (timeStart, timeEnd) => {
    // chuyển qua tab thống kê hoa hồng (tab 2), có kèm data
    dispatch({
      type: "CHANGE_PROMO_MENU_FROM_TAB3_TO_TAB2",
      payload: {
        timeStart,
        timeEnd,
      },
    });
  };

  useEffect(() => {
    setCurrent(1);
    getHistory({
      limit: ROWS,
      page: 1,
    });
  }, []);

  const columns = [
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: (
        <span>
          Số tiền
          <br />
          (VNĐ)
        </span>
      ),
      key: "Số tiền",
      dataIndex: "balance",
      width: 150,
      render: (e) => {
        return <span>{Math.round(Number(e)).toLocaleString()}</span>;
      },
    },
    {
      title: "Chi tiết",
      key: "Chi tiết",
      width: 100,
      render: (_, { timeStart, timeEnd }) => {
        return (
          <Button size="small" onClick={() => handleSeeDetail(timeStart, timeEnd)}>
            Chi tiết
          </Button>
        );
      },
    },
  ];

  return (
    <div className="promo-history">
      <div className="pagination-jk">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          hideOnSinglePage
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>

      <div className="table">
        <Table size="small" columns={columns} dataSource={data} rowKey={(record) => record.id} pagination={false} />
      </div>
    </div>
  );
}
