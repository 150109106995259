import { Button, Drawer, InputNumber, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function Main() {
  const { timeMode, time } = useSelector((root) => root.gameReducer);

  // loading when order
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState({
    color: "", // for CSS
    content: "", // for displaying to user
    data: "", // for calling API
  });
  const [money, setMoney] = useState(1000);
  const [amount, setAmount] = useState(1);
  const [total, setTotal] = useState(1000);

  const dispatch = useDispatch();

  const showDrawer = (color, content, data) => {
    setOpen(true);
    setRecord({
      color,
      content,
      data,
    });
    setMoney(1000);
    setAmount(1);
    setTotal(1000);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const renderTopDrawer = () => {
    let className;

    if (record.color == "green") className = "top top-green";
    else if (record.color == "purple") className = "top top-purple";
    else if (record.color == "red") className = "top top-red";
    else if (record.color == "orange") className = "top top-orange";
    else if (record.color == "blue") className = "top top-blue";
    else if (record.color == "red-purple") className = "top top-red-purple";
    else if (record.color == "green-purple") className = "top top-green-purple";

    return (
      <div className={className}>
        <div>{timeMode} phút</div>
        <div>
          <span>Bạn chọn </span>
          <span>{record.content}</span>
        </div>
      </div>
    );
  };

  const handleCong = () => {
    // cộng thì vô tư
    setAmount((amount) => amount + 1);
  };
  const handleTru = () => {
    // trừ thì không cho trừ xuống dưới 1
    if (amount <= 1) {
      return;
    }
    setAmount((amount) => amount - 1);
  };

  const renderCenterDrawer = () => {
    let classNameActive;
    let className2Active;

    if (record.color == "green") {
      classNameActive = "money money-active-green";
      className2Active = "amount amount-active-green";
    } else if (record.color == "purple") {
      classNameActive = "money money-active-purple";
      className2Active = "amount amount-active-purple";
    } else if (record.color == "red") {
      classNameActive = "money money-active-red";
      className2Active = "amount amount-active-red";
    } else if (record.color == "orange") {
      classNameActive = "money money-active-orange";
      className2Active = "amount amount-active-orange";
    } else if (record.color == "blue") {
      classNameActive = "money money-active-blue";
      className2Active = "amount amount-active-blue";
    } else if (record.color == "red-purple") {
      classNameActive = "money money-active-red";
      className2Active = "amount amount-active-red";
    } else if (record.color == "green-purple") {
      classNameActive = "money money-active-green";
      className2Active = "amount amount-active-green";
    }

    return (
      <div className="center">
        <div className="field">
          <span>Số tiền</span>
          <div>
            <button className={money == 1000 ? classNameActive : "money"} onClick={() => setMoney(1000)}>
              1,000
            </button>
            <button className={money == 10000 ? classNameActive : "money"} onClick={() => setMoney(10000)}>
              10,000
            </button>
            <button className={money == 100000 ? classNameActive : "money"} onClick={() => setMoney(100000)}>
              100,000
            </button>
            <button className={money == 1000000 ? classNameActive : "money"} onClick={() => setMoney(1000000)}>
              1,000,000
            </button>
          </div>
        </div>

        <div className="field">
          <span>Số lượng</span>

          <div className="new-amount-zone">
            <Button shape="circle" style={{ marginRight: 10 }} onClick={() => handleTru()} disabled={amount <= 1}>
              –
            </Button>
            <InputNumber
              min={1}
              style={{ width: 90 }}
              value={amount}
              onChange={(value) => setAmount(value)}
              controls={false}
              className="input-number-in-open-drawer"
            />
            <Button shape="circle" style={{ marginLeft: 10 }} onClick={() => handleCong()}>
              +
            </Button>
          </div>
        </div>

        <div className="field">
          <span></span>
          <div>
            <button className={amount == 1 ? className2Active : "amount"} onClick={() => setAmount(1)}>
              x1
            </button>
            <button className={amount == 5 ? className2Active : "amount"} onClick={() => setAmount(5)}>
              x5
            </button>
            <button className={amount == 10 ? className2Active : "amount"} onClick={() => setAmount(10)}>
              x10
            </button>
            <button className={amount == 20 ? className2Active : "amount"} onClick={() => setAmount(20)}>
              x20
            </button>
            <button className={amount == 50 ? className2Active : "amount"} onClick={() => setAmount(50)}>
              x50
            </button>
            <button className={amount == 100 ? className2Active : "amount"} onClick={() => setAmount(100)}>
              x100
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomDrawer = () => {
    let className;

    if (record.color == "green") className = "btnSubmit btnSubmit-green";
    else if (record.color == "purple") className = "btnSubmit btnSubmit-purple";
    else if (record.color == "red") className = "btnSubmit btnSubmit-red";
    else if (record.color == "orange") className = "btnSubmit btnSubmit-orange";
    else if (record.color == "blue") className = "btnSubmit btnSubmit-blue";
    else if (record.color == "red-purple") className = "btnSubmit btnSubmit-red";
    else if (record.color == "green-purple") className = "btnSubmit btnSubmit-green";

    return (
      <div className="bottom" style={{ height: 62 }}>
        <button className="btnCancel" onClick={() => closeDrawer()}>
          HUỶ BỎ
        </button>

        {!loading ? (
          <button className={className} onClick={handleOrder}>
            ĐẶT CƯỢC {total.toLocaleString()} <span>đ</span>
          </button>
        ) : (
          <button className={className} style={{ padding: 0, height: 62 }}>
            <Spin />
          </button>
        )}
      </div>
    );
  };

  const order = async (payload) => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/lotery/order", payload);
      showToast("success", response.data.message);
      dispatch({
        type: "USER_ORDER",
      });
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    } finally {
      setOpen(false);
      getProfile();

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const getProfile = async () => {
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_BALANCE",
        payload: response.data.data.balance,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      userInLocalStorage.balance = response.data.data.balance;
      localStorage.setItem("user", JSON.stringify(userInLocalStorage));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrder = () => {
    let API_type;

    if (record.data == "green" || record.data == "red" || record.data == "violet") {
      API_type = 1;
    } else if (record.data == "big" || record.data == "small") {
      API_type = 3;
    } else {
      API_type = 2;
    }
    // API_type theo yêu cầu của backend

    order({
      time: timeMode,
      amount: amount,
      balance: money,
      order: record.data,
      type: API_type,
      api: "order",
    });
  };

  useEffect(() => {
    setTotal(money * amount);
  }, [money, amount]);

  useEffect(() => {
    if (time !== null && time <= 4) {
      setOpen(false);
    }
  }, [time]);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div className="main-game">
        <div className="type-color">
          <button className="color-green" onClick={() => showDrawer("green", "Xanh", "green")}>
            Xanh
          </button>
          <button className="color-purple" onClick={() => showDrawer("purple", "Tím", "violet")}>
            Tím
          </button>
          <button className="color-red" onClick={() => showDrawer("red", "Đỏ", "red")}>
            Đỏ
          </button>
        </div>

        <div className="type-number">
          <button className="num0 red-purple" onClick={() => showDrawer("red-purple", "0", "0")}>
            0
          </button>
          <button className="num1 green" onClick={() => showDrawer("green", "1", "1")}>
            1
          </button>
          <button className="num2 red" onClick={() => showDrawer("red", "2", "2")}>
            2
          </button>
          <button className="num3 green" onClick={() => showDrawer("green", "3", "3")}>
            3
          </button>
          <button className="num4 red" onClick={() => showDrawer("red", "4", "4")}>
            4
          </button>
          <button className="num5 green-purple" onClick={() => showDrawer("green-purple", "5", "5")}>
            5
          </button>
          <button className="num6 red" onClick={() => showDrawer("red", "6", "6")}>
            6
          </button>
          <button className="num7 green" onClick={() => showDrawer("green", "7", "7")}>
            7
          </button>
          <button className="num8 red" onClick={() => showDrawer("red", "8", "8")}>
            8
          </button>
          <button className="num9 green" onClick={() => showDrawer("green", "9", "9")}>
            9
          </button>
        </div>

        <div className="type-boolean">
          <button className="big" onClick={() => showDrawer("orange", "Lớn", "big")}>
            Lớn
          </button>
          <button className="small" onClick={() => showDrawer("blue", "Nhỏ", "small")}>
            Nhỏ
          </button>
        </div>

        {time !== null && time <= 4 ? (
          <div className="overlay">
            <span className="second-digit">0</span>
            <span className="second-digit">{time}</span>
          </div>
        ) : null}
      </div>

      <Drawer placement="bottom" onClose={closeDrawer} open={open} closable={false} className="mainteskol-drawer">
        <div className="my-drawer-content">
          {renderTopDrawer()}
          {renderCenterDrawer()}
          {renderBottomDrawer()}
        </div>
      </Drawer>
    </>
  );
}
