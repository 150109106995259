import { Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import iconWallet from "../../assets/image/icon_wallet.webp";
import { axiosService } from "../../util/service";

export default function Wallet({ noButton }) {
  const { user } = useSelector((root) => root.userReducer);
  const { time, timeMode } = useSelector((root) => root.gameReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // bắt đầu game mới, load kết quả game cũ
    if (timeMode === 1 && time === 59) {
      getProfile();
    }
  }, [time]);

  useEffect(() => {
    getProfile();
  }, []);

  // getProfile just to update balance
  const getProfile = async () => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/user/getProfile");
      // update in redux
      dispatch({
        type: "UPDATE_BALANCE",
        payload: response.data.data.balance,
      });
      // update in localStorage
      const userInLocalStorage = JSON.parse(localStorage.getItem("user"));
      userInLocalStorage.balance = response.data.data.balance;
      localStorage.setItem("user", JSON.stringify(userInLocalStorage));
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const userBalance = Math.round(user?.balance);

  return (
    <div className="wallet">
      <div className="top">
        <div className="left">
          <img src={iconWallet} />
          <span>Số dư</span>
        </div>
        <div className="right">
          {loading ? (
            <Spin />
          ) : (
            <span>
              {userBalance.toLocaleString()} <span>đ</span>
            </span>
          )}
          <Tooltip title="Cập nhật số dư">
            <i className="fa-solid fa-arrows-rotate" onClick={() => getProfile()}></i>
          </Tooltip>
        </div>
      </div>

      {!noButton && (
        <div className="bottom">
          <button className="rut" onClick={() => history.push("/withdraw")}>
            Rút tiền
          </button>
          <button className="nap" onClick={() => history.push("/deposit")}>
            Nạp tiền
          </button>
        </div>
      )}
    </div>
  );
}
