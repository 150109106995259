const defaultState = {
  timeMode: 1,
  time: null,
  userOrderTemp: 0,
  currentGame: {
    timeMode: undefined,
    id: undefined,
  }, // kỳ sổ hiện tại
};

export const gameReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "CHANGE_TIME_MODE": {
      return {
        ...state,
        timeMode: action.payload,
        time: null, // reset time mỗi lần change timeMode
      };
    }
    case "TIME_FROM_SOCKET": {
      return {
        ...state,
        time: action.payload,
      };
    }
    case "USER_ORDER": {
      // khi user order, userOrderTemp tăng lên 1, bên component HistoryOrder listen change để load lại data để hiển thị lịch sử
      return {
        ...state,
        userOrderTemp: state.userOrderTemp + 1,
      };
    }
    case "SET_CURRENT_GAME": {
      return {
        ...state,
        currentGame: {
          timeMode: action.payload.time,
          id: action.payload.id,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};
