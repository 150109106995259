import React from "react";
import Wallet from "../AOVGamePlay/Wallet";
import HeaderAOV from "../AOVGamePlay/HeaderAOV";
import Marquee from "../AOVGamePlay/Marquee";
import Info from "../AOVGamePlay/Info";
import Time from "../AOVGamePlay/Time";
import Main from "../AOVGamePlay/Main";
import History from "../AOVGamePlay/History";
import { Redirect } from "react-router-dom";

export default function AOVGamePlay() {
  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return (
    <div className="aov-gameplay">
      <div className="aov">
        <HeaderAOV />

        <div className="container">
          <Wallet />
          <Marquee />
          <Time />
          <Info />
          <Main />
          <History />
        </div>
      </div>
    </div>
  );
}
