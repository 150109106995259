import { Button, Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function ChangePassword() {
  const [state, setState] = useState({
    oldpw: "",
    newpw1: "",
    newpw2: "",
  });

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = () => {
    if (!state.oldpw) {
      showAlert("error", "Hãy nhập mật khẩu hiện tại");
      return;
    }

    if (!state.newpw1) {
      showAlert("error", "Hãy nhập mật khẩu mới");
      return;
    }

    if (!state.newpw2) {
      showAlert("error", "Hãy xác nhận mật khẩu mới");
      return;
    }

    if (state.newpw2 != state.newpw1) {
      showAlert("error", "Mật khẩu không khớp");
      return;
    }

    changePassword({
      password: state.oldpw,
      newPassword: state.newpw2,
    });
  };

  const changePassword = async (payload) => {
    setLoading(true);

    try {
      let response = await axiosService.post("api/user/changePassword", payload);
      showAlert("success", response.data.message);
      history.replace("/account");
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="changepw-page">
      <div className="changepw">
        <Header />

        <div className="container">
          <div className="title-area">
            <h2 className="title">Thay đổi mật khẩu</h2>
          </div>

          <form>
            <div className="field">
              <label htmlFor="oldpw">Mật khẩu hiện tại</label>
              <Input.Password
                id="oldpw"
                size="large"
                value={state.oldpw}
                onChange={(e) => setState({ ...state, oldpw: e.target.value })}
              ></Input.Password>
            </div>

            <div className="field">
              <label htmlFor="newpw1">Mật khẩu mới</label>
              <Input.Password
                id="newpw1"
                size="large"
                value={state.newpw1}
                onChange={(e) => setState({ ...state, newpw1: e.target.value })}
              ></Input.Password>
            </div>

            <div className="field">
              <label htmlFor="newpw2">Xác nhận mật khẩu mới</label>
              <Input.Password
                id="newpw2"
                size="large"
                value={state.newpw2}
                onChange={(e) => setState({ ...state, newpw2: e.target.value })}
              ></Input.Password>
            </div>

            <div className="btntbtn">
              <Button
                size="large"
                type="primary"
                style={{ width: 180 }}
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Đổi mật khẩu
              </Button>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </div>
  );
}
