export const calcTime = (totalSecond) => {
  // 590 seconds --> 9 mins 50s
  const min = Math.floor(totalSecond / 60); // 9
  const sec = totalSecond - min * 60; // 50

  // split 'min' into 2 digits
  let firstDigitMin, secondDigitMin;
  if (min >= 10) {
    const temp = min.toString().split("");
    firstDigitMin = temp[0];
    secondDigitMin = temp[1];
  } else {
    firstDigitMin = "0";
    secondDigitMin = min.toString();
  }

  // split 'sec' into 2 digits
  let firstDigitSec, secondDigitSec;
  if (sec >= 10) {
    const temp = sec.toString().split("");
    firstDigitSec = temp[0];
    secondDigitSec = temp[1];
  } else {
    firstDigitSec = "0";
    secondDigitSec = sec.toString();
  }

  return [firstDigitMin, secondDigitMin, firstDigitSec, secondDigitSec];
};
