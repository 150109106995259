import { Select, Spin } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import mainImg from "../../assets/image/aov/IMG_0904.png";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service";
import Header from "../Home/Header";

export default function CayThueLienQuan() {
  const history = useHistory();

  const [dataPlowing, setDataPlowing] = useState([]); // show rank hiện tại
  const [dataPlowing2, setDataPlowing2] = useState([]); // show rank mong muốn
  const [amountToPay, setAmountToPay] = useState(0);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rankHienTai: dataPlowing[0]?.id,
      rankMongMuon: undefined,
      optionChonTuong: false,
      optionCaySieuToc: false,
      taiKhoanCanCay: "",
      password: "",
    },
    validationSchema: Yup.object({
      taiKhoanCanCay: Yup.string().required("Bắt buộc"),
      password: Yup.string().required("Bắt buộc"),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (!values.rankHienTai || !values.rankMongMuon) {
        showAlert("error", "Vui lòng chọn rank");
        return;
      }

      buyPlowing({
        idBegin: values.rankHienTai,
        idFinish: values.rankMongMuon,
        typeChampion: values.optionChonTuong ? 1 : 0,
        typeSpeed: values.optionCaySieuToc ? 1 : 0,
        account: values.taiKhoanCanCay,
        password: values.password,
      });
    },
  });

  const getAllPlowing = async () => {
    try {
      let response = await axiosService.post("api/plowing/getAllPlowing");
      setDataPlowing(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const buyPlowing = async (payload) => {
    setLoading(true);
    try {
      let response = await axiosService.post("api/plowing/buyPlowing", payload);
      showAlert("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlowing();
  }, []);

  useEffect(() => {
    const rankHienTai = formik.values.rankHienTai;
    if (rankHienTai) {
      const temp = dataPlowing.filter((item) => item.id > Number(rankHienTai));
      setDataPlowing2(temp);
    }
  }, [formik.values.rankHienTai]);

  useEffect(() => {
    const rank1 = Number(formik.values.rankHienTai);
    const rank2 = Number(formik.values.rankMongMuon);

    const option1 = formik.values.optionChonTuong;
    const option2 = formik.values.optionCaySieuToc;

    if (
      rank1 &&
      rank2 &&
      Number.isInteger(rank1) &&
      Number.isInteger(rank2) &&
      rank1 > 0 &&
      rank2 > 0 &&
      rank2 > rank1
    ) {
      let x = 0; // số tiền phải trả

      const arraySelectRanks = dataPlowing.filter(
        (dataPlowingItem) => dataPlowingItem.id > rank1 && dataPlowingItem.id <= rank2
      );

      for (const item of arraySelectRanks) {
        x += item.balance;
      }

      if (option1 && option2) {
        setAmountToPay(1.65 * x);
      } else if (option1 && !option2) {
        setAmountToPay(1.3 * x);
      } else if (!option1 && option2) {
        setAmountToPay(1.35 * x);
      } else {
        setAmountToPay(x);
      }
    } else {
      setAmountToPay(0);
    }
  }, [
    formik.values.rankHienTai,
    formik.values.rankMongMuon,
    formik.values.optionChonTuong,
    formik.values.optionCaySieuToc,
  ]);

  if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
    return <Redirect to={"/login"} />;
  }

  if (!dataPlowing || dataPlowing.length == 0) return <></>;

  return (
    <div className="caythuelienquan-page">
      <div className="caythuelienquan">
        <Header />

        <div className="container">
          <div className="caythue-title">CÀY THUÊ LIÊN QUÂN</div>

          <div className="caythue-image">
            <img src={mainImg} alt="Cày thuê liên quân" />
          </div>

          <div className="caythue-form">
            <span className="link-to-history-lsct" onClick={() => history.push("/lich-su-cay-thue")}>
              Lịch sử cày thuê
            </span>

            <div className="caythue-form-inside">
              <div className="field">
                <label htmlFor="rankHienTai">Rank hiện tại *</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={formik.values.rankHienTai}
                  onChange={(value) => formik.setFieldValue("rankHienTai", value)}
                  options={dataPlowing.map((dataPlowingItem) => {
                    return {
                      value: dataPlowingItem.id,
                      label: dataPlowingItem.name,
                    };
                  })}
                />
              </div>

              <div className="field">
                <label htmlFor="rankMongMuon">Rank mong muốn *</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={formik.values.rankMongMuon}
                  onChange={(value) => formik.setFieldValue("rankMongMuon", value)}
                  options={dataPlowing2.map((dataPlowingItem) => {
                    return {
                      value: dataPlowingItem.id,
                      label: dataPlowingItem.name,
                    };
                  })}
                />
              </div>

              <div className="field">
                <span className="tuychonmorong">Tuỳ chọn mở rộng</span>
                <div className="sub-field">
                  <input
                    type="checkbox"
                    id="optionChonTuong"
                    name="optionChonTuong"
                    value={formik.values.optionChonTuong}
                    onChange={formik.handleChange}
                  ></input>
                  <label htmlFor="optionChonTuong">Chọn tướng +30%</label>
                </div>

                <div className="sub-field">
                  <input
                    type="checkbox"
                    id="optionCaySieuToc"
                    name="optionCaySieuToc"
                    value={formik.values.optionCaySieuToc}
                    onChange={formik.handleChange}
                  ></input>
                  <label htmlFor="optionCaySieuToc">Cày siêu tốc +35%</label>
                </div>
              </div>

              <div className="field">
                <label htmlFor="taiKhoanCanCay">Tài khoản cần cày *</label>
                <input
                  type="text"
                  id="taiKhoanCanCay"
                  name="taiKhoanCanCay"
                  value={formik.values.taiKhoanCanCay}
                  onChange={formik.handleChange}
                />
                {formik.errors.taiKhoanCanCay ? <span className="formik-error">* Required</span> : null}
              </div>

              <div className="field">
                <label htmlFor="password">Mật khẩu tài khoản *</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password ? <span className="formik-error">* Required</span> : null}
              </div>

              <div className="field amount-field">
                <span className="text">Số tiền cần trả</span>
                <span className="num">{Math.round(Number(amountToPay)).toLocaleString()}</span>
              </div>

              <div className="field btn-field">
                <button
                  disabled={amountToPay <= 0 || loading}
                  type="submit"
                  onClick={formik.handleSubmit}
                  className={amountToPay <= 0 || loading ? "disabled" : "buy-plowing-button"}
                >
                  {loading ? <Spin /> : "Thuê cày ngay"}
                </button>
              </div>
            </div>

            {/* <div className="coming-soon-caythue">COMING SOON</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
