import React from "react";
import vietnamLottery from "../../assets/image/logo-Vlottery.webp";

export default function Card2() {
  return (
    <div className="game-card2">
      <div className="overlay"></div>
      <div className="content">
        <div className="top">
          <h2 className="title">VIETNAM LOTTERY</h2>
          <p className="desc">Xổ số Việt Nam</p>
        </div>
        <div className="bottom">
          <button className="play coming">
            <span>Coming soon</span>
          </button>
        </div>
        <img className="vietnamLottery" src={vietnamLottery} />
      </div>
    </div>
  );
}
