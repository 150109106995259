import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { axiosService } from "../../util/service";
import Menu5AccManagement from "./Menu5AccManagement";
import Menu5ListF1 from "./Menu5ListF1";

export default function Menu5() {
  const [network, setNetwork] = useState(null);

  const { user } = useSelector((root) => root.userReducer);

  const renderTree = (array) => {
    return array.map((item, index) => {
      if (item.array) {
        return (
          <li key={index}>
            <i className="fa-solid fa-users"></i> {item.userName} <span> - {item.email}</span>
            <ul>{renderTree(item.array)}</ul>
          </li>
        );
      } else
        return (
          <li key={index}>
            <i className="fa-solid fa-user"></i> {item.userName} <span> - {item.email}</span>
          </li>
        );
    });
  };

  const getNetwork = async () => {
    try {
      let response = await axiosService.post("/api/user/getParent", {
        userid: user.id,
      });
      setNetwork(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNetwork();
  }, []);

  if (!network) return <></>;

  return (
    <div>
      <div className="tree">
        <div className="title">MẠNG LƯỚI</div>
        <ul>
          <li>
            <i className="fa-solid fa-house-user"></i> {network.profile.userName}{" "}
            <span> - {network.profile.email}</span>
            {network.data && <ul>{renderTree(network.data)}</ul>}
          </li>
        </ul>
      </div>

      <div style={{ marginBottom: 20 }}></div>

      <Menu5AccManagement />

      <div style={{ marginBottom: 20 }}></div>

      <Menu5ListF1 />
    </div>
  );
}
