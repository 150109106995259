import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { calcTime } from "../../function/calcTime";
import { axiosService } from "../../util/service";

export default function TimerAOV() {
  const { time, timeMode } = useSelector((root) => root.gameReducer);

  const [secA, setSecA] = useState(0); // first digit of second
  const [secB, setSecB] = useState(0); // second digit of second
  const [secC, setSecC] = useState(0); // first digit of minute
  const [secD, setSecD] = useState(0); // second digit of minute

  const [currentGameID, setCurrentGameID] = useState(undefined);

  useEffect(() => {
    // tính thời gian hiển thị đồng hồ
    const x = calcTime(time); // [c,d,a,b]
    setSecC(x[0]);
    setSecD(x[1]);
    setSecA(x[2]);
    setSecB(x[3]);

    // khi time = 58, 178, 298, 598 gọi API để cập nhật id game mới
    if (
      (timeMode === 1 && time === 58) ||
      (timeMode === 3 && time === 178) ||
      (timeMode === 5 && time === 298) ||
      (timeMode === 10 && time === 598)
    ) {
      getData({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time, timeMode]);

  useEffect(() => {
    getData({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  const getData = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/getAovLotery", payload);
      let arrayResult = response.data.data.array;

      // xét arrayResult[0]
      // arrayResult[0].number == null: trong thời gian của 1 game thì number sẽ null, show id đó lên kỳ xổ hiện tại

      if (arrayResult[0].number == null) {
        setCurrentGameID(arrayResult[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {time !== null ? (
        <>
          <div className="admin-card">
            <span className="card-title">ID game hiện tại</span>
            <div className="gameID card-content">{currentGameID}</div>
          </div>

          <div className="admin-card">
            <span className="card-title">Thời gian còn lại</span>
            <div className="time card-content">
              <span className="second-digit">{secC}</span>
              <span className="second-digit">{secD}</span>
              <span className="divider">:</span>
              <span className="second-digit">{secA}</span>
              <span className="second-digit">{secB}</span>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
