import { Pagination, Select, Table, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function Orders() {
  const { user } = useSelector((root) => root.userReducer);

  // for table
  const [array, setArray] = useState([]);

  // for searching
  const [searchWord, setSearchWord] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // for select
  // 100 là tất cả, chỉ là số đại diện không có ý nghĩa
  // 1-3-5-10: tương ứng gameMode 1-3-5-10 phút
  const [mode, setMode] = useState(100);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const getHistory = async (limit, page, mode) => {
    // nếu mode là 100 thì load tất cả
    // nếu mode là số 1-3-5-10 thì load gameMode tương ứng

    let payload;
    if (mode == 100) {
      payload = { limit, page };
    } else {
      payload = { limit, page, time: mode };
    }

    let url;
    if (user.id == 1) {
      // admin
      url = "api/lotery/getAllOrderAdmin";
    } else if (user.type == 1) {
      url = "api/admin/getListUserOrderParentAdmin";
    } else {
      url = "";
    }

    try {
      let response = await axiosService.post(url, payload);
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getHistory(ROWS, page, mode);
  };

  useEffect(() => {
    setCurrent(1);
    getHistory(ROWS, 1, mode);
  }, [mode]);

  const renderOrder = (order) => {
    if (order == "big") {
      return <span className="type type-big">Lớn</span>;
    } else if (order == "small") {
      return <span className="type type-small">Nhỏ</span>;
    } else if (order == "0") {
      return <span className="type type-0">0</span>;
    } else if (order == "5") {
      return <span className="type type-5">5</span>;
    } else if (order == "1") {
      return <span className="type type-1379">1</span>;
    } else if (order == "3") {
      return <span className="type type-1379">3</span>;
    } else if (order == "7") {
      return <span className="type type-1379">7</span>;
    } else if (order == "9") {
      return <span className="type type-1379">9</span>;
    } else if (order == "2") {
      return <span className="type type-2468">2</span>;
    } else if (order == "4") {
      return <span className="type type-2468">4</span>;
    } else if (order == "6") {
      return <span className="type type-2468">6</span>;
    } else if (order == "8") {
      return <span className="type type-2468">8</span>;
    } else if (order == "green") {
      return <span className="type type-green">Xanh</span>;
    } else if (order == "violet") {
      return <span className="type type-purple">Tím</span>;
    } else if (order == "red") {
      return <span className="type type-red">Đỏ</span>;
    }
  };

  const columns = [
    {
      title: "User",
      key: "User",
      width: 200,
      render: (_, { username, email }) => {
        return (
          <div>
            <div>
              <b>{username}</b>
            </div>
            <span style={{ fontStyle: "italic" }}>{email}</span>
          </div>
        );
      },
    },
    {
      title: "Kỳ xổ",
      key: "Kỳ xổ",
      width: 120,
      render: (_, { time, idChartLottery }) => {
        return (
          <div>
            <span>{time} phút</span> <br />
            <span>#{idChartLottery}</span>
          </div>
        );
      },
    },
    {
      title: "Đặt cược",
      key: "Đặt cược",
      width: 150,
      render: (_, { order, amount, balance }) => {
        return (
          <div>
            <div>{renderOrder(order)}</div>
            <span>
              {amount} x {Math.round(Number(balance)).toLocaleString()}
            </span>
          </div>
        );
      },
    },
    {
      title: "Phí",
      key: "Phí",
      dataIndex: "fee",
      width: 100,
    },
    {
      title: "Kết quả",
      key: "Kết quả",
      width: 150,
      render: (_, record) => {
        if (record.status == "PENDING") {
          return <span>PENDING</span>;
        } else if (record.status == "SUCCESS") {
          if (record.profit != 0) {
            return (
              <>
                <span className="result-win">THẮNG</span> <br />
                <span className="result-win-money">+{Math.round(Number(record.profit)).toLocaleString()}</span>
              </>
            );
          } else if (record.profit == 0) {
            return (
              <>
                <span className="result-lose">THUA</span> <br />
                <span className="result-lose-money">
                  -{Math.round(Number(record.balance) * Number(record.amount)).toLocaleString()}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
  ];

  const handleChange = (value) => {
    setMode(value);
  };

  const resetTable = () => {
    setCurrent(1);
    getHistory(ROWS, 1, mode);
    setIsSearching(false);
  };

  const onSearch = (keyword) => {
    setSearchWord(keyword);

    if (keyword !== "") {
      searchOrder(keyword);
      setIsSearching(true);
    } else {
      resetTable();
    }
  };

  const onChange = (e) => {
    const keyword = e.target.value;
    setSearchWord(keyword);

    if (keyword === "") {
      resetTable();
    }
  };

  const searchOrder = async (keyword) => {
    try {
      const response = await axiosService.post("api/admin/sreachListToEmail", {
        limit: 1000,
        page: 1,
        keyWord: keyword,
        table: "tb_order",
      });
      setArray(response.data.data);

      setCurrent(1);
      setTotalRecord(10);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="admin-history-orders">
      <div className="title-area">
        <div className="left">
          <h2 className="title">Lịch sử đặt cược</h2>

          <div className="select-box">
            <Select
              disabled={isSearching}
              defaultValue={100}
              value={mode}
              style={{
                width: 100,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 100,
                  label: "Tất cả",
                },
                {
                  value: 1,
                  label: "1 phút",
                },
                {
                  value: 3,
                  label: "3 phút",
                },
                {
                  value: 5,
                  label: "5 phút",
                },
                {
                  value: 10,
                  label: "10 phút",
                },
              ]}
            />
          </div>
        </div>

        {user?.id === 1 && (
          <Input.Search
            placeholder="Tìm kiếm email"
            className="search-box"
            allowClear
            onSearch={onSearch}
            onChange={onChange}
          />
        )}

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          hideOnSinglePage
        />
      </div>

      <Table
        columns={columns}
        dataSource={array}
        rowKey={(record) => record.id}
        pagination={
          isSearching
            ? { position: ["topRight"], defaultCurrent: 1, showSizeChanger: false, showQuickJumper: false }
            : false
        }
        scroll={{
          x: 900,
        }}
      />
    </div>
  );
}
