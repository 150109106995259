import { Button, Checkbox, Input, Modal, Pagination, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROWS } from "../../constant/constant";
import { showAlert } from "../../function/showAlert";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

const { Search } = Input;

export default function AdminUser() {
  const [data, setData] = useState([]);
  const { user } = useSelector((root) => root.userReducer);

  // for searching
  const [searchWord, setSearchWord] = useState("");

  // for sorting balance
  const [isSorting, setIsSorting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  // for modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState(null);
  const [balanceField, setBalanceField] = useState("");

  const showModal = (record) => {
    if (user.id == 1) {
      setIsModalVisible(true);
      setRecord(record);
      setBalanceField(Number.isInteger(record.balance) ? record.balance : record.balance?.toFixed(2));
    }
  };

  const handleOk = () => {
    if (!Number.isInteger(Number(balanceField))) {
      showAlert("error", "Số tiền VNĐ phải là số nguyên");
      console.log(balanceField);
      return;
    }

    setBalance(record.id, balanceField);

    setIsModalVisible(false);
    setRecord(null);
    setBalanceField("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRecord(null);
    setBalanceField("");
  };

  const handleChangeBalance = (e) => {
    setBalanceField(e.target.value);
  };

  const setBalance = async (id, balance) => {
    try {
      const response = await axiosService.post("api/admin/setBalance", {
        id: id.toString(),
        balance: balance.toString(),
      });
      showToast("success", response.data.message);
      getAllUsers(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  async function getAllUsers(limit, page) {
    const payload = isSorting
      ? {
          limit,
          page,
          order: "ORDER BY balance DESC",
        }
      : { limit, page };

    let url;
    if (user.id == 1) {
      // admin
      url = "api/admin/getAllUser";
    } else if (user.type == 1) {
      url = "api/admin/getUserParentAdmin";
    } else {
      url = "";
    }

    try {
      const response = await axiosService.post(url, payload);

      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setCurrent(1);
    getAllUsers(ROWS, 1);
  }, [isSorting]);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllUsers(ROWS, page);
  };

  const resetTable = () => {
    setCurrent(1);
    getAllUsers(ROWS, 1);
    setIsSearching(false);
  };

  const onSearch = (keyword) => {
    setSearchWord(keyword);

    if (keyword !== "") {
      searchUsers(keyword);
      setIsSearching(true);
    } else {
      resetTable();
    }
  };

  const onChange = (e) => {
    const keyword = e.target.value;
    setSearchWord(keyword);

    if (keyword === "") {
      resetTable();
    }
  };

  const searchUsers = async (keyword) => {
    let url;
    if (user.id == 1) {
      // admin
      url = "api/admin/sreachListToEmail";
    } else if (user.type == 1) {
      url = "api/admin/sreachListToEmailInParentAdmin";
    } else {
      url = "";
    }

    try {
      const response = await axiosService.post(url, {
        limit: 10,
        page: 1,
        keyWord: keyword,
        table: "tb_user",
      });
      setData(response.data.data);
      setCurrent(1);
      setTotalRecord(10);
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserToSubAdmin = async (payload) => {
    try {
      const response = await axiosService.post("api/admin/updateUser", payload);
      showAlert("success", response.data.message);
      getAllUsers(ROWS, current);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeCheckBox = async (e, recordID, recordType) => {
    await updateUserToSubAdmin({
      userid: recordID,
      name: "type",
      number: recordType == 0 ? 1 : 0,
    });
  };

  const columns = [
    {
      title: "STT",
      key: "STT",
      width: 60,
      render: (t, r, i) => {
        return <span>{(current - 1) * 10 + i + 1}</span>;
      },
    },
    {
      title: "Email",
      key: "email",
      width: 220,
      render: (_, record) => {
        return (
          <div style={{ textAlign: "left", cursor: "pointer" }}>
            <Link className="linked_email" to={"/users-detail/" + record.id}>
              {record.email}
            </Link>
            {record.block == 0 ? (
              <></>
            ) : (
              <Tag color={"red"} style={{ marginLeft: 8 }}>
                <strong>BLOCKED</strong>
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Mã giới thiệu",
      dataIndex: "referral",
      key: "referral",
      width: 170,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>Số dư</span>
          {!isSearching && (
            <Button size="small" onClick={() => setIsSorting((prev) => !prev)} type={isSorting ? "primary" : "default"}>
              <i className="fa-solid fa-arrow-down-wide-short"></i>
            </Button>
          )}
        </div>
      ),
      key: "Số dư",
      dataIndex: "balance",
      width: 150,
      render: (_, record) => {
        const recordBalance = Math.round(record.balance);

        return (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>{recordBalance.toLocaleString()}</span>
            {user.id == 1 && (
              // admin thì mới được chỉnh sửa số dư
              <Tooltip title="Edit balance">
                <i
                  className="fa-solid fa-pen-to-square"
                  style={{ cursor: "pointer" }}
                  onClick={() => showModal(record)}
                ></i>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Thời gian",
      dataIndex: "created_at",
      key: "created_at",
      width: 170,
    },
    {
      title: "Active",
      key: "Active",
      dataIndex: "active",
      width: 80,
      // nếu là admin: gặp user chưa active thì hiện nút cho active
      // nếu là subadmin: không cho active
      render: (_, record) => {
        if (user.id == 1) {
          if (record.active === 0) {
            return (
              <Button size="small" onClick={() => activeUser(record.id)}>
                Active
              </Button>
            );
          } else {
            return (
              <Tag color={"green"}>
                <strong>ACTIVE</strong>
              </Tag>
            );
          }
        } else {
          if (record.active === 0) {
            return (
              <Tag color={"red"}>
                <strong>NOT ACTIVE</strong>
              </Tag>
            );
          } else {
            return (
              <Tag color={"green"}>
                <strong>ACTIVE</strong>
              </Tag>
            );
          }
        }
      },
    },
    {
      title: "Sub Admin",
      key: "Sub Admin",
      width: 90,
      render: (_, record) => {
        if (record.id === 1) return <></>;
        return (
          <Checkbox onChange={(e) => onChangeCheckBox(e, record.id, record.type)} checked={record.type === 1}>
            Sub Admin
          </Checkbox>
        );
      },
    },
  ];

  const activeUser = async (id) => {
    try {
      const response = await axiosService.post("api/admin/activeUser", { id });
      showAlert("success", response.data.message);
      getAllUsers(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  return (
    <div className="admin-user-page">
      <div className="title-area">
        <h2 className="title">Users</h2>

        <Search
          placeholder="Tìm kiếm email"
          className="search-box"
          allowClear
          onSearch={onSearch}
          onChange={onChange}
        />

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          hideOnSinglePage
          className="pagination-box"
        />
      </div>

      <Table
        columns={user.id == 1 ? columns : columns.filter((col) => col.key != "Sub Admin")}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={user.id == 1 ? { x: 1300 } : { x: 1210 }}
      />

      {/* Modal */}
      <Modal title="Edit balance" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered>
        <div className="field balance_field">
          <label htmlFor="balance_field">Balance</label>
          <Input
            type="number"
            size="large"
            id="balance_field"
            name="balance_field"
            addonAfter="VNĐ"
            value={balanceField}
            onChange={handleChangeBalance}
          />
        </div>
      </Modal>
      {/* End modal */}
    </div>
  );
}
