import { Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { axiosService } from "../../util/service";

const { Option } = Select;

export default function Menu5AccManagement() {
  const [level, setLevel] = useState(100);
  const [array, setArray] = useState([]);

  const getParentToLevel = async (level) => {
    try {
      let response = await axiosService.post("api/lotery/getParentToLevel", {
        level,
      });
      setArray(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "STT",
      key: "STT",
      render: (t, r, i) => {
        return <span style={{ fontSize: 12 }}>{i + 1}</span>;
      },
      width: 80,
    },
    {
      title: "Username",
      key: "Username",
      dataIndex: "userName",
      width: 150,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: (
        <span>
          Parent
          <br />
          username
        </span>
      ),
      key: "Parent Username",
      dataIndex: "userNameParent",
      width: 150,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
    {
      title: (
        <span>
          Commission
          <br />
          balance
        </span>
      ),
      key: "Commission balance",
      dataIndex: "commissionBalance",
      width: 150,
      render: (text) => {
        return <span style={{ fontSize: 12 }}>{text}</span>;
      },
    },
  ];

  useEffect(() => {
    getParentToLevel(level);
  }, [level]);

  return (
    <div className="acc-manage">
      <div className="title-area">
        <div className="title">Quản lý tài khoản</div>

        <div className="select-box">
          <Select size="small" value={level} onChange={(v) => setLevel(v)}>
            <Option value={100}>Level 0</Option>
            <Option value={1}>Level 1</Option>
            <Option value={2}>Level 2</Option>
            <Option value={3}>Level 3</Option>
            <Option value={4}>Level 4</Option>
            <Option value={5}>Level 5</Option>
          </Select>
        </div>
      </div>

      <div className="table">
        <Table size="small" columns={columns} dataSource={array} rowKey={(record) => record.id} pagination={false} />
      </div>
    </div>
  );
}
