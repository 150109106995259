import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";

import { gameReducer } from "./reducers/gameReducer";
import { userReducer } from "./reducers/userReducer";
import { sidebarReducer } from "./reducers/sidebarReducer";
import { promoReducer } from "./reducers/promoReducer";
import { adminReducer } from "./reducers/adminReducer";

const rootReducer = combineReducers({ gameReducer, userReducer, sidebarReducer, promoReducer, adminReducer });

const middleWare = applyMiddleware(reduxThunk);

const composeCustom = compose(middleWare);
// const composeCustom = compose(middleWare, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const store = createStore(rootReducer, composeCustom);

export default store;
