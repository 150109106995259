import React from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { useLocation } from "react-router-dom";

export default function Auth() {
  const location = useLocation();

  return (
    <div className="auth-page">
      <div className="auth">
        <Header />

        <div className="banner"></div>

        <div className="container">
          {location.pathname == "/login" ? <LoginForm /> : null}
          {location.pathname == "/signup" ? <SignupForm /> : null}
        </div>

        <Footer />
      </div>
    </div>
  );
}
