import { Button, Image, Input, Modal, Pagination, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { axiosService, DOMAIN } from "../../util/service";

export default function ManageDepositVND() {
  const { user } = useSelector((root) => root.userReducer);
  const [array, setArray] = useState([]);

  // for searching
  const [searchWord, setSearchWord] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  // modal - review giao dịch
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // end modal

  // modal 2 - nhập lý do huỷ
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [note, setNote] = useState("");
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  // end modal 2

  // modal 3 - xem lý do huỷ
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const showModal3 = (note) => {
    setCancelReason(note);
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  // end modal 3

  async function getAllData(limit, page) {
    let url;
    if (user.id == 1) {
      // admin
      url = "api/depositVND/historyDepositVndAdmin";
    } else if (user.type == 1) {
      url = "api/admin/getListUserDepositParentAdmin";
    } else {
      url = "";
    }

    try {
      const response = await axiosService.post(url, {
        limit,
        page,
      });
      setArray(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllData(ROWS, 1);
    setCurrent(1);
  }, []);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllData(ROWS, page);
  };

  const columns = [
    {
      title: "UserID",
      key: "UserID",
      dataIndex: "userid",
      width: 80,
    },
    {
      title: "Email",
      key: "Email",
      dataIndex: "email",
      width: 200,
    },
    {
      title: "Ngân hàng",
      key: "Ngân hàng",
      width: 180,
      render: (_, { bank_name }) => {
        return (
          <div className="bank-column">
            {renderBankImg(bank_name, 1)}
            {bank_name}
          </div>
        );
      },
    },
    {
      title: "Số tiền",
      key: "Số tiền",
      dataIndex: "amount",
      width: 180,
      render: (a) => {
        return <span>{Number(a).toLocaleString()}</span>;
      },
    },
    {
      title: "Nội dung chuyển khoản",
      key: "Nội dung chuyển khoản",
      dataIndex: "code_unique",
      width: 250,
    },
    {
      title: "Hình ảnh",
      key: "Hình ảnh",
      width: 150,
      render: (_, record) => {
        if (!record.images) {
          return <span>-</span>;
        } else {
          return (
            <Button
              size="small"
              onClick={() => {
                window.open(DOMAIN + record.images);
              }}
            >
              Xem hình ảnh
            </Button>
          );
        }
      },
    },
    {
      title: "Thời gian",
      key: "Thời gian",
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: "Trạng thái",
      key: "Trạng thái",
      width: 180,
      render: (_, { type_admin, type_user, note }) => {
        if (type_admin == 0 && type_user == 0) {
          return (
            <Tag color="orange">
              <b>ĐANG CHỜ</b>
            </Tag>
          );
        } else if (type_admin == 0 && type_user == 2) {
          return (
            <Tag color="grey">
              <b>USER HUỶ GIAO DỊCH</b>
            </Tag>
          );
        } else if (type_admin == 2 && type_user == 0) {
          return (
            <Tag color="blue">
              <b>USER ĐÃ CHUYỂN TIỀN</b>
            </Tag>
          );
        } else if (type_admin == 3) {
          return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Tag color="red">
                <b>TỪ CHỐI</b>
              </Tag>
              <i
                className="fa-solid fa-circle-question"
                style={{ fontSize: 18, color: "grey", cursor: "pointer", marginLeft: 6 }}
                onClick={() => showModal3(note)}
              ></i>
            </div>
          );
        } else if (type_admin == 1) {
          return (
            <Tag color="green">
              <b>THÀNH CÔNG</b>
            </Tag>
          );
        }
      },
    },
    {
      title: "Xét duyệt",
      key: "Xetduyet",
      width: 120,
      render: (_, record) => {
        if (record.type_admin == 0 && record.type_user == 0) {
          // pending -> không hiển thị nút review
          return <></>;
        } else if (record.type_admin == 0 && record.type_user == 2) {
          // user huỷ lệnh chuyển tiền
          return <></>;
        } else if (record.type_admin == 3 && record.type_user == 3) {
          // đã từ chối rồi, không xét duyệt nữa, chỉ xem thôi
          return (
            <Button size="small" onClick={() => showModal(record)}>
              Xem
            </Button>
          );
        } else if (record.type_admin == 1 && record.type_user == 1) {
          // đã thành công rồi, không xét duyệt nữa, chỉ xem thôi
          return (
            <Button size="small" onClick={() => showModal(record)}>
              Xem
            </Button>
          );
        } else {
          return (
            <Button size="small" onClick={() => showModal(record)}>
              Xét duyệt
            </Button>
          );
        }
      },
    },
  ];

  const handleCancelTransaction = async () => {
    if (!currentRecord) {
      showAlert("error", "Something went wrong");
      return;
    }

    handleCancel();
    showModal2();
  };

  const handleApproveTransaction = async () => {
    if (!currentRecord) {
      showAlert("error", "Something went wrong");
      return;
    }

    await approveTransaction(currentRecord.id);
    handleCancel();
    await getAllData(ROWS, current);
  };

  const cancelTransaction = async (id, note) => {
    // note: lý do từ chối
    try {
      let response = await axiosService.post("api/depositVND/cancelDepositVNDAdmin", {
        id,
        note,
      });
      showAlert("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const approveTransaction = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/activeDepositVNDAdmin", {
        id,
      });
      showAlert("success", response.data.message);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const renderFooterModal1 = (type_admin, type_user) => {
    if (type_admin == 0 && type_user == 2) {
      // user cancel -> chỉ xem thôi chứ không có hành động reject hay approve
      return null;
    } else if (type_admin == 2 && type_user == 0) {
      // user confirm -> admin quyết định approve / reject
      return [
        <Button key="Reject transaction" type="primary" danger onClick={handleCancelTransaction}>
          Từ chối
        </Button>,
        <Button key="Approve transaction" type="primary" onClick={handleApproveTransaction}>
          Chấp nhận
        </Button>,
      ];
    } else if (type_admin == 3) {
      // cancel rồi
      return null;
    } else if (type_admin == 1) {
      // approve rồi
      return null;
    }
  };

  const resetTable = () => {
    setCurrent(1);
    getAllData(ROWS, 1);
    setIsSearching(false);
  };

  const onSearch = (keyword) => {
    setSearchWord(keyword);

    if (keyword !== "") {
      searchTransaction(keyword);
      setIsSearching(true);
    } else {
      resetTable();
    }
  };

  const onChange = (e) => {
    const keyword = e.target.value;
    setSearchWord(keyword);

    if (keyword === "") {
      resetTable();
    }
  };

  const searchTransaction = async (keyword) => {
    try {
      const response = await axiosService.post("api/admin/sreachListToEmail", {
        limit: 1000,
        page: 1,
        keyWord: keyword,
        table: "tb_banking_transaction",
      });
      setArray(response.data.data);

      setCurrent(1);
      setTotalRecord(10);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="manage-deposit">
      <div className="title-area">
        <h2 className="title">Giao dịch nạp tiền</h2>

        {user?.id === 1 && (
          <Input.Search
            placeholder="Tìm kiếm email"
            className="search-box"
            allowClear
            onSearch={onSearch}
            onChange={onChange}
          />
        )}

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
          hideOnSinglePage
        />
      </div>

      <Table
        columns={user?.id === 1 ? columns : columns.filter((col) => col.key != "Xetduyet")}
        dataSource={array}
        rowKey={(record) => record.id}
        pagination={
          isSearching
            ? { position: ["topRight"], defaultCurrent: 1, showSizeChanger: false, showQuickJumper: false }
            : false
        }
        scroll={{
          x: 1320,
        }}
      />

      <Modal
        title="Xét duyệt giao dịch"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={currentRecord ? renderFooterModal1(currentRecord.type_admin, currentRecord.type_user) : null}
      >
        {currentRecord && currentRecord.images && (
          <div className="img-in-modal-review-transaction">
            <Image src={DOMAIN + currentRecord.images} />
          </div>
        )}
        {currentRecord && !currentRecord.images && <span>Không có hình ảnh</span>}
      </Modal>

      <Modal
        title="Từ chối giao dịch"
        visible={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              // reset
              handleCancel2();
              setCurrentRecord(null);
              setNote("");
            }}
          >
            Cancel
          </Button>,
          <Button
            key="Reject transaction"
            type="primary"
            danger
            onClick={async () => {
              await cancelTransaction(currentRecord.id, note);

              // reset
              handleCancel2();
              setCurrentRecord(null);
              setNote("");

              await getAllData(ROWS, current);
            }}
          >
            Từ chối
          </Button>,
        ]}
      >
        <Input.TextArea
          rows={5}
          placeholder="Lý do từ chối giao dịch..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Modal>

      <Modal
        title="Lý do từ chối giao dịch"
        visible={isModalOpen3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={null}
      >
        {cancelReason}
      </Modal>
    </div>
  );
}
