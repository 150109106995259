import React from "react";
import Marquee from "react-fast-marquee";

export default function MarqueeComponent() {
  return (
    <div className="marquee-component">
      <i className="fa-solid fa-bell"></i>

      <div className="main">
        <Marquee gradient={false} speed={60} delay={2}>
          <span className="marquee-text">Welcome to AOV Money! Wish you good luck!</span>
        </Marquee>
      </div>
    </div>
  );
}
