import { Button, Input, Modal, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ROWS } from "../../constant/constant";
import { getBankList } from "../../function/getBankList";
import { renderBankImg } from "../../function/renderBankImg";
import { showAlert } from "../../function/showAlert";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";

export default function UserBank() {
  const [data, setData] = useState([]);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [info, setInfo] = useState({
    bank: "",
    number: "",
    name: "",
  });
  const showModal = (record) => {
    setCurrentRecord(record);
    setInfo({
      bank: record.name_banking,
      number: record.number_banking,
      name: record.owner_banking,
    });

    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (!info.bank) {
      showAlert("error", "Vui lòng chọn ngân hàng");
      return;
    }
    if (!info.number) {
      showAlert("error", "Vui lòng nhập số tài khoản");
      return;
    }
    if (!info.name) {
      showAlert("error", "Vui lòng nhập tên chủ tài khoản");
      return;
    }

    setIsModalOpen(false);

    await updateUserBank({
      owner_banking: info.name,
      name_banking: info.bank,
      number_banking: info.number,
      idBanking: currentRecord.id,
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // end modal

  const getAllData = async (limit, page) => {
    try {
      let response = await axiosService.post("api/depositVND/getAllBankingUserAdmin", { limit, page });
      setData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserBank = async (data) => {
    try {
      let response = await axiosService.post("api/depositVND/updateBankingUserAdmin", data);
      showToast("success", response.data.message);
      await getAllData(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  const deleteUserBank = async (id) => {
    try {
      let response = await axiosService.post("api/depositVND/deleteBankingUserAdmin", { id });
      showToast("success", response.data.message);
      await getAllData(ROWS, current);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getAllData(ROWS, 1);
  }, []);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getAllData(ROWS, page);
  };

  const columns = [
    {
      title: "User ID",
      key: "User ID",
      dataIndex: "userid",
      width: 70,
    },
    {
      title: "Ngân hàng",
      key: "Bank",
      width: 150,
      render: (_, record) => {
        return (
          <div className="bank-column">
            {renderBankImg(record.name_banking, 1)}
            <span>{record.name_banking}</span>
          </div>
        );
      },
    },
    {
      title: "Tên chủ tài khoản",
      key: "Account name",
      width: 200,
      dataIndex: "owner_banking",
    },
    {
      title: "Số tài khoản",
      key: "Account number",
      width: 200,
      dataIndex: "number_banking",
    },
    {
      title: "Thời gian",
      key: "Time",
      width: 200,
      dataIndex: "created_at",
    },
    {
      title: "Quản lý",
      key: "Manage",
      width: 150,
      render: (_, record) => {
        return (
          <>
            <Button size="small" onClick={() => showModal(record)}>
              Chỉnh sửa
            </Button>
            <Button size="small" style={{ marginLeft: 6 }} danger onClick={() => deleteUserBank(record.id)}>
              Xoá
            </Button>
          </>
        );
      },
    },
  ];

  const handleChangeSelectBank = (value) => {
    setInfo({
      ...info,
      bank: value,
    });
  };

  return (
    <div className="mn-user-bank">
      <div className="title-area">
        <h2 className="title">Ngân hàng rút tiền</h2>

        <Pagination
          defaultCurrent={1}
          total={totalRecord}
          current={current}
          onChange={onPaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
          className="pagination-box"
        />
      </div>

      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{
          x: 1000,
        }}
      />

      <Modal
        title="Chỉnh sửa ngân hàng"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Cập nhật"
      >
        <form className="add-bank-modal">
          <div className="field bank-field">
            <label htmlFor="bank">Tên ngân hàng</label>
            <Select
              size="large"
              id="bank"
              style={{ width: "100%" }}
              onChange={handleChangeSelectBank}
              value={info.bank}
              options={getBankList().map((bank) => {
                return {
                  value: bank,
                  label: bank,
                };
              })}
            />
          </div>

          <div className="field number-field">
            <label htmlFor="number">Số tài khoản</label>
            <Input
              type="text"
              size="large"
              id="number"
              value={info.number}
              onChange={(e) =>
                setInfo({
                  ...info,
                  number: e.target.value,
                })
              }
            />
          </div>

          <div className="field name-field">
            <label htmlFor="name">Tên chủ tài khoản</label>
            <Input
              type="text"
              size="large"
              id="name"
              value={info.name}
              onChange={(e) =>
                setInfo({
                  ...info,
                  name: e.target.value,
                })
              }
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
