import React from "react";
import wingo from "../../assets/image/logo-wingo.webp";
import { useHistory } from "react-router-dom";

export default function Card() {
  let history = useHistory();

  return (
    <div className="game-card">
      <div className="overlay"></div>
      <div className="content">
        <div className="top">
          <h2 className="title">WIN GO</h2>
          <p className="desc">Đoán màu xanh / tím / đỏ để giành chiến thắng</p>
          {/* <span className="link-to-guide" onClick={() => history.push("/guide")}>
            Xem hướng dẫn
          </span> */}
        </div>
        <div className="bottom">
          <button className="play" onClick={() => history.push("/wingo-gameplay")}>
            <span>Play</span>
            <i className="fa-solid fa-circle-right"></i>
          </button>
        </div>
        <img className="wingo" src={wingo} />
      </div>
    </div>
  );
}
