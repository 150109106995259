export const getBankList = () => {
  return [
    "Vietcombank",
    "Techcombank",
    "VietinBank",
    "TP Bank",
    "LienVietPostBank",
    "Sacombank",
    "NamABank",
    "MSB",
    "BIDV",
    "MB",
    "ACB",
    "VPBank",
    "Agribank",
    "Eximbank",
    "HD Bank",
    "VIB",
    "Ví Momo",
  ];
};
