import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Time() {
  const { timeMode } = useSelector((root) => root.gameReducer);
  const dispatch = useDispatch();

  const handleChangeMode = (mode) => {
    dispatch({
      type: "CHANGE_TIME_MODE",
      payload: mode,
    });
  };

  return (
    <div className="aov-time-choice">
      <div className={timeMode == 1 ? "item item-active" : "item"} onClick={() => handleChangeMode(1)}>
        <i className="fa-solid fa-circle-question"></i>
        <i className="fa-solid fa-clock"></i>
        <span>1 phút</span>
      </div>

      <div className={timeMode == 3 ? "item item-active" : "item"} onClick={() => handleChangeMode(3)}>
        <i className="fa-solid fa-circle-question"></i>
        <i className="fa-solid fa-clock"></i>
        <span>3 phút</span>
      </div>

      <div className={timeMode == 5 ? "item item-active" : "item"} onClick={() => handleChangeMode(5)}>
        <i className="fa-solid fa-circle-question"></i>
        <i className="fa-solid fa-clock"></i>
        <span>5 phút</span>
      </div>

      <div className={timeMode == 10 ? "item item-active" : "item"} onClick={() => handleChangeMode(10)}>
        <i className="fa-solid fa-circle-question"></i>
        <i className="fa-solid fa-clock"></i>
        <span>10 phút</span>
      </div>

      <div className="line"></div>
    </div>
  );
}
