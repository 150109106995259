import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <NavLink className="item" to={"/home"} activeClassName="item-active">
        <i className="fa-solid fa-house"></i>
        <span>Home</span>
      </NavLink>

      <NavLink className="item" to={"/promotion"} activeClassName="item-active">
        <i className="fa-solid fa-gem"></i>
        <span>Tiếp thị</span>
      </NavLink>

      <NavLink className="item" to={"/account"} activeClassName="item-active">
        <i className="fa-solid fa-circle-user"></i>
        <span>Account</span>
      </NavLink>
    </div>
  );
}
