import React from "react";

export default function Static() {
  const timeStamp = Date.now();
  let numOfUsers = Number(timeStamp.toString().substring(3, 8));
  if (numOfUsers < 10000) {
    numOfUsers = 99999;
  }
  let numOfOrders = Number(timeStamp.toString().substring(2, 10));
  if (numOfOrders < 10000000) {
    numOfOrders = 99999999;
  }

  const onlineUser = Math.floor(Math.random() * 1235); // from 0 to 1234

  return (
    <div className="static">
      <div className="item item1">
        <i className="fa-solid fa-user"></i>
        <p>{numOfUsers.toLocaleString()}</p>
        <span>
          Số lượng
          <br />
          người dùng
        </span>
      </div>

      <div className="item item2">
        <i className="fa-solid fa-chalkboard-user"></i>
        <p>{numOfOrders.toLocaleString()}</p>
        <span>
          Số lần
          <br />
          đặt cược
        </span>
      </div>

      <div className="item item3">
        <i className="fa-solid fa-user-group"></i>
        <p>{onlineUser.toLocaleString()}</p>
        <span>
          Số người
          <br />
          trực tuyến
        </span>
      </div>
    </div>
  );
}
