import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function SidebarAdmin() {
  const { showSidebar } = useSelector((root) => root.sidebarReducer);
  const { user } = useSelector((root) => root.userReducer);
  const dispatch = useDispatch();

  let sidebarHideClass = showSidebar ? "" : "sidebar-hide";

  const hideSideBar = () => {
    if (window.innerWidth < 576) {
      dispatch({
        type: "TOGGLE_SIDEBAR",
      });
    }
  };

  return (
    <div className={`sidebar ${sidebarHideClass}`}>
      <span className="group-type">HỆ THỐNG</span>

      <NavLink exact to="/dashboard" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Dashboard</span>
      </NavLink>

      <NavLink exact to="/users-management" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Quản lý người dùng</span>
      </NavLink>

      <NavLink exact to="/history-profit" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Lịch sử lợi nhuận</span>
      </NavLink>

      <span className="group-type type-below">LOTTERY</span>

      <NavLink exact to="/dashboard-wingo" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Win Go Lottery</span>
      </NavLink>

      <NavLink exact to="/dashboard-aov" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>AOV Lottery</span>
      </NavLink>

      {/* <NavLink exact to="/history-order-wingo" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Lịch sử đặt cược Win Go</span>
      </NavLink>

      <NavLink exact to="/history-order-aov" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Lịch sử đặt cược AOV</span>
      </NavLink> */}

      <span className="group-type type-below">GIAO DỊCH</span>

      {user?.id === 1 && (
        <NavLink exact to="/deposit-bank" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
          <span>Ngân hàng nạp tiền</span>
        </NavLink>
      )}

      <NavLink exact to="/deposit-management" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Giao dịch nạp tiền</span>
      </NavLink>

      {user?.id === 1 && (
        <NavLink exact to="/withdraw-bank" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
          <span>Ngân hàng rút tiền</span>
        </NavLink>
      )}

      <NavLink exact to="/withdraw-management" activeClassName="btn-active" className="btn" onClick={hideSideBar}>
        <span>Giao dịch rút tiền</span>
      </NavLink>
    </div>
  );
}
