import React from "react";
import { Redirect } from "react-router-dom";
import Card from "./Card";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import Card5 from "./Card5";
import Carousel from "./Carousel";
import Footer from "./Footer";
import Header from "./Header";
import Marquee from "./Marquee";
import Rank from "./Rank";
import Static from "./Static";

export default function Home() {
  if (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("user")).id == 1
  ) {
    return <Redirect to="/dashboard-wingo" />;
  }

  return (
    <div className="home-page">
      <div className="home">
        <Header />
        <div className="banner"></div>
        <div className="container">
          <Carousel />
          <Marquee />
          <Card5 />
          <Card />
          <Card2 />
          <Card3 />
          <Card4 />
          <Static />
          <Rank />
        </div>
        <Footer />
      </div>
    </div>
  );
}
