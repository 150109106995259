import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROWS } from "../../constant/constant";
import { axiosService } from "../../util/service";

export default function HistoryGame() {
  const [array, setArray] = useState([]);
  const { timeMode, time } = useSelector((root) => root.gameReducer);

  // for pagination
  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(1);

  const onPaginationChange = (page) => {
    setCurrent(page);
    getData({
      time: timeMode,
      limit: ROWS,
      page,
    });
  };

  // getChartLotery
  const getData = async (payload) => {
    try {
      let response = await axiosService.post("api/lotery/getChartLotery", payload);
      let arrayResult = response.data.data.array;

      // xét arrayResult[0]
      // nếu arrayResult[0].number == null: đó là kỳ số hiện tại, chưa có kết quả
      // slice nó đi, không show ra bảng lịch sử
      // nếu arrayResult[0].number có giá trị thì hiển thị như lịch sử bình thường

      if (arrayResult[0].number == null) {
        arrayResult = arrayResult.slice(1); // tạo mảng mới từ phần tử thứ 1 về sau, loại bỏ phần tử 0
      }
      setArray(arrayResult);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrent(1);
    getData({
      time: timeMode,
      limit: ROWS,
      page: 1,
    });
  }, [timeMode]);

  useEffect(() => {
    // bắt đầu game mới, load kết quả game cũ
    if (
      (timeMode === 1 && time === 59) ||
      (timeMode === 3 && time === 179) ||
      (timeMode === 5 && time === 299) ||
      (timeMode === 10 && time === 599)
    ) {
      setCurrent(1);
      getData({
        time: timeMode,
        limit: ROWS,
        page: 1,
      });
    }
  }, [time]);

  const columns = [
    {
      title: "Kỳ xổ",
      key: "kyxo",
      width: "40%",
      dataIndex: "id",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "Kết quả",
      key: "ketqua",
      width: "60%",
      children: [
        {
          title: "Số",
          render: (_, { number }) => {
            return <span style={{ fontSize: 12 }}>{number}</span>;
          },
        },
        {
          title: "Loại",
          render: (_, { number }) => {
            if (number == 0 || number == 1 || number == 2 || number == 3 || number == 4) {
              return <span style={{ fontSize: 12 }}>Nhỏ</span>;
            }
            if (number == 5 || number == 6 || number == 7 || number == 8 || number == 9) {
              return <span style={{ fontSize: 12 }}>Lớn</span>;
            }
          },
        },
        {
          title: "Màu",
          render: (_, { number }) => {
            let num = number;

            if (num === 1 || num === 3 || num === 7 || num === 9) {
              return <i className="fa-solid fa-circle green"></i>;
            } else if (num === 2 || num === 4 || num === 6 || num === 8) {
              return <i className="fa-solid fa-circle red"></i>;
            } else if (num === 0) {
              return (
                <>
                  <i className="fa-solid fa-circle red"></i>
                  <i className="fa-solid fa-circle purple" style={{ marginLeft: 4 }}></i>
                </>
              );
            } else if (num === 5) {
              return (
                <>
                  <i className="fa-solid fa-circle green"></i>
                  <i className="fa-solid fa-circle purple" style={{ marginLeft: 4 }}></i>
                </>
              );
            }
          },
        },
      ],
    },
  ];

  return (
    <div className="history-game">
      <Table columns={columns} dataSource={array} rowKey={(record) => record.id} pagination={false} size="small" />

      <div className="pagination">
        <Pagination
          defaultCurrent={1}
          current={current}
          total={totalRecord}
          showSizeChanger={false}
          showQuickJumper={false}
          size="small"
          showLessItems
          className="pagination-box"
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );
}
