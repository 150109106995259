import acbLogo from "../assets/image/bank/acb.png";
import agribankLogo from "../assets/image/bank/agribank.png";
import bidvLogo from "../assets/image/bank/bidv.png";
import eximbankLogo from "../assets/image/bank/eximbank.png";
import hdbankLogo from "../assets/image/bank/hdbank.png";
import lienvietpostbankLogo from "../assets/image/bank/lienviet.png";
import mbLogo from "../assets/image/bank/mb.png";
import msbLogo from "../assets/image/bank/msb.png";
import namabankLogo from "../assets/image/bank/nama.png";
import sacombankLogo from "../assets/image/bank/sacombank.png";
import techcombankLogo from "../assets/image/bank/techcombank.png";
import tpbankLogo from "../assets/image/bank/tpbank.png";
import vibLogo from "../assets/image/bank/vib.png";
import vietcombankLogo from "../assets/image/bank/vietcombank.png";
import viettinbankLogo from "../assets/image/bank/viettinbank.png";
import vpbankLogo from "../assets/image/bank/vpbank.png";
import momo from "../assets/image/bank/momo.png";
import placeholderLogo from "../assets/image/placeholder_img.png";

export const renderBankImg = (name, mode) => {
  let bankSRC;
  if (name == "Vietcombank") bankSRC = vietcombankLogo;
  else if (name == "Techcombank") bankSRC = techcombankLogo;
  else if (name == "Sacombank") bankSRC = sacombankLogo;
  else if (name == "VietinBank") bankSRC = viettinbankLogo;
  else if (name == "TP Bank" || name == "TPBank") bankSRC = tpbankLogo;
  else if (name == "LienVietPostBank") bankSRC = lienvietpostbankLogo;
  else if (name == "NamABank") bankSRC = namabankLogo;
  else if (name == "MSB") bankSRC = msbLogo;
  else if (name == "BIDV") bankSRC = bidvLogo;
  else if (name == "MB" || name == "MB Bank") bankSRC = mbLogo;
  else if (name == "ACB") bankSRC = acbLogo;
  else if (name == "VPBank") bankSRC = vpbankLogo;
  else if (name == "Agribank") bankSRC = agribankLogo;
  else if (name == "Eximbank") bankSRC = eximbankLogo;
  else if (name == "HD Bank") bankSRC = hdbankLogo;
  else if (name == "VIB") bankSRC = vibLogo;
  else if (name == "Momo" || name == "momo" || name == "Ví Momo") bankSRC = momo;
  else bankSRC = placeholderLogo;

  if (mode == 1) {
    return <img src={bankSRC} />;
  } else if (mode == 2) {
    return (
      <div className="bank-img">
        <img src={bankSRC} />
      </div>
    );
  }
};
