import { Button, Drawer, Pagination, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROWS } from "../../constant/constant";
import { showToast } from "../../function/showToast";
import { axiosService } from "../../util/service";
import socket from "../../util/socket";
import TimerAOV from "./TimerAOV";

import imgADD from "../../assets/image/aov/ad2.png";
import nhanvat0 from "../../assets/image/aov/nhanvat0.png";
import nhanvat1 from "../../assets/image/aov/nhanvat1.png";
import nhanvat2 from "../../assets/image/aov/nhanvat2.png";
import nhanvat3 from "../../assets/image/aov/nhanvat3.png";
import nhanvat4 from "../../assets/image/aov/nhanvat4.png";
import nhanvat5 from "../../assets/image/aov/nhanvat5.png";
import nhanvat6 from "../../assets/image/aov/nhanvat6.png";
import nhanvat7 from "../../assets/image/aov/nhanvat7.png";
import nhanvat8 from "../../assets/image/aov/nhanvat8.png";
import nhanvat9 from "../../assets/image/aov/nhanvat9.png";
import imgSPP from "../../assets/image/aov/sp2.png";

export default function SetResultAOV() {
  const [result, setResult] = useState(undefined); // 0-9 để set result

  const history = useHistory();

  const dispatch = useDispatch();
  const { orderNow } = useSelector((root) => root.adminReducer);
  const { timeMode, time } = useSelector((root) => root.gameReducer);

  // drawer
  const [open, setOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [current, setCurrent] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const showDrawer = () => {
    setCurrent(1);
    getSetLotteryHistory(ROWS, 1);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setHistoryData([]);
    setCurrent(1);
  };

  const onChangeGameMode = ({ target: { value } }) => {
    dispatch({
      type: "CHANGE_TIME_MODE",
      payload: value,
    });
  };

  const setLottery = async (gameMode, result) => {
    try {
      let response = await axiosService.post("api/lotery/setLotteryAov", {
        number: result,
        time: gameMode,
      });
      showToast("success", response.data.message);
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  const getSetLotteryHistory = async (limit, page) => {
    try {
      let response = await axiosService.post("api/admin/getSetResultAdmin", {
        limit,
        page,
      });
      setHistoryData(response.data.data.array);
      setTotalRecord(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderNow = async (gameMode) => {
    try {
      let response = await axiosService.post("api/lotery/getOrderNowAOV", {
        time: gameMode,
      });
      dispatch({
        type: "LOAD_PENDING_ORDER_NOW",
        payload: response.data.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "LOAD_PENDING_ORDER_NOW",
        payload: undefined,
      });
    }
  };

  const handleSubmit = () => {
    if (result == undefined) {
      showToast("error", "Please choose a number");
    } else {
      setLottery(timeMode, result);
    }
  };

  function compareFn(a, b) {
    if (a.amountOrder > b.amountOrder) return -1;
    else if (a.amountOrder < b.amountOrder) return 1;
    else return 0;
  }

  const renderPendingOrder = (array) => {
    if (array.length == 0) {
      return <></>;
    }

    const sortedArray = array.sort(compareFn);

    return sortedArray.map((order, index) => {
      return (
        <div className="ord" key={index}>
          <span>{order.email}</span>
          <span>
            <i className="fa-solid fa-coins" style={{ marginRight: 5 }}></i>
            <b>{order.amountOrder.toLocaleString()}</b>
          </span>
          <span>
            <i className="fa-solid fa-wallet" style={{ marginRight: 5 }}></i>
            {Math.round(Number(order.balanceUser)).toLocaleString()}
          </span>
        </div>
      );
    });
  };

  const renderHistoryItem = (item, index) => {
    let className = "";

    if (item.result == 0) {
      className = "type-0";
    } else if (item.result == 5) {
      className = "type-5";
    } else if (item.result == 1 || item.result == 3 || item.result == 7 || item.result == 9) {
      className = "type-1379";
    } else if (item.result == 2 || item.result == 4 || item.result == 6 || item.result == 8) {
      className = "type-2468";
    }

    return (
      <div className="history-set-result-item" key={index}>
        <div className="row row1">
          <span className={`item-result ${className}`}>{item.result}</span>
          <span>{item.userName}</span>
        </div>
        <div className="row row2">
          <span className="item-time">{item.created_at}</span>
          <span>{item.email}</span>
        </div>
      </div>
    );
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
    getSetLotteryHistory(ROWS, page);
  };

  useEffect(() => {
    getOrderNow(timeMode);

    socket.removeAllListeners();

    socket.emit("joinUser", "1"); // tham số thứ 2 là user id

    socket.on("orderNow", (res) => {
      getOrderNow(timeMode);
    });

    if (timeMode === 1) {
      socket.on("TIME", (res) => {
        // res: [1-60]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 60 - res,
        });
      });
    } else if (timeMode === 3) {
      socket.on("TIME3", (res) => {
        // res: [1-180]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 180 - res,
        });
      });
    } else if (timeMode === 5) {
      socket.on("TIME5", (res) => {
        // res: [1-300]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 300 - res,
        });
      });
    } else if (timeMode === 10) {
      socket.on("TIME10", (res) => {
        // res: [1-600]
        dispatch({
          type: "TIME_FROM_SOCKET",
          payload: 600 - res,
        });
      });
    }
  }, [timeMode]);

  useEffect(() => {
    if (
      (timeMode === 1 && time === 58) ||
      (timeMode === 3 && time === 178) ||
      (timeMode === 5 && time === 298) ||
      (timeMode === 10 && time === 598)
    ) {
      dispatch({
        type: "LOAD_PENDING_ORDER_NOW",
        payload: undefined,
      });
      // gọi lại API để load lại data game mới
      getOrderNow(timeMode);
    }
  }, [time, timeMode]);

  const calcSum = (array) => {
    // tính tổng các order cùng loại

    if (!array) return 0;
    if (array.length == 0) return 0;

    let sum = 0;
    for (let order of array) {
      sum += order.amountOrder;
    }
    return sum;
  };

  return (
    <div className="dashboard-admin-aov">
      <div style={{ marginBottom: 30 }} className="time-zone">
        <div className="admin-card">
          <span className="card-title">Thời gian</span>
          <div className="card-content">
            <Radio.Group
              className="game-mode-radio"
              options={[
                {
                  label: "1 phút",
                  value: 1,
                },
                {
                  label: "3 phút",
                  value: 3,
                },
                {
                  label: "5 phút",
                  value: 5,
                },
                {
                  label: "10 phút",
                  value: 10,
                },
              ]}
              onChange={onChangeGameMode}
              value={timeMode}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>

        <TimerAOV />
      </div>

      <form style={{ marginBottom: 50 }}>
        <div className="field titleField">
          <h2 className="title">Set AOV Lottery</h2>
          <span onClick={showDrawer}>Xem lịch sử</span>
        </div>

        <div className="field resultField">
          <div className="radio-group">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`type-set-aov ${item == result ? "active" : ""}`}
                  onClick={() => {
                    setResult(item);
                  }}
                >
                  {item == 0 && <img src={nhanvat0} />}
                  {item == 1 && <img src={nhanvat1} />}
                  {item == 2 && <img src={nhanvat2} />}
                  {item == 3 && <img src={nhanvat3} />}
                  {item == 4 && <img src={nhanvat4} />}
                  {item == 5 && <img src={nhanvat5} />}
                  {item == 6 && <img src={nhanvat6} />}
                  {item == 7 && <img src={nhanvat7} />}
                  {item == 8 && <img src={nhanvat8} />}
                  {item == 9 && <img src={nhanvat9} />}
                </div>
              );
            })}
          </div>

          <Button
            size="large"
            type="primary"
            onClick={() => handleSubmit()}
            disabled={result == undefined}
            className="submit-btn"
            style={{ display: result == undefined ? "none" : "block" }}
          >
            Submit
          </Button>
        </div>
      </form>

      {orderNow && (
        <>
          <div className="current-title-area">
            <h2 className="title current-title">Đặt cược hiện tại</h2>
            <span onClick={() => history.push("/history-order-aov")}>Xem lịch sử</span>
          </div>

          <div className="order-list">
            {orderNow.orderBig.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={imgSPP} className="adsp-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.orderBig)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.orderBig)}</div>
              </div>
            )}
            {orderNow.orderSmall.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={imgADD} className="adsp-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.orderSmall)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.orderSmall)}</div>
              </div>
            )}
            {orderNow.order0.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat0} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order0)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order0)}</div>
              </div>
            )}
            {orderNow.order1.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat1} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order1)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order1)}</div>
              </div>
            )}
            {orderNow.order2.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat2} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order2)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order2)}</div>
              </div>
            )}
            {orderNow.order3.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat3} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order3)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order3)}</div>
              </div>
            )}
            {orderNow.order4.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat4} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order4)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order4)}</div>
              </div>
            )}
            {orderNow.order5.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat5} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order5)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order5)}</div>
              </div>
            )}
            {orderNow.order6.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat6} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order6)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order6)}</div>
              </div>
            )}
            {orderNow.order7.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat7} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order7)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order7)}</div>
              </div>
            )}
            {orderNow.order8.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat8} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order8)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order8)}</div>
              </div>
            )}
            {orderNow.order9.length != 0 && (
              <div className="row">
                <div className="row-type">
                  <img src={nhanvat9} className="tuong-in-result-game" />
                  <span>{Math.round(calcSum(orderNow.order9)).toLocaleString()}</span>
                </div>
                <div className="pending-orders">{renderPendingOrder(orderNow.order9)}</div>
              </div>
            )}
          </div>

          {orderNow.order0.length == 0 &&
            orderNow.order1.length == 0 &&
            orderNow.order2.length == 0 &&
            orderNow.order3.length == 0 &&
            orderNow.order4.length == 0 &&
            orderNow.order5.length == 0 &&
            orderNow.order6.length == 0 &&
            orderNow.order7.length == 0 &&
            orderNow.order8.length == 0 &&
            orderNow.order9.length == 0 &&
            orderNow.orderBig.length == 0 &&
            orderNow.orderSmall.length == 0 && <div>Không có đặt cược nào</div>}
        </>
      )}

      <Drawer title="Lịch sử set lottery" onClose={onClose} open={open}>
        {historyData.length == 0 && <div style={{ textAlign: "center" }}>Không có dữ liệu</div>}
        {historyData.length != 0 && (
          <>
            <div className="history-set-result-pagination">
              <Pagination
                defaultCurrent={1}
                total={totalRecord}
                current={current}
                onChange={onPaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
                className="pagination-box"
                size="small"
                hideOnSinglePage
              />
            </div>

            <div className="history-set-result-list">
              {historyData.map((item, index) => {
                return renderHistoryItem(item, index);
              })}
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
}
