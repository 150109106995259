import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Home/Footer";
import Header from "../Home/Header";

export default function Page404() {
  const history = useHistory();

  return (
    <div className="page404">
      <div className="p404">
        <Header />

        <div className="p404-content">
          <h3>404 Error</h3>
          <p>Trang này không tồn tại</p>
          <Button type="primary" onClick={() => history.replace("/home")}>
            Về trang chủ
          </Button>
        </div>

        <Footer />
      </div>
    </div>
  );
}
